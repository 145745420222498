/** Payment card brand. */
export enum CardBrand {
  Visa = 'visa',
  Amex = 'amex',
  Discover = 'discover',
  Mastercard = 'mastercard',
  Unknown = 'unknown',
}

const CARD_BRAND_TO_READABLE_MAP: Readonly<Record<CardBrand, string>> = {
  [CardBrand.Visa]: 'Visa',
  [CardBrand.Amex]: 'Amex',
  [CardBrand.Discover]: 'Discover',
  [CardBrand.Mastercard]: 'Mastercard',
  [CardBrand.Unknown]: 'Unknown',
};

export namespace CardBrand {

  /**
   * Makes card brand human-readable.
   * @param brand Brand.
   */
  export function toReadable(brand: CardBrand): string {
    return CARD_BRAND_TO_READABLE_MAP[brand];
  }
}
