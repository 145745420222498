<ng-container
  *ngIf="page.order$ | async as order; else controlsSkeletonTemplate"
>
  <ng-container *ngIf="order.isPaid; else unpaidOrderButtonsTemplate">
    <a routerLink="/user/order-history" class="primary primary_bigger">
      Order History
    </a>
  </ng-container>
  <ng-template #unpaidOrderButtonsTemplate>
    <button
      type="button"
      class="outlined"
      [pboxcLoading]="page.isCancellingOrder$ | async"
      (click)="page.onOrderCancelClick()"
    >
      Cancel Order
    </button>
    <a routerLink="/order/cart" class="outlined"> Edit Order</a>
    <a routerLink="/user/order-history" class="outlined"> Order History </a>
  </ng-template>
</ng-container>

<ng-template #controlsSkeletonTemplate>
  <a routerLink="/user/order-history" class="primary primary_bigger">
    Order History
  </a>
</ng-template>
