import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { OrderItem } from '@pbox/common/core/models/order-item';
import { createTrackByFunction } from '@pbox/common/core/utils/trackby';

/** List of order items. */
@Component({
  selector: 'pboxc-order-items-list',
  templateUrl: './order-items-list.component.html',
  styleUrls: ['./order-items-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemsListComponent {

  /** Whether the images should be displayed or not. */
  @Input()
  public withImages = true;

  /** Order items to display. */
  @Input()
  public orderItems: (readonly OrderItem[]) | null = null;

  /** Whether customization of order items is enabled. */
  @Input()
  public isCustomizationEnabled = false;

  /** Template for end slot of the item. */
  @ContentChild('itemTemplate', { static: true })
  public orderItemSlotTemplate?: TemplateRef<{item: OrderItem;}>;

  /** Template for the skeleton of the slotted item. */
  @ContentChild('itemSkeletonTemplate', { static: true })
  public orderItemSlotSkeletonTemplate?: TemplateRef<never>;

  /** Trackby function. */
  public readonly trackOrderItem = createTrackByFunction<OrderItem>('id');
}
