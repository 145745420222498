import { Injectable } from '@angular/core';

import { ScheduleType } from '../../models/schedule-type';

import { ScheduleTypeDto } from './dto/schedule-type.dto';

import { IMapperFromDto } from './mappers';

/** Schedule type mapper. */
@Injectable({
  providedIn: 'root',
})
export class ScheduleTypeMapper
implements IMapperFromDto<ScheduleTypeDto, ScheduleType> {
  /** @inheritdoc */
  public fromDto(dto: ScheduleTypeDto): ScheduleType {
    return {
      id: dto.user_skip_type_id,
      name: dto.user_skip_type_name,
      description: dto.user_skip_type_description,
    };
  }
}
