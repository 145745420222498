import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

import { CommonSharedModule } from '../../common-shared.module';

/** Loader. */
@Component({
  selector: 'pboxc-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, CommonSharedModule],
})
export class LoaderComponent {

  /** Loading flag. */
  @Input()
  public isLoading = false;

  /** Width of loader in pixels. */
  @Input()
  public width = 40;

  /** Height of loader in pixels. */
  @Input()
  public height = 40;

  /** Applies `aria-hidden` attribute to the host. */
  @HostBinding('attr.aria-hidden')
  public readonly hidden = true;

  /** Width binding. */
  @HostBinding('style.width')
  public get _width(): string {
    return `${this.width}px`;
  }

  /** Height binding. */
  @HostBinding('style.height')
  public get _height(): string {
    return `${this.width}px`;
  }
}
