import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@pbox/common/core/models/store';
import { StoresService } from '@pbox/common/core/services/stores.service';

/** Store policy. */
@Component({
  selector: 'pboxc-store-policy',
  templateUrl: './store-policy.component.html',
  styleUrls: ['./store-policy.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorePolicyComponent {

  /** Store. */
  @Input()
  public store: Store.Data | null = null;

  public constructor(protected readonly storesService: StoresService) { }
}
