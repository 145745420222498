import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';

import { MenuInformation } from '../../models/menu';
import { User, UserStatus } from '../../models/user';

import { AddressMapper } from './address.mapper';
import { UserDto } from './dto/user.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { MenuMapper } from './menu.mapper';

const USER_STATUS_MAP_FROM_DTO: Readonly<Record<UserDto['status'], UserStatus>> = {
  active: UserStatus.Active,
  canceled: UserStatus.Canceled,
  process: UserStatus.Processing,
  suspended: UserStatus.Suspended,
  trial: UserStatus.Trial,
};

/** User mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserMapper implements
  IMapperFromDto<UserDto, User>,
  IValidationErrorMapper<UserDto, User> {
  public constructor(
    private readonly addressMapper: AddressMapper,
    private readonly menuMapper: MenuMapper,
  ) { }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<UserDto> | null | undefined,
  ): EntityValidationErrors<User> {
    return {
      email: extractErrorMessage(errorDto?.email),
    };
  }

  /** @inheritdoc */
  public fromDto(dto: UserDto): User {
    return {
      firstName: dto.first_name,
      lastName: dto.last_name,
      id: dto.user_id,
      email: dto.email,
      phone: dto.phone,
      address: this.addressMapper.fromDto({
        apt_suite: dto.apt_suite,
        city_name: dto.city_name,
        address: dto.address,
        zip_code: dto.zip_code,
        state: dto.state,
      }),
      menu: new MenuInformation({
        message: dto.menu.message,
        content: dto.menu.data ? this.menuMapper.fromDto(dto.menu) : null,
        delivery: {
          date: dto.delivery_date ? new Date(dto.delivery_date) : null,
          description: dto.delivery_day_text,
        },
        isOpen: dto.menu.is_opening,
        openDate: new Date(dto.menu.opening_date),
        closeDate: new Date(dto.menu.closing_date),
        nextOpenDate: new Date(dto.menu.next_opening_date),
        nextCloseDate: new Date(dto.menu.next_closing_date),
      }),
      isFirstTime: dto.is_first_time,
      notifications: {
        accountEmail: dto.is_account_email_notification,
        cellPhone: dto.cell_phone_notification,
        menuEmail: dto.is_menu_email_notification,
        specialEmail: dto.is_special_email_notification,
      },
      delivery: {
        date: new Date(dto.delivery_date),
        message: dto.delivery_day_text,
        instructions: dto.message,
      },
      references: {
        employerId: dto.employer_id,
      },
      balance: dto.balance,
      status: USER_STATUS_MAP_FROM_DTO[dto.status],
      hasOrderWeek: dto.has_order,
      isSubscribed: dto.has_subscription,
      referralLink: dto.referral_url,
      anniversaryDate: new Date(),
      coordinator: {
        id: dto.coordinator_id,
        name: dto.coordinator_name,
      },
      hasCart: dto.cart_id != null,
      hasAssignedLocation: dto.location != null,
      isMember: dto.is_member,
      storeId: dto.store_id,
    };
  }
}
