<label class="checkbox-container" *ngIf="withLabel; else withoutLabelTemplate">
  <input
    [disabled]="disabled"
    class="checkbox"
    [(ngModel)]="controlValue"
    type="checkbox"
    *ngIf="label; else skeleton"
  />
  <div
    class="label_primary"
    [pboxcSkeleton]="label"
    [pboxcSkeletonMinWidth]="5"
    [pboxcSkeletonMaxWidth]="15"
  >
    {{ label }}
  </div>

  <div class="label_secondary" *ngIf="secondaryLabel">
    {{ secondaryLabel }}
  </div>
</label>

<ng-template #withoutLabelTemplate>
  <input
    [disabled]="disabled"
    class="checkbox"
    [(ngModel)]="controlValue"
    type="checkbox"
  />
</ng-template>

<ng-template #skeleton>
  <div class="checkbox checkbox_skeleton" zcSkeleton></div>
</ng-template>
