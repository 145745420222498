import { Injectable } from '@angular/core';

import { AppConfig } from './app.config';

/**
 * Urls used within the application.
 * Stringified for convenience, since most of the Angular's HTTP tools work with strings.
 */
@Injectable({ providedIn: 'root' })
export class AppUrlsConfig {
  public constructor(
    private readonly appConfigService: AppConfig,
  ) { }

  // Please prefer the following way of defining urls scope:

  // public readonly scope = {
  //   get: (id: string) => this.toApi('scope', id),
  //   list: this.toApi('scope/list/'),
  // };

  /** Auth-related routes. */
  public readonly auth = {
    login: this.toApi('auth/'),
    refreshSecret: this.toApi('auth/'),
    resetPassword: this.toApi('auth/password/reset'),
    confirmPasswordReset: this.toApi('auth/password/reset'),
    register: this.toApi('auth/sign-up/'),
  } as const;

  /** Routes for getting/editing current user's info. */
  public readonly user = {
    profile: this.toApi('me/profile/'),
    changeAddress: this.toApi('me/change-address/'),
    changePassword: this.toApi('me/change_password/'),
    moveFromSuspended: this.toApi('me', 'active-from-suspended'),
    membershipInfo: this.toApi('me', 'membership', 'info'),
    cancelMembership: this.toApi('me', 'membership', 'cancel'),
  } as const;

  /** Routes for getting info about products. */
  public readonly products = {
    list: this.toApi('products'),
    mostPurchased: this.toApi('products', 'most-purchased'),
    newThisWeek: this.toApi('products', 'new-this-week'),
    get: (id: string) => this.toApi('products', id),
    popularProduct: this.toApi('products', 'popular'),
  } as const;

  /** Routes for getting info about products. */
  public readonly subscriptions = {
    list: this.toApi('subscriptions'),
    add: this.toApi('subscriptions'),
    update: (id: string) => this.toApi('subscriptions', id),
    remove: (id: string) => this.toApi('subscriptions', id),
    skipTypes: this.toApi('skip-types'),
  } as const;

  /** Routes for reference on signup page. */
  public readonly reference = {
    list: this.toApi('reference/user-refers/'),
    fromUser: this.toApi('reference/referrers/'),
  } as const;

  /** Routes for states. */
  public readonly state = {
    list: this.toApi('states/'),
  } as const;

  /** Routes for employer. */
  public readonly employer = {
    list: this.toApi('employers/'),
  } as const;

  /** Routes for getting taxonomy-related info. */
  public readonly taxonomy = {
    categoryList: this.toApi('categories'),
    tagList: this.toApi('tags'),
  } as const;

  /** Routes for getting store-related info. */
  public readonly stores = {
    list: this.toApi('stores'),
  } as const;

  /** Routes for the cart API. */
  public readonly cart = {
    cart: (id: string) => this.toApi('carts', id),
    cartItems: this.toApi('cart-items'),
    cartItem: (id: string) => this.toApi('cart-items', id),
    customizeItem: this.toApi('cart-items', 'customize'),
    coupons: (cartId: string) => this.toApi('carts', cartId, 'coupons'),
    coupon: (cartId: string, couponId: string) => this.toApi('carts', cartId, 'coupons', couponId),
    futureCart: this.toApi('carts', 'future'),
  };

  /** Routes for the delivery API. */
  public readonly delivery = {
    list: this.toApi('me', 'deliveries'),
    update: this.toApi('me', 'deliveries'),
  };

  /** Routes for the credit card API. */
  public readonly creditCard = {
    list: this.toApi('credit-cards'),
    setActive: (id: string) => this.toApi('credit-cards', id, 'active'),
    creditCard: (id: string) => this.toApi('credit-cards', id),
    creditCards: this.toApi('credit-cards'),
  };

  /** Order urls. */
  public readonly orders = {
    allOrders: this.toApi('orders'),
    order: (id: string) => this.toApi(`orders/${id}`),
    replaceItem: (id: string) => this.toApi('orders', id, 'replacements'),
    replacementReasons: this.toApi('orders', 'replacement-reasons'),
  };

  /** App parameters urls. */
  public readonly appParameters = {
    list: this.toApi('app', 'parameters'),
  };

  /** Social network urls. */
  public readonly socials = {
    allSocials: this.toApi('socials', 'share-content'),
  };

  /** Endpoints for EBT payment. */
  public readonly ebtPayments = {
    initialize: this.toApi('payments', 'ebt', 'initial'),
    complete: this.toApi('payments', 'ebt', 'orders'),
  };

  /** Counties urls. */
  public readonly counties = {
    allCountries: this.toApi('counties'),
  };

  /** Locations urls. */
  public readonly locations = {
    allLocations: this.toApi('locations'),
  };

  /**
   * Checks whether the url is application-scoped.
   * @param url Url to check.
   */
  public isApplicationUrl(url: string): boolean {
    return url.startsWith(this.appConfigService.apiUrl);
  }

  /**
   * Checks whether the specified url is calling an auth-related endpoint.
   * @param url Url to check.
   */
  public isAuthUrl(url: string): boolean {
    return Object.values(this.auth).find(authUrl => authUrl.includes(url)) != null;
  }

  private toApi(...args: readonly string[]): string {
    const path = args.join('/');
    return new URL(path, this.appConfigService.apiUrl).toString();
  }
}
