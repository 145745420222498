import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

/** Common datepicker component. */
@Component({
  selector: 'pboxc-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => DatepickerComponent)],
})
export class DatepickerComponent extends SimpleValueAccessor<Date> {

  /**
   * Filter date.
   * @param date Date to filter.
   */
  public filterDate(date: Date | null): boolean {
    if (date == null) {
      return false;
    }
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return date.getTime() >= now.getTime();
  }

  /** Control placeholder. */
  @Input()
  public placeholder = '';

}
