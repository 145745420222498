import { Injectable } from '@angular/core';

import { DetailedOrder, DetailedOrderItem, Order, ReplaceItem } from '../../models/order';
import { PaymentMethod } from '../../models/payment-method';
import { PaymentMethodType } from '../../models/payment-method-type';
import { OrdersSearchOptions } from '../orders.service';

import { CardBrandMapper } from './card-brand.mapper';
import { CouponMapper } from './coupon.mapper';
import { CardPaymentMethodDto } from './dto/card-payment-method.dto';
import { FullOrderDto, FullOrderItemDto, ReplaceItemDto, ShortOrderDto } from './dto/order.dto';
import { SearchOptionsDto } from './dto/search-options.dto';
import { ProductMapper } from './product.mapper';
import { SearchOptionsMapper } from './search-options.mapper';
import { SortMapper } from './sort.mapper';
import { StockKeepingUnitMapper } from './stock-keeping-unit.mapper';

/** Order mapper. */
@Injectable({ providedIn: 'root' })
export class OrderMapper {
  public constructor(
    private readonly productMapper: ProductMapper,
    private readonly stockKeepingUnitMapper: StockKeepingUnitMapper,
    private readonly searchOptionsMapper: SearchOptionsMapper,
    private readonly sortMapper: SortMapper,
    private readonly couponMapper: CouponMapper,
    private readonly cardBrandMapper: CardBrandMapper,
  ) {}

  /**
   * Maps order dto to order.
   * @param dto Data to map.
   */
  public mapFromShortDto(dto: ShortOrderDto): Order {
    return {
      id: dto.order_id,
      date: new Date(dto.date ?? dto.date_created),
      title: dto.title.toString(),
      price: {
        total: dto.totals.final_amount,
        subTotal: dto.totals.final_amount,
        tip: 0,
        delivery: dto.totals.delivery_fee,
        afterDiscount: dto.totals.after_discount,
        tax: dto.totals.estimated_tax,
        membershipFee: dto.totals.membership_fee,
        membershipFeeDiscount: dto.totals.initial_membership_discount,
        balance: dto.totals.credit_applied_discount,
        orderTotal: dto.totals.order_amount,
        cardTotal: dto.totals.forage_cc_amount,
        ebtTotal: dto.totals.forage_ebt_amount,
        snapTotal: dto.totals.forage_snap_amount,
      },
      isPaid: dto.is_paid,
      coupon: dto.coupon ? this.couponMapper.fromDto(dto.coupon) : null,
    };
  }

  /**
   * Maps order dto to detailed order.
   * @param dto Data to map.
   */
  public mapFromFullDto(dto: FullOrderDto): DetailedOrder {
    const paymentMethod: CardPaymentMethodDto | undefined =
      dto.payment_methods[0];
    return {
      date: new Date(dto.date ?? dto.date_created),
      id: dto.order_id,
      title: 'Order',
      price: {
        total: dto.totals.final_amount,
        subTotal: dto.totals.sub_amount,
        tip: dto.totals.tip,
        delivery: dto.totals.delivery_fee,
        afterDiscount: dto.totals.after_discount,
        tax: dto.totals.estimated_tax,
        membershipFee: dto.totals.membership_fee,
        membershipFeeDiscount: dto.totals.initial_membership_discount,
        balance: dto.totals.credit_applied_discount,
        orderTotal: dto.totals.order_amount,
        cardTotal: dto.totals.forage_cc_amount,
        ebtTotal: dto.totals.forage_ebt_amount,
        snapTotal: dto.totals.forage_snap_amount,
      },
      isPaid: dto.is_paid,
      paymentMethod: this.mapPaymentMethodFromDto(paymentMethod),
      items: dto.items ? dto.items.map(item => this.mapDetailedOrderItemFromDto(item)) : [],
      coupon: dto.coupon ? this.couponMapper.fromDto(dto.coupon) : null,
    };
  }

  /**
   * Maps payment method dto to payment method.
   * @param paymentMethod Payment method DTO.
   */
  private mapPaymentMethodFromDto(
    paymentMethod: CardPaymentMethodDto | undefined,
  ): PaymentMethod {
    // https://saritasa.atlassian.net/browse/PBDEV-453?focusedCommentId=250263
    if (paymentMethod == null) {
      return {
        type: PaymentMethodType.Balance,
      };
    }

    if (paymentMethod.forage_ref) {
      return {
        type: PaymentMethodType.EBT,
        snapAmount: paymentMethod.snap_amount,
        snapCardNumber: paymentMethod.snap_number,
        snapBalance: paymentMethod.snap_balance,
        ebtAmount: paymentMethod.ebt_amount,
        ebtBalance: paymentMethod.ebt_balance,
        creditCardNumber: paymentMethod.cc_number.slice(-4),
        creditCardBrand: this.cardBrandMapper.fromDto(paymentMethod.cc_brand),
        creditCardAmount: paymentMethod.cc_amount,
      };
    }

    return {
      cardId: paymentMethod.credit_card_id,
      type: PaymentMethodType.Card,
    };
  }

  /**
   * Maps data required to replace an item to dto.
   * @param data Replace item data.
   */
  public replaceItemDataToDto(data: ReplaceItem): ReplaceItemDto {
    return {
      sku_id: data.stockKeepingUnitId,
      product_id: data.productId,
      replacement_reason_id: data.replacementReason.id,
      description: data.description,
    };
  }

  /**
   * Maps orders search options to DTO.
   * @param options Search options.
   */
  public mapOrdersSearchOptionsToDto(options: OrdersSearchOptions): SearchOptionsDto.Any {
    return {
      ...this.searchOptionsMapper.mapPaginationOptionsToDto(options),
      ...this.sortMapper.toDto({
        field: 'date' as keyof ShortOrderDto,
        type: 'desc',
      }),
      ...(options.canRequestCredit != null ? { can_request_credit: Number(options.canRequestCredit) } : null),
    };
  }

  /**
   * Map detailed order item from dto to domain.
   * @param dto Full order item dto.
   */
  public mapDetailedOrderItemFromDto(dto: FullOrderItemDto): DetailedOrderItem {
    return ({
      id: dto.order_box_id,
      quantity: dto.box_count,
      price: dto.price,
      product: {
        ...this.productMapper.fromDto(dto.product),
        stockKeepingUnits: dto.product.product_skus ?
          dto.product.product_skus.map(skuDto => this.stockKeepingUnitMapper.shortFromDto(skuDto)) :
          [],
      },
      comesWithEveryOrder: null,
    });
  }
}
