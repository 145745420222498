import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import { PaginationOptions } from 'swiper/types';

import { CarouselContentDirective } from './carousel-content.directive';

SwiperCore.use([Pagination]);

const DEFAULT_SWIPER_OPTIONS: SwiperOptions = {
  slidesPerView: 1,
  spaceBetween: 40,
};

/** Carousel component. */
@Component({
  selector: 'pboxc-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent {

  /** Whether custom pagination is added. */
  @Input()
  public withCustomPaginationClass = false;

  /** Swiper options. */
  @Input()
  public set options(value: SwiperOptions | null) {
    if (value != null) {
      this.swiperOptions$.next({
        ...DEFAULT_SWIPER_OPTIONS,
        ...value,
      });
    }
  }

  /** Contents. */
  @ContentChildren(CarouselContentDirective, { descendants: true })
  public contents: QueryList<CarouselContentDirective> | null = null;

  /** Carousel options. */
  protected readonly swiperOptions$ = new BehaviorSubject(DEFAULT_SWIPER_OPTIONS);

  /**
   * Whether pagination options is added.
   * @param options Swiper pagination options.
   */
  protected hasPaginationOptions(options: boolean | PaginationOptions | undefined): options is PaginationOptions {
    return this.withCustomPaginationClass && options !== null;
  }

  /**
   * Convert pagination element selector to class.
   * @param options Swiper pagination options.
   */
  protected getPaginationClass(options: PaginationOptions): string | undefined {
    if (options.el) {
      return (options.el).toString().slice(1);
    }
    return undefined;
  }

  /**
   * Function to track slide in array.
   * @param _ Idx.
   * @param slide Item to track.
   */
  protected trackSlide(_: number, slide: CarouselContentDirective): CarouselContentDirective {
    return slide;
  }
}
