<fieldset class="picker">
  <legend class="legend">Your Payment Information</legend>

  <div class="picker__content">
    <pboxc-card class="card">
      <label class="card__label">
        <input
          type="radio"
          [formControl]="paymentMethodTypeControl"
          [value]="paymentMethodTypes.Card"
          name="payment-method"
        />
        <span class="card__label-text"> Credit Card </span>
        <pboxc-payment-card-brands-preview></pboxc-payment-card-brands-preview>
      </label>
      <div
        *ngIf="paymentMethodTypeControl.value === paymentMethodTypes.Card"
        class="card__body"
      >
        <ng-container
          *ngIf="
            (isCardPaymentMethodLoading$ | async) === false;
            else cardPaymentMethodSkeletonTemplate
          "
        >
          <pboxc-card-payment-method
            *ngIf="
              cardPaymentMethod$ | async as cardPaymentMethod;
              else newCardPaymentMethodTemplate
            "
            [cardPaymentMethod]="cardPaymentMethod"
          ></pboxc-card-payment-method>
        </ng-container>
        <ng-template #newCardPaymentMethodTemplate>
          <pboxc-card-payment-method-form
            (formSubmit)="onNewCardAdded(form)"
            #form
          >
            <button
              type="submit"
              [pboxcLoading]="form.isLoading$ | async"
              class="primary save-payment-method-button"
            >
              Save
            </button>
          </pboxc-card-payment-method-form>
        </ng-template>
      </div>
    </pboxc-card>
    <pboxc-card class="card" *ngIf="appConfig.hasSnap">
      <label class="card__label">
        <input
          type="radio"
          [formControl]="paymentMethodTypeControl"
          [value]="paymentMethodTypes.EBT"
          name="payment-method"
        />
        <span class="card__label-text"> SNAP EBT Cash & Credit Card </span>
      </label>
    </pboxc-card>
  </div>

  <p class="other-methods-message">
    other payment methods will be available soon...
  </p>
</fieldset>

<ng-template #cardPaymentMethodSkeletonTemplate>
  <pboxc-card-payment-method></pboxc-card-payment-method>
</ng-template>
