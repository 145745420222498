import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AppParameters } from '../models/app-parameters';
import { Store } from '../models/store';

import { AppUrlsConfig } from './app-urls.config';

import { AppParametersMapper } from './mappers/app-parameters.mapper';
import { AppParametersDto } from './mappers/dto/app-parameters.dto';

/** Provides information about parameters for the whole app. */
@Injectable({ providedIn: 'root' })
export class AppParametersService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly appParametersMapper: AppParametersMapper,
  ) { }

  /**
   * Returns app parameters.
   * @param storeId Store id.
   */
  public getAppParameters(storeId: Store.Data['id']): Observable<AppParameters> {
    const params = new HttpParams({
      fromObject: {
        store_id: storeId,
      },
    });
    return this.httpClient.get<AppParametersDto>(this.apiUrls.appParameters.list, { params }).pipe(
      map(dto => this.appParametersMapper.fromDto(dto)),
    );
  }
}
