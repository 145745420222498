import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, Observable, pipe, Subject, switchMap, throwError, EMPTY } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, finalize, ignoreElements, share } from 'rxjs/operators';

import { AppError } from '../models/app-error';

const DEFAULT_DURATION_MS = 10000;

export namespace NotificationMessages {
  export const NOT_IMPLEMENTED = 'The feature is not implemented yet';
  export const SOMETHING_WENT_WRONG = 'Something went wrong';
}

interface Notification {

  /** Message. */
  readonly message: string;

  /** Duration of notification message. */
  readonly duration: number;

  /** Whenever snackbar is auto-dismissed after specified duration the callback is called. */
  readonly onDismiss: () => void;
}

/** Notification service. */
@Injectable({ providedIn: 'root' })
export class NotificationService {

  /** Whenever the stream is observed, snackbar messages are displayed. */
  public readonly notification$: Observable<never>;

  private readonly notificationSubject = new Subject<Notification>();

  private get shouldNotify(): boolean {
    return this.notificationSubject.observed;
  }

  public constructor(
    snackBar: MatSnackBar,
  ) {
    this.notification$ = this.notificationSubject.pipe(
      switchMap(({ message, duration, onDismiss }) => {
        const snackBarRef = snackBar.open(
          message,
          '',
          {
            duration,
            verticalPosition: 'top',
            horizontalPosition: 'center',
          },
        );
        return snackBarRef.afterDismissed().pipe(finalize(onDismiss));
      }),
      share(),
      ignoreElements(),
    );
  }

  /**
   * Notifies an user with a message.
   * @param message Human-readable message.
   * @param duration Duration of notification message.
   */
  public notify(message: string, duration: number = DEFAULT_DURATION_MS): Promise<void> {
    if (this.shouldNotify) {
      return new Promise(resolve => {
        this.notificationSubject.next({
          message,
          duration,
          onDismiss: resolve,
        });
      });
    }
    return Promise.reject(new Error('There is no active subscribers for notifications.'));
  }

  /**
   * Operator that catches app error instances and presents a message.
   * @param continueStreamAfterNotifying Whether stream should be continue after notifying.
   */
  public notifyOnAppError<T>(continueStreamAfterNotifying = false): MonoTypeOperatorFunction<T> {
    return pipe(
      catchError((error: unknown) => {
        if (error instanceof AppError) {
          this.notify(error.message);
        }
        return continueStreamAfterNotifying ? EMPTY : throwError(() => error);
      }),
    );
  }
}
