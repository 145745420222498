<div class="layout">
  <div
    class="background"
    [ngStyle]="{ 'background-image': backgroundImageUrl }"
  >
    <header>
      <a class="header__link" routerLink="/">
        <pboxc-main-logo class="header__logo"></pboxc-main-logo>
      </a>
    </header>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <pboxw-footer></pboxw-footer>
</div>
