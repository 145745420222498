import { Pipe, PipeTransform } from '@angular/core';

const PLACEHOLDER = '-';

/** Sets a placeholder if value is null or empty string. */
@Pipe({
  name: 'pboxcNullable',
})

export class NullablePipe implements PipeTransform {
  /** @inheritdoc */
  public transform(value: string | number | undefined | null): string {
    return value == null || value.toString().trim() === '' ? PLACEHOLDER : value.toString();
  }
}
