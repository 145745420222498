<header class="header">
  <a class="header__link" routerLink="/">
    <pboxc-main-logo class="header__logo"></pboxc-main-logo>
  </a>

  <nav class="navigation">
    <ng-container
      *ngIf="userService.isAuthorized$ | async; else loginLinkTemplate"
    >
      <a
        class="navigation__link"
        routerLink="/products"
        routerLinkActive
        #productsLink="routerLinkActive"
        [class.navigation__link_active]="productsLink.isActive"
      >
        <div>Continue</div>
        <div>Shopping</div>
      </a>
      <button
        *ngIf="shouldShowCartLink"
        class="navigation__link navigation__link_cart"
        type="button"
        (click)="onCartClick()"
      >
        <span class="visually-hidden">Cart</span>
        <mat-icon svgIcon="cart" class="navigation__link-icon"></mat-icon>
        <pboxc-cart-items-amount-badge
          class="badge"
        ></pboxc-cart-items-amount-badge>
      </button>
      <button
        type="button"
        class="navigation__link"
        title="Go to the your profile"
        [class.navigation__link_active]="
          profileLink.isActive || orderHistoryLink.isActive
        "
        [matMenuTriggerFor]="menu"
      >
        <mat-icon
          svgIcon="profile"
          class="navigation__link-icon navigation__link-icon_profile"
        ></mat-icon>
      </button>
    </ng-container>

    <ng-template #loginLinkTemplate>
      <a class="navigation__link" routerLink="/auth/login"> Login </a>
    </ng-template>
  </nav>

  <mat-menu #menu="matMenu" class="products-layout-menu menu">
    <a
      class="menu__item"
      routerLink="/user/profile"
      routerLinkActive
      #profileLink="routerLinkActive"
      mat-menu-item
    >
      Manage Your Profile
    </a>
    <a
      *ngIf="storesService.isDeliveryEnabled$ | async"
      class="menu__item"
      routerLink="/user/deliveries"
      routerLinkActive
      #deliveriesLink="routerLinkActive"
      mat-menu-item
    >
      Manage Your Deliveries
    </a>
    <a
      class="menu__item"
      routerLink="/user/order-history"
      routerLinkActive
      #orderHistoryLink="routerLinkActive"
      mat-menu-item
    >
      Your Orders
    </a>
    <a
      class="menu__item"
      routerLink="/user/order-history"
      [queryParams]="needACreditQueryParam"
      routerLinkActive
      #skipCalendarLink="routerLinkActive"
      mat-menu-item
    >
      Need a Credit?
    </a>
    <a
      *ngIf="storesService.isDeliveryEnabled$ | async"
      class="menu__item"
      routerLink="/user/deliveries"
      [queryParams]="skipCalendarQueryParam"
      routerLinkActive
      #skipCalendarLink="routerLinkActive"
      mat-menu-item
    >
      Skips
    </a>
    <button
      (click)="onLogoutClick()"
      type="button"
      class="menu__item"
      mat-menu-item
      [pboxcLoading]="isLoading$ | async"
    >
      Sign Out
    </button>
  </mat-menu>
</header>
