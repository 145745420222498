<a
  *ngIf="store$ | async as store; else phoneSkeletonTemplate"
  href="tel:{{ store.phone }}"
  class="phone"
>
  {{ store.phone | pboxcPhone }}
</a>

<ng-template #phoneSkeletonTemplate>
  <span
    aria-hidden
    pboxcSkeleton
    [pboxcSkeletonMinWidth]="10"
    [pboxcSkeletonMaxWidth]="10"
  ></span>
</ng-template>
