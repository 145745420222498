import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppParameters } from '@pbox/common/core/models/app-parameters';
import { Banner } from '@pbox/common/core/models/banner';
import { AppParametersService } from '@pbox/common/core/services/app-parameters.service';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';

export type BannerType = 'menu' | 'profile';

const PLACEHOLDER_SRC = '/assets/image-not-available.png';

/** Banner component. */
@UntilDestroy()
@Component({
  selector: 'pboxc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit {

  /** Custom errors message. */
  @Input()
  public bannerType: BannerType | null = null;

  /** Current banner. */
  protected readonly bannerSubject = new BehaviorSubject<Banner | null>(null);

  public constructor(
    private readonly appParametersService: AppParametersService,
    private readonly storeService: StoresService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.storeService.currentStore$.pipe(
      switchMap(currentStore => this.appParametersService.getAppParameters(currentStore.id)),
      map(appParameters => this.getBannerFromParameters(appParameters)),
      untilDestroyed(this),
    )
      .subscribe({
        next: banner => this.bannerSubject.next(banner),
      });
  }

  /** Handles image loading error. */
  public onImageLoadError(): void {
    this.bannerSubject.next({
      alternativeText: '',
      url: PLACEHOLDER_SRC,
    });
  }

  /**
   * Get banner url.
   * @param appParameters App parameters.
   */
  protected getBannerFromParameters(appParameters: AppParameters | null): Banner | null {
    if (appParameters == null) {
      return null;
    }

    // TODO (Tien Luu): Update alternative text when BE ready.
    if (this.bannerType === 'menu') {
      return {
        url: appParameters.menuBannerUrl,
        alternativeText: 'Menu banner',
      };
    }

    if (this.bannerType === 'profile') {
      return {
        url: appParameters.profileBannerUrl,
        alternativeText: 'Profile banner',
      };
    }

    return null;
  }
}
