import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { PasswordReset } from '../../models/password-reset';

import { PasswordResetDto } from './dto/password-reset.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Mapper for reset password data. */
@Injectable({ providedIn: 'root' })
export class ResetPasswordConfirmationMapper implements
  IMapperToDto<PasswordResetDto.Confirmation, PasswordReset.Confirmation>,
  IValidationErrorMapper<PasswordResetDto.Confirmation, PasswordReset.Confirmation> {
  /** @inheritdoc */
  public toDto(
    data: PasswordReset.Confirmation,
  ): PasswordResetDto.Confirmation {
    return {
      password: data.password,
      password_confirm: data.passwordConfirmation,
      token: data.key,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<PasswordResetDto.Confirmation>,
  ): EntityValidationErrors<PasswordReset.Confirmation> {
    return {
      password:
        extractErrorMessage(errorDto.password) ??
        extractErrorMessage(errorDto.non_field_errors),
      passwordConfirmation: extractErrorMessage(errorDto.password_confirm),
    };
  }
}
