<pboxc-dialog-container class="dialog">
  <h2 class="h1 dialog__heading">Customize your {{ product.name }}</h2>
  <div class="dialog__confirmation-message">
    Editing the contents of your curated
    <strong>"{{ product.name }}"</strong> will convert this to a customized
    order and all items will appear in your cart as individual items. You can
    add and subtract in the cart.
  </div>

  <div class="dialog__actions">
    <button
      type="button"
      class="primary primary_bigger"
      (click)="onCustomizeOrderButtonClick()"
      [pboxcLoading]="isCustomizing$ | async"
    >
      Customize Order
    </button>
    <button type="button" class="basic basic_bigger" mat-dialog-close>
      Cancel
    </button>
  </div>
</pboxc-dialog-container>
