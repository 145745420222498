import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlatformName, SocialPlatform } from '@pbox/common/core/models/store';
import { createTrackByFunction } from '@pbox/common/core/utils/trackby';

const SOCIAL_ICON_MAPPER: Readonly<Record<PlatformName, string>> = {
  facebook: 'facebook',
  instagram: 'instagram',
  linkedin: 'linkedin',
};

/** Social media info. */
@Component({
  selector: 'pboxc-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialMediaComponent {

  /** Social media. */
  @Input()
  public socialMedia: readonly SocialPlatform[] | null = null;

  /** Function to track social platforms by name. */
  protected trackSocialPlatforms = createTrackByFunction<SocialPlatform>('name');

  /** Icon mapper. */
  protected readonly iconMapper = SOCIAL_ICON_MAPPER;
}
