import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Product image. */
@Component({
  selector: 'pboxc-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductImageComponent {
  /** Image src. */
  @Input()
  public src: string | null = null;
}
