import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ignoreElements, Observable, tap } from 'rxjs';

/** Service for router. */
@Injectable({
  providedIn: 'root',
})
export class RouterService {

  public constructor(
    private readonly router: Router,
  ) { }

  /**
   * Run callback after navigate end.
   * @param callback Callback.
   */
  public handleAfterNavigationEnd(callback: () => void): Observable<void> {
    return this.router.events.pipe(
      tap(event => {
        if (event instanceof NavigationEnd) {
          callback();
        }
      }),
      ignoreElements(),
    );
  }

}
