/**
 * Inverts a dictionary from key-to-value to value-to-key. Works only for the dictionaries which values may be keys.
 * @param dictionary Dictionary to revert.
 * @param mapFn Function to map inverted value to one that needed.
 */
// Any is allowed since it works for literally any object.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function invertDictionary<K extends keyof any, T extends keyof any, R = void>(
  dictionary: Readonly<Record<K, T>>,
  mapFn?: (key: K) => R,
): Record<T, K> {
  return (Object.entries(dictionary) as [K, T][]).reduce((acc, [key, value]) => ({
    ...acc,
    [value]: mapFn ? mapFn(key) : key,
  }), {} as Record<T, K>);
}
