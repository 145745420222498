import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthInterceptor } from '@pbox/common/core/interceptors/auth.interceptor';
import { RefreshTokenInterceptor } from '@pbox/common/core/interceptors/refresh-token.interceptor';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { CommonSharedModule } from '@pbox/common/shared/common-shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { GlobalErrorHandler } from '@pbox/common/core/services/global-error-handler';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { WebAppConfig } from './features/shared/web-app.config';
import { WebSharedModule } from './features/shared/web-shared.module';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProductsLayoutComponent } from './layouts/products-layout/products-layout.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';

const httpInterceptorProviders = [
  // The refresh interceptor should be before the auth interceptor, otherwise refreshed bearer would not be updated
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];

/** Root module. */
@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    ProductsLayoutComponent,
    WelcomeLayoutComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    LayoutModule,
    MatDialogModule,
    NgxMaskModule.forRoot(),
    WebSharedModule,
    CommonSharedModule,
    InfiniteScrollModule,
  ],
  providers: [
    ...httpInterceptorProviders,
    { provide: AppConfig, useClass: WebAppConfig },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
