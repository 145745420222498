import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserService } from '@pbox/common/core/services/user.service';
import { filter, first, map, Observable, tap } from 'rxjs';

/** UI service for working with the current user. */
@Injectable({
  providedIn: 'root',
})
export class UserAccessService {

  public constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
  ) { }

  /** Interrupts workflow if the current user is not authenticated and redirects to the login page. */
  public ifAuthorized(): Observable<void> {
    return this.userService.isAuthorized$.pipe(
      first(),
      tap(isAuthorized => {
        if (!isAuthorized) {
          this.navigateToAuthPage();
        }
      }),
      filter(isAuthorized => isAuthorized),
      map(() => undefined),
    );
  }

  private navigateToAuthPage(): void {
    this.dialog.closeAll();
    this.router.navigate(['/auth/login']);
  }
}
