import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { OrderItem } from '@pbox/common/core/models/order-item';
import { CartService } from '@pbox/common/core/services/cart.service';
import { createTrackByFunction } from '@pbox/common/core/utils/trackby';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { CommonSharedModule } from '../../common-shared.module';
import { OrderModule } from '../../features/order/order.module';
import { StoreTextsService } from '../../ui-services/store-texts.service';
import { DialogContainerComponent } from '../dialog-container/dialog-container.component';
import { LoaderComponent } from '../loader/loader.component';

/** Cart dialog. */
@UntilDestroy()
@Component({
  selector: 'pboxc-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CommonSharedModule,
    OrderModule,
    RouterModule,
    MatDialogModule,
    DialogContainerComponent,
    LoaderComponent,
  ],
})
export class CartDialogComponent implements OnInit {

  /** Title. */
  @Input()
  public set title(value: string | null) {
    if (value != null) {
      this.titleSubject.next(value);
    }
  }

  /** Cart items. */
  protected readonly cartItems$: Observable<readonly OrderItem[]>;

  /** Cart total price. */
  protected readonly cartTotalPrice$: Observable<number>;

  /** Whether the cart is loading or not. */
  protected readonly isCartLoading$: Observable<boolean>;

  /** Whether checkout is available or not. */
  protected readonly isCheckoutAvailable$: Observable<boolean>;

  /** Trackby function for a cart item. */
  protected readonly trackCartItem = createTrackByFunction<OrderItem>('id');

  /** Title. */
  protected readonly title$: Observable<string>;

  /** Title subject. */
  private readonly titleSubject = new BehaviorSubject<string>('');

  public constructor(
    private readonly storeTextsService: StoreTextsService,
    protected readonly cartService: CartService,
  ) {
    this.cartItems$ = this.cartService.cart$.pipe(
      map(({ items }) => items),
    );

    this.isCartLoading$ = this.cartService.isCartLoading$;

    this.cartTotalPrice$ = this.cartService.cart$.pipe(
      map(({ price }) => price.groceries),
    );

    this.isCheckoutAvailable$ = this.cartService.isCheckoutAvailable$;
    this.title$ = this.titleSubject.asObservable();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.storeTextsService.text$.pipe(
      tap(text => {
        if (this.title == null) {
          this.titleSubject.next(text.order.groceriesTitle);
        }
      }),
      untilDestroyed(this),
    ).subscribe();
  }
}
