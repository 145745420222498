import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@pbox/common/core/models/order';

/** Order date. */
@Component({
  selector: 'pboxc-order-date',
  templateUrl: './order-date.component.html',
  styleUrls: ['./order-date.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDateComponent {

  /** Order to display date for. */
  @Input()
  public order: Order | null = null;
}
