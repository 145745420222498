import { Injectable } from '@angular/core';

import { Delivery, ShortDelivery, DeliveryStatus, DeliveryUpdateData } from '../../models/delivery';

import { DeliveryDto, ShortDeliveryDto, DeliveryUpdateDto } from './dto/delivery.dto';
import { IMapperFromDto } from './mappers';

/** Delivery mapper. */
@Injectable({
  providedIn: 'root',
})
export class DeliveryMapper implements IMapperFromDto<DeliveryDto, Delivery> {

  /**
   * Maps short delivery from DTO.
   * @param dto DTO.
   */
  public mapShortDeliveryFromDto(dto: ShortDeliveryDto): ShortDelivery {
    return {
      key: dto.key,
      deliveryRange: dto.delivery_date_text,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: DeliveryDto): Delivery {
    return {
      ...this.mapShortDeliveryFromDto(dto),
      menuOpeningDate: new Date(dto.open_menu_date),
      status: dto.is_skip ? DeliveryStatus.Skipped : DeliveryStatus.Opened,
      isStatusChangeable: dto.can_update,
    };
  }

  /**
   * Maps edit data to dto.
   * @param data Data.
   */
  public mapUpdateDeliveryToDto(data: DeliveryUpdateData): DeliveryUpdateDto {
    return {
      key: data.key,
      is_skip: data.status === DeliveryStatus.Skipped,
    };
  }
}
