<button
  class="button"
  type="button"
  title="Decrease by 1"
  [disabled]="isDecrementDisabled || disabled"
  (click)="decrement()"
>
  <mat-icon class="button__icon">remove</mat-icon>
</button>

<span
  class="count"
  [pboxcSkeleton]="controlValue"
  [pboxcSkeletonMinWidth]="3"
  [pboxcSkeletonMaxWidth]="3"
>
  {{ controlValue }}
</span>

<button
  class="button"
  type="button"
  title="Increase by 1"
  [disabled]="isIncrementDisabled || disabled"
  (click)="increment()"
>
  <mat-icon class="button__icon">add</mat-icon>
</button>
