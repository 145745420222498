<div class="autocomplete">
  <input
    type="text"
    class="pboxc-autocomplete autocomplete__input"
    [ngClass]="{ 'pboxc-autocomplete__clearable': isClearable }"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [ngModel]="controlValue"
    (ngModelChange)="onChange($event)"
    [matAutocomplete]="auto"
    #autocompleteInput
  />
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="toReadable$ | async">
    <ng-container *ngIf="trackBy$ | async as trackBy">
      <mat-option
        (click)="onOptionClick()"
        *ngFor="let item of data$ | async; trackBy: trackBy"
        [value]="item"
      >
        <ng-container *ngIf="toReadable$ | async as toReadable">
          {{ toReadable(item) }}
        </ng-container>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="(data$ | async)?.length === 0">
      <mat-option disabled>
        <span class="autocomplete__no-items-message"> No items found </span>
      </mat-option>
    </ng-container>

    <ng-container
      *ngIf="(data$ | async) === null && (filterValue$ | async) !== ''"
    >
      <!-- Iterating over primitives -->
      <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
      <mat-option disabled *ngFor="let i of [1, 2, 3, 4]">
        <span
          class="autocomplete__text autocomplete__text_skeleton"
          pboxcSkeleton
          [pboxcSkeletonMinWidth]="10"
          [pboxcSkeletonMaxWidth]="20"
        ></span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>
</div>
