import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User, UserStatus } from '@pbox/common/core/models/user';
import { NO_ASSIGNED_LOCATION_MESSAGE, PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE } from '@pbox/common/core/services/cart.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { tap } from 'rxjs';

const CART_URL = '/order/cart';
const WELCOME_URL = '/welcome';
const WELCOME_BACK_URL = '/welcome/back/recurring-orders';
const ACCOUNT_IS_SUSPENDED_MESSAGE = 'Please notice there is a negative balance on your account. Update your credit card to proceed.';

/** Service for order. */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OrderService {

  private userSnapshot: User | null = null;

  public constructor(
    private readonly router: Router,
    private readonly userService: UserService,
    private readonly notificationService: NotificationService,
  ) {

    this.userService.currentUser$.pipe(
      filterNull(),
      tap(user => {
        this.userSnapshot = user;
      }),
      untilDestroyed(this),
    ).subscribe();
  }

  /** Whether cart dialog should be showed. */
  public canOpenCartDialog(): boolean {
    return this.canAccessOrder() && this.isMenuOpen() && this.canAccessCart() && this.router.url.includes(CART_URL) === false;
  }

  /** Whether order pages are can be accessed. */
  public canAccessOrder(): boolean {
    if (this.userSnapshot == null) {
      return false;
    }

    if (this.userSnapshot.status === UserStatus.Suspended) {
      this.notificationService.notify(ACCOUNT_IS_SUSPENDED_MESSAGE);
    }

    if (this.userSnapshot.status === UserStatus.Processing) {
      this.notificationService.notify(PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE);
    }

    if (this.userSnapshot.hasAssignedLocation === false) {
      this.notificationService.notify(NO_ASSIGNED_LOCATION_MESSAGE);
    }

    if (
      this.userSnapshot.status === UserStatus.Suspended ||
      this.userSnapshot.status === UserStatus.Processing ||
      this.userSnapshot.hasAssignedLocation === false
    ) {
      return false;
    }
    return true;
  }

  /** Whether menu is opened. */
  public isMenuOpen(): boolean {

    if (this.userSnapshot?.menu.isOpen === true) {
      return true;
    }

    // Show notification when user is redirected to Welcome page and Welcome back page
    if (this.router.url.includes(WELCOME_BACK_URL) === false && this.router.url.includes(WELCOME_URL) === false) {
      const redirectReason = 'The menu opens Friday morning!';
      this.notificationService.notify(redirectReason);
    }
    return false;
  }

  /** Whether cart page is can be accessed. */
  public canAccessCart(): boolean {
    return this.userSnapshot?.hasCart ?? false;
  }

}
