import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@pbox/common/core/services/user.service';
import { Observable, tap } from 'rxjs';

/** UI service for logging out of the system. */
@Injectable({
  providedIn: 'root',
})
export class LogoutPageService {

  public constructor(
    private readonly router: Router,
    private readonly userService: UserService,
  ) { }

  private navigateToAuthPage(): void {
    this.router.navigate(['/auth/login']);
  }

  /** Handle redirect when user logout. */
  public logoutRedirectSideEffect(): Observable<void> {
    return this.userService.isLoggedOut$.pipe(
      tap(() => this.navigateToAuthPage()),
    );
  }
}
