import { Pipe, PipeTransform } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

export const PHONE_MASK = '(000) 000-0000';

/** Phone pipe. */
@Pipe({
  name: 'pboxcPhone',
})
export class PhonePipe implements PipeTransform {

  public constructor(
    private readonly maskPipe: MaskPipe,
  ) { }

  /**
   * Transforms phone number to formatted phone.
   * @param value Phone number.
   * @param format Format.
   */
  public transform(value: string | null | undefined): string | null {
    if (value == null) {
      return null;
    }

    return this.maskPipe.transform(value, PHONE_MASK);
  }
}
