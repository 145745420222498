import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address } from '@pbox/common/core/models/address';
import { State } from '@pbox/common/core/models/state';
import { StateService } from '@pbox/common/core/services/state.service';
import { assertNonNull } from '@pbox/common/core/utils/assert-non-null';
import { createTrackByFunction } from '@pbox/common/core/utils/trackby';
import { FlatControlsOf } from '@pbox/common/core/utils/types/controls-of';
import { NullableProperties } from '@pbox/common/core/utils/types/nullable-properties';
import { ignoreElements, Observable, switchMap, tap } from 'rxjs';

import { SelectConfiguration } from '../../controllers/select-controller';
import { GoogleMapPlaces } from '../google-places-autocomplete/google-places-autocomplete.component';

export type AddressFormData = NullableProperties<Address, 'state'> & {
  readonly googlePlaces: FormControl<GoogleMapPlaces | null>;
};
export type AddressForm = FlatControlsOf<AddressFormData>;

export namespace AddressForm {

  /**
   * Form group initializer.
   * @param fb Form builder.
   */
  export function initialize(fb: FormBuilder): FormGroup<AddressForm> {
    return fb.group<AddressForm>({
      addressLine: fb.nonNullable.control('', [Validators.required]),
      city: fb.nonNullable.control('', [Validators.required]),
      state: fb.control<State | null>(null, [Validators.required]),
      suite: fb.control<string | null>(null),
      zipCode: fb.nonNullable.control('', [Validators.required]),
      googlePlaces: fb.control<GoogleMapPlaces | null>(null, [Validators.required]),
    });
  }

  /**
   * Maps form data to address.
   * @param form Form.
   */
  export function mapAddressFromForm(form: FormGroup<AddressForm>): Address {
    const address = form.getRawValue();
    const { state } = address;
    assertNonNull(state);

    return { ...address, state };
  }
}

/** Address component. */
@UntilDestroy()
@Component({
  selector: 'pboxc-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent implements OnInit {

  /** Address form. */
  @Input()
  public addressFormGroup?: FormGroup<AddressForm>;

  /** Whether required mark should be show on required field. */
  @Input()
  public withRequiredMark = false;

  /** Select config for state. */
  public stateAutocompleteConfig: SelectConfiguration<State>;

  public constructor(
    private readonly stateService: StateService,
  ) {
    this.stateAutocompleteConfig = {
      get: () => this.stateService.states$,
      toReadable: State.toReadable,
      trackBy: createTrackByFunction<State>('id'),
    };
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.fillFormOnGooglePlaceChangeSideEffect().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

  private fillFormOnGooglePlaceChangeSideEffect(): Observable<void> {
    assertNonNull(this.addressFormGroup);
    return this.addressFormGroup.controls.googlePlaces.valueChanges.pipe(
      tap(googlePlaces => {
        assertNonNull(this.addressFormGroup);
        if (googlePlaces) {
          this.addressFormGroup.controls.addressLine.patchValue(googlePlaces.addressLine);
          this.addressFormGroup.controls.city.patchValue(googlePlaces.city);
          this.addressFormGroup.controls.zipCode.patchValue(googlePlaces.zipCode);
        }
      }),
      switchMap(() => this.stateService.states$),
      tap(states => {
        assertNonNull(this.addressFormGroup);

        const stateFromGooglePlace = this.addressFormGroup.controls.googlePlaces.value?.state.name;
        const matchedState = states.find(state => state.name === stateFromGooglePlace);
        this.addressFormGroup.controls.state.setValue(matchedState ?? null);
      }),
      ignoreElements(),
    );
  }
}
