import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, TrackByFunction, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AutocompleteConfiguration, ToReadableFunction } from '@pbox/common/core/models/autocomplete-configuration';
import { assertNonNull } from '@pbox/common/core/utils/assert-non-null';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';

/** Autocomplete component. */
@UntilDestroy()
@Component({
  selector: 'pboxc-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => AutocompleteComponent)],
})
export class AutocompleteComponent<T> extends SimpleValueAccessor<T> implements OnInit {

  /** Autocomplete element. */
  @ViewChild('autocompleteInput', { read: ElementRef })
  public autocompleteElement!: ElementRef;

  /** Placeholder text. */
  @Input()
  public placeholder = '';

  /** Autocomplete configuration. */
  @Input()
  public set configuration(c: AutocompleteConfiguration<T> | null) {
    if (c != null) {
      this.configuration$.next(c);
    }
  }

  /** Whether autocomplete has clear button or not. */
  @Input()
  public isClearable = false;

  /** Search value control. */
  protected readonly filterValue$ = new BehaviorSubject<string>('');

  /** Fetched objects. */
  protected readonly data$: Observable<readonly T[] | null>;

  /** Function, obtained from configuration, makes T item human-readable. */
  protected readonly toReadable$: Observable<ToReadableFunction<T | null>>;

  /** Track by function. */
  protected readonly trackBy$: Observable<TrackByFunction<T>>;

  /** Autocomplete configuration. */
  protected readonly configuration$ = new ReplaySubject<AutocompleteConfiguration<T>>(1);

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);

    this.data$ = this.configuration$.pipe(
      switchMap(configuration => {
        assertNonNull(configuration.get);
        return this.filterValue$.pipe(
          switchMap(filter => configuration.get({ searchString: filter })),
        );
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.toReadable$ = this.configuration$.pipe(
      map(({ toReadable }) => (value: T | null) => {
        if (value != null) {
          return toReadable(value);
        }
        return '';
      }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.trackBy$ = this.configuration$.pipe(
      map(({ trackBy }) => (index: number, value: T) => trackBy(index, value)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

  }

  /** @inheritdoc */
  public ngOnInit(): void {

    const prefillSideEffect$ = this.configuration$.pipe(
      switchMap(({ toReadable }) => combineLatest([this.filterValue$, this.data$.pipe(filterNull())]).pipe(
        map(([searchValue, data]) => data?.find(item => toReadable(item).toLocaleLowerCase() === searchValue.toLocaleLowerCase())),
      )),
      filterNull(),
      tap(valueMatchedByQuery => {
        this.controlValue = valueMatchedByQuery;
      }),
    );

    prefillSideEffect$.pipe(
      untilDestroyed(this),
    ).subscribe();

  }

  /**
   * Handles autocomplete change.
   * @param data Autocomplete data.
   */
  public onChange(data: string | T): void {
    if (typeof data === 'string') {
      this.filterValue$.next(data);
      this.emitChange(null);
    } else {
      this.controlValue = data;
    }
  }

  /**
   * Handle clear autocomplete.
   */
  public onClear(): void {
    this.controlValue = null;
    this.emitChange(null);
  }

  /** Handle click on options field. */
  public onOptionClick(): void {
    this.autocompleteElement.nativeElement.blur();
  }
}
