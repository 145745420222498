import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Delivery info message. */
@Component({
  selector: 'pboxc-delivery-info-message',
  templateUrl: './delivery-info-message.component.html',
  styleUrls: ['./delivery-info-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryInfoMessageComponent {

}
