<header>
  <h2 [class]="titleClass" mat-dialog-title>{{ options.title }}</h2>
</header>
<div>
  <mat-dialog-content class="message">{{ options.message }}</mat-dialog-content>
</div>
<footer class="footer">
  <mat-dialog-actions class="footer__controls" align="center">
    <button type="button" class="primary footer__button" mat-dialog-close>
      {{ options.buttonTitle }}
    </button>
  </mat-dialog-actions>
</footer>
