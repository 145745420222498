<ng-container
  *ngIf="{
    paidItems: (orderPageService.cart$ | async)?.paidItems
  } as info"
>
  <pboxc-order-items-list
    class="items-list"
    [orderItems]="info?.paidItems || null"
  >
    <ng-template #itemTemplate let-item>
      <div class="item-footer">
        <span class="item-footer__price">{{ item.price | currency }}</span>
        <div class="item-footer__control">
          <pboxc-quantity-control
            [ngModel]="item.quantity"
            [disabled]="true"
          ></pboxc-quantity-control>
          <button
            class="text with-icon delete-button"
            type="button"
            disabled
            aria-hidden="true"
          >
            <mat-icon class="delete-button__icon" svgIcon="delete"></mat-icon>
          </button>
        </div>
      </div>
    </ng-template>

    <ng-template #itemSkeletonTemplate>
      <div class="item-footer">
        <pboxc-cart-item-price></pboxc-cart-item-price>
        <pboxc-cart-item-quantity></pboxc-cart-item-quantity>
      </div>
    </ng-template>
  </pboxc-order-items-list>
</ng-container>
