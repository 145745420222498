import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';

import { AppError } from '../models/app-error';

import { Pagination } from '../models/pagination';
import { Product } from '../models/product';
import { ProductSubscription, ProductSubscriptionEditData } from '../models/product-subscription';
import { ScheduleType } from '../models/schedule-type';
import { Store } from '../models/store';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { BaseResponseDto, ResponseWithPaginationDto } from './mappers/dto/base-response.dto';
import { ProductSubscriptionDto, ProductSubscriptionEditDto } from './mappers/dto/product-subscription.dto';
import { ScheduleTypeDto } from './mappers/dto/schedule-type.dto';
import { ApiError } from './mappers/dto/validation-error.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { ProductSubscriptionMapper } from './mappers/product-subscription.mapper';
import { ScheduleTypeMapper } from './mappers/schedule-type.mapper';

/** Subscriptions API service. */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionsApiService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly paginationMapper: PaginationMapper,
    private readonly productSubscriptionMapper: ProductSubscriptionMapper,
    private readonly scheduleTypeMapper: ScheduleTypeMapper,
  ) {
  }

  /** Obtains a list of current subscriptions. */
  public getSubscriptions(): Observable<Pagination<ProductSubscription>> {
    return this.httpClient.get<ResponseWithPaginationDto<ProductSubscriptionDto>>(this.apiUrls.subscriptions.list).pipe(
      map(pageDto => this.paginationMapper.mapPaginationFromDto(pageDto, this.productSubscriptionMapper)),
    );
  }

  /**
   * Adds a subscription to the product.
   * @param productId Product ID.
   * @param storeId Store ID.
   */
  public addSubscription(productId: Product['id'], storeId: Store.Data['id']): Observable<ProductSubscription> {
    return this.httpClient.post<BaseResponseDto<ProductSubscriptionDto>>(
      this.apiUrls.subscriptions.add,
      {
        product_id: productId,
        store_id: storeId,
      },
    ).pipe(
      map(response => this.productSubscriptionMapper.fromDto(response.data)),
      catchHttpErrorResponse(error => throwError(() => new AppError(error.error.message ?? error.message))),
    );
  }

  /**
   * Updates the subscription information.
   * @param subscriptionId Subscription ID.
   * @param data Data required to update the subscription.
   */
  public updateSubscription(subscriptionId: ProductSubscription['id'], data: ProductSubscriptionEditData): Observable<void> {
    return this.httpClient.patch<void>(
      this.apiUrls.subscriptions.update(subscriptionId.toString()),
      this.productSubscriptionMapper.mapEditDataToDto(data),
    ).pipe(
      catchHttpErrorResponse(error => {
        const { message, errors } = error.error as ApiError<ProductSubscriptionEditDto>;
        const updateMessage = errors?.start_week ? errors.start_week[0] : message;

        return throwError(() => new AppError(updateMessage));
      }),
    );
  }

  /**
   * Removes the subscription from the product.
   * @param id Subscription ID.
   */
  public removeSubscription(id: ProductSubscription['id']): Observable<void> {
    return this.httpClient.delete<void>(this.apiUrls.subscriptions.remove(id.toString()));
  }

  /** Get a list of schedule type. */
  public getScheduleTypes(): Observable<ScheduleType[]> {
    return this.httpClient.get<BaseResponseDto<ScheduleTypeDto[]>>(this.apiUrls.subscriptions.skipTypes).pipe(
      map(response => response.data.map(dto => this.scheduleTypeMapper.fromDto(dto))),
    );
  }
}
