<pboxc-dialog-container class="dialog">
  <div class="content">
    <p class="content__message message">
      Please check your delivery address in your profile if it’s up-to-date.
    </p>
  </div>

  <footer class="footer">
    <ng-content></ng-content>
  </footer>
</pboxc-dialog-container>
