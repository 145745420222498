<div class="date-picker">
  <input
    [disabled]="disabled"
    class="date-picker__control"
    matInput
    [matDatepicker]="picker"
    [matDatepickerFilter]="filterDate"
    [(ngModel)]="controlValue"
    [placeholder]="placeholder"
  />

  <mat-datepicker-toggle
    class="date-picker__toggle"
    matIconSuffix
    [for]="picker"
  ></mat-datepicker-toggle>
  <mat-datepicker #picker></mat-datepicker>
</div>
