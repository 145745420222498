import {
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

import { SelectConfiguration, SelectController } from '../../controllers/select-controller';

/** Select component. */
@Component({
  selector: 'pboxc-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => SelectComponent)],
})
export class SelectComponent<T> extends SimpleValueAccessor<T> {

  /** Placeholder text. */
  @Input()
  public placeholder = '';

  /** Selection configuration. */
  @Input()
  public set configuration(configuration: SelectConfiguration<T> | null) {
    if (configuration != null) {
      this.controller.configuration = configuration;
    }
  }

  /** Select controller. */
  protected readonly controller = new SelectController<T>();
}
