import { Directive, ElementRef, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { listenControlChanges } from '@pbox/common/core/utils/rxjs/listen-control-changes';
import { listenControlTouched } from '@pbox/common/core/utils/rxjs/listen-control-touched';
import { defer, distinctUntilChanged, EMPTY, ignoreElements, of, switchMap, tap } from 'rxjs';

/** Sets empty string for input whenever the value is `0` and the control is `untouched`. */
@UntilDestroy()
// eslint-disable-next-line max-len
@Directive({ selector: 'input[formControl][pboxcNullifyControl],input[formControlName][pboxcNullifyControl],input[ngModel][pboxcNullifyControl]' })
export class NullifyControlDirective implements OnInit {

  public constructor(
    private readonly ngControl: NgControl,
    private readonly elementRef: ElementRef<HTMLInputElement>,
  ) {}

  /** @inheritdoc */
  public ngOnInit(): void {
    const control$ = defer(() => of(this.ngControl.control)).pipe(
      filterNull(),
    );

    const setEmptyValueSideEffect$ = control$.pipe(
      switchMap(control => listenControlTouched(control).pipe(
        switchMap(isTouched => {
          if (isTouched) {
            return EMPTY;
          }
          return listenControlChanges(control).pipe(
            distinctUntilChanged(),
            tap(value => {
              if (value === 0) {
                this.elementRef.nativeElement.value = '';
              }
            }),
          );
        }),
      )),
      ignoreElements(),
    );

    setEmptyValueSideEffect$.pipe(
      untilDestroyed(this),
    ).subscribe();
  }
}
