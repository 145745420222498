import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppError } from '@pbox/common/core/models/app-error';
import { OrderItem } from '@pbox/common/core/models/order-item';
import { Product } from '@pbox/common/core/models/product';
import { StockKeepingUnit } from '@pbox/common/core/models/stock-keeping-unit';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { ProductsService } from '@pbox/common/core/services/products.service';
import { createTrackByFunction } from '@pbox/common/core/utils/trackby';
import { CustomizeProductDialogComponent } from '@pbox/common/shared/modules/customize-product/components/customize-product-dialog/customize-product-dialog.component';
import { map, Observable, ReplaySubject, switchMap } from 'rxjs';

/** Item in the list of order products. */
@Component({
  selector: 'pboxc-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemComponent {

  /** Product. */
  @Input()
  public set orderItem(value: OrderItem) {
    this.orderItemSubject.next(value);
  }

  /** Template for the item's slotted content. */
  @Input()
  public orderItemSlotTemplate?: TemplateRef<unknown>;

  /** Template for the item's slotted skeleton. */
  @Input()
  public orderItemSlotSkeletonTemplate?: TemplateRef<unknown>;

  /** Whether the product images should be displayed. */
  @Input()
  public withImages = true;

  /** Whether customization is enabled in case if order item could be customized. */
  @Input()
  public isCustomizationEnabled = false;

  /** Box items. */
  protected readonly boxItems$: Observable<readonly StockKeepingUnit[]>;

  /** Product. */
  protected readonly orderItemSubject = new ReplaySubject<OrderItem>(1);

  /** Trackby function for item. */
  protected readonly trackItem = createTrackByFunction<StockKeepingUnit>('id');

  /** Function that checks whether a product could be customized or not. */
  protected readonly isProductCustomizable = Product.isCustomizable;

  /** Whether the tile is expanded. */
  protected expanded = false;

  public constructor(
    protected readonly appConfig: AppConfig,
    private readonly productsService: ProductsService,
    private readonly dialog: MatDialog,
  ) {
    this.boxItems$ = this.orderItemSubject.pipe(
      switchMap(({ product }) => this.productsService.getProductDetails(product.id)),
      map(({ stockKeepingUnits }) => stockKeepingUnits),
    );
  }

  /**
   * Handles click on the customize button.
   * @param product Product.
   */
  protected onCustomizeButtonClick(product: Product): void {
    if (Product.isCustomizable(product)) {
      this.dialog.open(CustomizeProductDialogComponent, {
        panelClass: 'basic-dialog-container',
        data: { product },
      });
    } else {
      throw new AppError(`The ${product.name} product can not be customized!`);
    }
  }
}
