import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard, HOME_PAGE } from '@pbox/common/core/guards/authorized.guard';
import { MenuNotClosedGuard } from '@pbox/common/core/guards/menu-not-closed.guard';
import { NO_INITIAL_REDIRECT, RecommendedNavigationGuard } from '@pbox/common/core/guards/recommended-navigation.guard';
import { UnauthorizedGuard } from '@pbox/common/core/guards/unauthorized.guard';
import { WelcomeLayoutService } from '@pbox/common/shared/layouts/welcome-layout/welcome-layout.service';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ProductsLayoutComponent } from './layouts/products-layout/products-layout.component';
import { WelcomeLayoutComponent } from './layouts/welcome-layout/welcome-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [RecommendedNavigationGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: HOME_PAGE,
      },
      {
        path: '',
        component: AuthLayoutComponent,
        children: [
          {
            path: 'auth',
            canActivate: [AuthorizedGuard],
            loadChildren: () => import('@pbox/common/shared/features/auth/auth.module').then(m => m.AuthModule),
            data: {
              [NO_INITIAL_REDIRECT]: true,
            },
          },
        ],
      },
      {
        path: 'welcome',
        component: WelcomeLayoutComponent,
        canActivate: [UnauthorizedGuard],
        providers: [WelcomeLayoutService],
        loadChildren: () => import('@pbox/web/features/welcome/welcome.module').then(m => m.WebWelcomeModule),
      },
      {
        path: HOME_PAGE,
        component: ProductsLayoutComponent,
        canActivate: [MenuNotClosedGuard],
        loadChildren: () => import('@pbox/web/features/products/products.module').then(m => m.WebProductsModule),
      },
      {
        path: 'user',
        component: ProductsLayoutComponent,
        canActivate: [UnauthorizedGuard],
        loadChildren: () =>
          import('@pbox/web/features/user/user.module').then(m => m.UserModule),
      },
      {
        path: 'order',
        component: ProductsLayoutComponent,
        canLoad: [UnauthorizedGuard],
        loadChildren: () => import('./features/order/order.module').then(m => m.OrderModule),
      },
      {
        path: 'mobile',
        loadChildren: () => import('@pbox/mobile/app.module').then(m => m.AppModule),
      },
    ],
  },

  // Callbacks from EBT payment system, redirects are needed to abstract from the knowledge of app routes
  {
    path: 'carts/:id',
    redirectTo: 'order/ebt-result',
  },
  {
    path: 'mobile/carts/:id',
    redirectTo: 'order/ebt-result',
  },
];

/** App routing module. */
@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
