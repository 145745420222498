<ng-container
  *ngIf="{
    options: controller.items$ | async,
    toReadable: controller.toReadable$ | async,
    trackBy: controller.trackBy$ | async,
    comparator: controller.comparator$ | async
  } as config"
>
  <mat-select
    *ngIf="
      config.trackBy !== null && config.toReadable !== null && config.comparator
    "
    [disabled]="disabled"
    [placeholder]="placeholder"
    [(ngModel)]="controlValue"
    [compareWith]="config.comparator"
    class="select pboxc-input"
  >
    <mat-option
      *ngFor="let option of config.options; trackBy: config.trackBy"
      [value]="option"
    >
      {{ config.toReadable(option) }}
    </mat-option>

    <ng-container *ngIf="config.options?.length === 0">
      <mat-option disabled>
        <span class="select__no-items-message"> No items found </span>
      </mat-option>
    </ng-container>

    <ng-container *ngIf="config.options === null">
      <!-- Iterating over primitives -->
      <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
      <mat-option disabled *ngFor="let i of [1, 2, 3, 4]">
        <span
          class="select__text select__text_skeleton"
          pboxcSkeleton
          [pboxcSkeletonMinWidth]="10"
          [pboxcSkeletonMaxWidth]="20"
        ></span>
      </mat-option>
    </ng-container>
  </mat-select>
</ng-container>
