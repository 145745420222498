import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';

import { WINDOW } from '../injection-tokens/window-token';

import { AppConfig } from './app.config';

/** Initialization config for FreshChat. */
interface Config {

  /** Access token. */
  readonly token: string;

  /** Host. */
  readonly host: string;
}

declare global {
  interface Window {

    /** FreshChat widget object. */
    fcWidget?: {

      /** Mounts the widget. */
      init(config: Config): void;

      /** Unmounts the widget. */
      destroy(): void;

      /** Make the widget visible. */
      show(): void;

      /** Visually hides the widget. */
      hide(): void;
    };
  }
}

/** Service for interacting with FreshChat API. */
@Injectable({
  providedIn: 'root',
})
export class FreshChatService {

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly config: AppConfig,
  ) { }

  /** Side effect for FreshChat initialization. */
  public initChatSideEffect(): Observable<never> {
    this.window.addEventListener('load', this.initSDK.bind(this), false);

    return EMPTY;
  }

  private initSDK(): void {
    let script: HTMLScriptElement | null = null;

    script = this.document.createElement('script');
    script.id = 'freshchat-js-sdk';
    script.async = true;
    script.src = 'https://wchat.freshchat.com/js/widget.js';
    script.onload = this.initWidget.bind(this);
    this.document.head.appendChild(script);
  }

  private initWidget(): void {
    if (this.window.fcWidget == null) {
      throw new Error('Can not initialize FreshChat widget, SDK is not loaded.');
    }

    this.window.fcWidget.init({ token: this.config.freshChatToken, host: this.config.freshChatHost });
  }
}
