import { Injectable } from '@angular/core';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { environment } from 'projects/web/src/environments/environment';

/** App-specific implementation of app config. */
@Injectable()
export class WebAppConfig extends AppConfig {
  /** @inheritdoc */
  public readonly memberSolutionsLink = environment.memberSolutionsLink;

  /** @inheritdoc */
  public readonly spreedlyToken = environment.spreedlyToken;

  /** @inheritdoc */
  public readonly apiUrl: string = environment.apiUrl;

  /** @inheritdoc */
  public readonly version: string = environment.version;

  /** @inheritdoc */
  public readonly appTenant = environment.appTenant;

  /** @inheritdoc */
  public readonly freshChatToken = environment.freshChatToken;

  /** @inheritdoc */
  public readonly freshChatHost = environment.freshChatHost;

  /** @inheritdoc */
  public readonly hasSnap = environment.hasSnap;
}
