import { ChangeDetectionStrategy, Component } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

type HTMLInputTypeAttribute = 'password' | 'text';
type VisibilityIcons = 'visibility' | 'visibility_off';

/** Password component. */
@Component({
  selector: 'pboxc-password-control',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => PasswordControlComponent)],
})
export class PasswordControlComponent extends SimpleValueAccessor<string> {
  /** Type attribute. */
  protected type: HTMLInputTypeAttribute = 'password';

  /** Icon. */
  protected icon: VisibilityIcons = 'visibility';

  private shouldShowPassword = false;

  /** Show/hide password. */
  public onToggleClick(): void {
    this.shouldShowPassword = !this.shouldShowPassword;

    if (this.shouldShowPassword) {
      this.type = 'text';
      this.icon = 'visibility_off';
    } else {
      this.type = 'password';
      this.icon = 'visibility';
    }
  }
}
