/** State. */
export interface State {

  /** Id. */
  readonly id: number;

  /** Full name. */
  readonly name: string;

  /** Abbreviation. */
  readonly abbreviation: string;
}

/** State. */
export namespace State {

  /**
   * Makes state human-readable.
   * @param state State.
   */
  export function toReadable(state: State): string {
    return state.name;
  }

  /**
   * Comparator of state entities.
   * @param this This.
   * @param left State 1.
   * @param right State 2.
   */
  export function comparator(this: void, left: State, right: State): boolean {
    return left.id === right.id;
  }
}
