import { Injectable } from '@angular/core';

import { Cart } from '../../models/cart';
import { NewOrderItem, OrderItem } from '../../models/order-item';
import { Product } from '../../models/product';

import { CouponMapper } from './coupon.mapper';

import { CartDto } from './dto/cart.dto';
import { AddCustomizedOrderItemDto, AddOrderItemDto, OrderItemDto } from './dto/order-item.dto';
import { IMapperFromDto } from './mappers';
import { ProductMapper } from './product.mapper';
import { OrderMapper } from './order.mapper';

/** Cart info mapper. */
@Injectable({
  providedIn: 'root',
})
export class CartMapper implements IMapperFromDto<CartDto, Cart> {

  public constructor(
    private readonly productMapper: ProductMapper,
    private readonly couponMapper: CouponMapper,
    private readonly orderMapper: OrderMapper,
  ) {}

  /** @inheritdoc */
  public fromDto(data: CartDto): Cart {
    return {
      id: data.cart_id,
      price: {
        delivery: data.totals.delivery_fee,
        groceries: data.totals.groceries_amount,
        membership: data.totals.membership_fee,
        tax: data.totals.estimated_tax,
        total: data.totals.final_amount,
        couponDiscount: data.totals.order_coupon_discount,
        membershipDiscount: data.totals.initial_membership_discount,
        creditAppliedDiscount: data.totals.credit_applied_discount,
        amountLeftForFreeDelivery: data.totals.amount_left_for_free_delivery,
        nonSlapEligibleAmount: data.totals.non_snap_amount,
        snapEligibleAmount: data.totals.snap_amount,
        minimumPriceOfOrder: data.totals.minimum_order,
      },
      coupon: data.coupon ? this.couponMapper.fromDto(data.coupon) : null,
      items: data.items.map(item => this.mapCartItemFromDto(item, data.is_with_every_order)),
      paidItems: data.previous_paid_items.map(item => this.orderMapper.mapDetailedOrderItemFromDto(item)),
      isPaid: data.is_paid,
    };
  }

  /**
   * Maps cart item from dto.
   * @param item Item to map.
   * @param comesWithEveryOrder Whether the item comes with every order.
   */
  public mapCartItemFromDto(item: OrderItemDto, comesWithEveryOrder?: boolean): OrderItem {
    return {
      id: item.cart_item_id,
      quantity: item.quantity,
      product: this.productMapper.fromDto(item.product),
      comesWithEveryOrder: comesWithEveryOrder ?? false,
    };
  }

  /**
   * Maps cart item to dto.
   * @param cartId Cart id.
   * @param cartItem Cart item.
   */
  public newCartItemToDto(cartId: Cart['id'], cartItem: NewOrderItem): AddOrderItemDto {
    return {
      cart_id: cartId,
      product_id: cartItem.product.id,
      quantity: cartItem.quantity,
    };
  }

  /**
   * Maps product to customized cart item dto.
   * @param cartId Cart id.
   * @param product Product.
   */
  public newCustomizedItemToDto(cartId: Cart['id'], product: Product): AddCustomizedOrderItemDto {
    return {
      cart_id: cartId,
      product_id: product.id,
    };
  }
}
