<ng-container *ngIf="item !== null; else skeletonTemplate">
  <div class="quantity">
    <pboxc-quantity-control
      [formControl]="quantityControl"
      class="quantity__control"
      [class.quantity__control_hidden]="isProductSoldOut"
      [max]="getAddableAmount(product)"
    >
    </pboxc-quantity-control>

    <pboxc-sold-out-badge
      *ngIf="isProductSoldOut; else insufficientAmountMessageTemplate"
      class="quantity__sold-out-badge"
      [withIcon]="false"
    ></pboxc-sold-out-badge>

    <ng-template #insufficientAmountMessageTemplate>
      <pboxc-insufficient-amount-badge
        *ngIf="isInsufficientProductsAmount"
        class="quantity__insufficient-amount-badge"
        [amountLeft]="product?.remainingAmount || null"
      >
      </pboxc-insufficient-amount-badge>
    </ng-template>
  </div>

  <button
    class="text with-icon delete-button"
    type="button"
    title="Remove {{ item.product.name }}"
    (click)="onItemDelete()"
    [disabled]="
      (isLoading$ | async) === true ||
      (cartService.isCartPaid$ | async) === true
    "
  >
    <mat-icon class="delete-button__icon" svgIcon="delete"></mat-icon>
  </button>
</ng-container>

<ng-template #skeletonTemplate>
  <pboxc-quantity-control [disabled]="true"></pboxc-quantity-control>
  <button
    aria-hidden
    class="text with-icon delete-button"
    type="button"
    disabled
  >
    <mat-icon class="delete-button__icon" svgIcon="delete"></mat-icon>
  </button>
</ng-template>
