import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { FreshChatService } from '@pbox/common/core/services/freshchat.service';
import { IconsService } from '@pbox/common/core/services/icons.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { SubscriptionsService } from '@pbox/common/core/services/subscriptions.service';
import { ThemeService } from '@pbox/common/core/services/theme.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { LogoutPageService } from '@pbox/common/shared/ui-services/logout-page.service';
import { combineLatest, filter, ignoreElements, merge, Observable, tap } from 'rxjs';

/**
 * Removes ending slash from the route.
 * @param url Route to remove ending slash from.
 */
function removeEndingSlash(url: string): string {
  // Need to remove the ending slash since router ignores the route otherwise
  return url.replace(/\/$/, '');
}

/** Root component. */
@Component({
  selector: 'pboxw-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly breakpointsObserver: BreakpointObserver,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly subscriptionsService: SubscriptionsService,
    private readonly storesService: StoresService,
    private readonly themeService: ThemeService,
    private readonly freshChatService: FreshChatService,
    private readonly logoutPageService: LogoutPageService,
    iconsService: IconsService,
  ) {
    iconsService.registerIcons();
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    // No need to handle the subscription here since it must be alive the whole life of the app.
    merge(
      this.userService.checkTokenExpirationSideEffect(),
      this.logoutPageService.logoutRedirectSideEffect(),
      this.initMobileRoutingSideEffect(),
      this.notificationService.notification$,
      this.subscriptionsService.subscriptions$,
      this.initStoreTitleSideEffect(),
      this.themeService.initThemeSideEffect(),
      this.initThemeAttributeSideEffect(),
      this.freshChatService.initChatSideEffect(),
    ).subscribe();
  }

  /** Redirects a user to mobile module and back depending on the screen size. */
  private initMobileRoutingSideEffect(): Observable<never> {
    const MOBILE_ROUTES_PREFIX = '/mobile';

    return combineLatest([
      this.breakpointsObserver.observe(Breakpoints.XSmall),
      this.router.events.pipe(filter((event): event is NavigationStart => event instanceof NavigationStart)),
    ]).pipe(
      tap(([{ matches: isMobileScreen }, { url }]) => {
        const isMobileRoute = url.startsWith(MOBILE_ROUTES_PREFIX);

        if (isMobileScreen) {
          if (!isMobileRoute) {
            this.router.navigateByUrl(removeEndingSlash(MOBILE_ROUTES_PREFIX.concat(url)));
          }
        } else if (isMobileRoute) {
          this.router.navigateByUrl(removeEndingSlash(url.replace(new RegExp(`^${MOBILE_ROUTES_PREFIX}`), '')));
        }
      }),
      ignoreElements(),
    );
  }

  private initStoreTitleSideEffect(): Observable<never> {
    return this.storesService.currentStore$.pipe(
      tap(store => {
        if (store) {
          document.title = store.name;
        }
      }),
      ignoreElements(),
    );
  }

  private initThemeAttributeSideEffect(): Observable<never> {
    return this.themeService.activeTheme$.pipe(
      tap(theme => this.document.body.setAttribute('data-theme', theme.name)),
      ignoreElements(),
    );
  }
}
