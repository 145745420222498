import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@pbox/common/shared/common-shared.module';

import { CanceledMembershipDialogComponent } from '../../components/canceled-membership-dialog/canceled-membership-dialog.component';

import { DialogContainerComponent } from '../../components/dialog-container/dialog-container.component';

import { QuantityControlComponent } from '../../components/quantity-control/quantity-control.component';

import { LoaderComponent } from '../../components/loader/loader.component';

import { CanceledMembershipNotificationDialogComponent } from './canceled-membership-notification-dialog/canceled-membership-notification-dialog.component';

import { CardComponent } from './card/card.component';
import { CartItemPriceComponent } from './cart-item-price/cart-item-price.component';
import { CartItemQuantityComponent } from './cart-item-quantity/cart-item-quantity.component';
import { CartItemsListComponent } from './cart-items-list/cart-items-list.component';
import { CouponComponent } from './coupon/coupon.component';
import { CreatedOrderControlsComponent } from './created-order-controls/created-order-controls.component';
import { DeliveryInfoMessageComponent } from './delivery-info-message/delivery-info-message.component';
import { FreeDeliveryMessageComponent } from './free-delivery-message/free-delivery-message.component';
import { OrderItemsListModule } from './order-items-list/order-items-list.module';
import { OrderPaymentMethodComponent } from './order-payment-method/order-payment-method.component';
import { OrderSubTotalsComponent } from './order-sub-totals/order-sub-totals.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { PaymentCardBrandsPreviewComponent } from './payment-card-brands-preview/payment-card-brands-preview.component';
import { PaymentMethodsPickerComponent } from './payment-methods-picker/payment-methods-picker.component';
import { TipFormComponent } from './tip-form/tip-form.component';
import { EbtPaymentResultComponent } from './ebt-payment-result/ebt-payment-result.component';
import { CartPaidItemsListComponent } from './cart-paid-items-list/cart-paid-items-list.component';

/** Cart module. */
@NgModule({
  declarations: [
    OrderSummaryComponent,
    CartItemsListComponent,
    FreeDeliveryMessageComponent,
    CardComponent,
    PaymentCardBrandsPreviewComponent,
    PaymentMethodsPickerComponent,
    DeliveryInfoMessageComponent,
    OrderPaymentMethodComponent,
    CouponComponent,
    CartItemQuantityComponent,
    CreatedOrderControlsComponent,
    TipFormComponent,
    OrderSubTotalsComponent,
    CartItemPriceComponent,
    CanceledMembershipNotificationDialogComponent,
    EbtPaymentResultComponent,
    CartPaidItemsListComponent,
  ],
  exports: [
    OrderSummaryComponent,
    CartItemsListComponent,
    FreeDeliveryMessageComponent,
    CardComponent,
    PaymentCardBrandsPreviewComponent,
    PaymentMethodsPickerComponent,
    DeliveryInfoMessageComponent,
    OrderPaymentMethodComponent,
    CreatedOrderControlsComponent,
    CartItemQuantityComponent,
    TipFormComponent,
    OrderSubTotalsComponent,
    CartItemPriceComponent,
    EbtPaymentResultComponent,
    CartPaidItemsListComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    CommonSharedModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    OrderItemsListModule,
    QuantityControlComponent,
    DialogContainerComponent,
    CanceledMembershipDialogComponent,
    LoaderComponent,
  ],
})
export class OrderModule { }
