import { Injectable } from '@angular/core';

import { SortOptions } from '../../models/sort-options';

import { SortDto } from './dto/sort.dto';

/** Sort mapper. */
@Injectable({ providedIn: 'root' })
export class SortMapper {
  /**
   * Maps sort options to dto.
   * @param data Sort options.
   */
  public toDto<T extends string>(data: SortOptions<T>): SortDto {
    return {
      sort_order: data.type,
      order_by: data.field,
    };
  }
}
