
import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { CreateOrderWithCardData } from '../../models/order';

import { CreateOrderWithCardDto } from './dto/order.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Create order data mapper. */
@Injectable({ providedIn: 'root' })
export class CreateOrderDataMapper implements
  IMapperToDto<CreateOrderWithCardDto, CreateOrderWithCardData>,
  IValidationErrorMapper<CreateOrderWithCardDto, CreateOrderWithCardData> {

  /** @inheritdoc */
  public toDto(data: CreateOrderWithCardData): CreateOrderWithCardDto {
    return {
      cart_id: data.cartId,
      credit_card_id: data.card ? data.card.id : null,
      payment_type: 'cc',
      gratuity: data.tipAmount,
      store_id: data.storeId,
    };
  }

  /** @inheritdoc */
  public validationErrorFromDto(error: ValidationErrorDto<CreateOrderWithCardDto>): EntityValidationErrors<CreateOrderWithCardData> {
    return {
      card: extractErrorMessage(error.credit_card_id),
      cartId: extractErrorMessage(error.cart_id),
      paymentMethodType: extractErrorMessage(error.payment_type),
      tipAmount: extractErrorMessage(error.gratuity),
    };
  }

}
