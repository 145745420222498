<pboxc-coupon class="section section_bordered"></pboxc-coupon>

<table *ngIf="cart; else summarySkeletonTemplate" class="price-table">
  <thead class="visually-hidden">
    <th>Entry</th>
    <th>Price</th>
  </thead>
  <tbody class="section section_bordered">
    <tr>
      <td
        class="price-table__cell"
        *ngIf="storeTextsService.text$ | async as text"
      >
        {{ text.order.groceriesTitle }}
      </td>
      <td class="price-table__cell price-table__cell_price">
        {{ cart.price.groceries | currency }}
      </td>
    </tr>
    <tr *ngIf="cart.price.couponDiscount as couponDiscount">
      <td class="price-table__cell">Coupon</td>
      <td
        class="price-table__cell price-table__cell_price"
        title="Coupon feature is not yet available"
      >
        -{{ couponDiscount | currency }}
      </td>
    </tr>
    <tr>
      <td class="price-table__cell">Estimated Tax</td>
      <td class="price-table__cell price-table__cell_price">
        {{ cart.price.tax | currency }}
      </td>
    </tr>
  </tbody>
  <tbody class="section section_bordered">
    <tr *ngIf="cart.price.membership as membershipFee">
      <td class="price-table__cell">Initial Membership Fee</td>
      <td class="price-table__cell price-table__cell_price">
        {{ membershipFee | currency }}
      </td>
    </tr>
    <tr *ngIf="cart.price.membershipDiscount as membershipDiscount">
      <td class="price-table__cell">Membership Discount</td>
      <td class="price-table__cell price-table__cell_price">
        -{{ membershipDiscount | currency }}
      </td>
    </tr>
    <tr *ngIf="cart.price.delivery as delivery">
      <td class="price-table__cell">Delivery</td>
      <td class="price-table__cell price-table__cell_price">
        {{ delivery | currency }}
      </td>
    </tr>
    <tr *ngIf="cart.price.creditAppliedDiscount as creditDiscount">
      <td class="price-table__cell">Credit Applied</td>
      <td class="price-table__cell price-table__cell_price">
        -{{ creditDiscount | currency }}
      </td>
    </tr>
  </tbody>
  <tbody class="section section_bordered" *ngIf="appConfig.hasSnap">
    <tr>
      <td class="price-table__cell">SNAP Eligible Sub Total</td>
      <td class="price-table__cell price-table__cell_price">
        {{ cart.price.snapEligibleAmount | currency }}
      </td>
    </tr>
    <tr>
      <td class="price-table__cell">Non SNAP Eligible Sub Total</td>
      <td class="price-table__cell price-table__cell_price">
        {{ cart.price.nonSlapEligibleAmount | currency }}
      </td>
    </tr>
  </tbody>
</table>

<div class="section section_bordered total">
  <div class="total__label">Total</div>
  <pboxc-money [amount]="cart?.price?.total"></pboxc-money>
</div>

<ng-content> </ng-content>

<ng-template #summarySkeletonTemplate>
  <table class="price-table" aria-hidden>
    <!-- Iterating over primitives -->
    <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
    <tbody class="section section_bordered" *ngFor="let i of [1, 2, 3]">
      <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
      <tr *ngFor="let i of [1, 2, 3]">
        <td class="price-table__cell">
          <span
            pboxcSkeleton
            [pboxcSkeletonMinWidth]="15"
            [pboxcSkeletonMaxWidth]="20"
          ></span>
        </td>
        <td class="price-table__cell price-table__cell_price">
          <span
            pboxcSkeleton
            [pboxcSkeletonMinWidth]="3"
            [pboxcSkeletonMaxWidth]="3"
          ></span>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<ng-template #totalsSkeletonTemplate>
  <div class="section section_bordered total">
    <span
      class="total__label"
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="10"
      [pboxcSkeletonMaxWidth]="10"
    ></span>
    <span
      class="total__price"
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="3"
      [pboxcSkeletonMaxWidth]="3"
    ></span>
  </div>
</ng-template>
