import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppError } from '@pbox/common/core/models/app-error';
import { CompleteEbtCheckoutData, EbtPaymentStatus } from '@pbox/common/core/models/complete-ebt-checkout-data';
import { EbtOrderPaymentService } from '@pbox/common/core/services/ebt-order-payment.service';
import { OrdersService } from '@pbox/common/core/services/orders.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject, map, Observable, ReplaySubject, retry } from 'rxjs';

const NUMBER_OF_RETRIES = 3;
const RETRY_TIME = 10000;

/**
 * Component for handling the EBT completion.
 * Can either accept cancelled or completed payment status.
 * The system will automatically redirect user to this page.
 */
@UntilDestroy()
@Component({
  selector: 'pboxc-ebt-payment-result',
  templateUrl: './ebt-payment-result.component.html',
  styleUrls: ['./ebt-payment-result.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EbtPaymentResultComponent implements OnInit {
  /** Whether the payment is being completed. */
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

  /** Checkout data. */
  private readonly ebtCheckoutData: CompleteEbtCheckoutData;

  /** Last order id. */
  protected readonly lastOrderId$: Observable<number | null>;

  /** Whether the payment transaction was cancelled or failed. */
  protected readonly isPaymentCancelledSubject = new ReplaySubject<boolean>(1);

  public constructor(
    activatedRoute: ActivatedRoute,
    router: Router,
    private readonly ebtOrderPaymentService: EbtOrderPaymentService,
    private readonly ordersService: OrdersService,
  ) {
    try {
      this.ebtCheckoutData = this.ebtOrderPaymentService.mapEbtCheckoutData(activatedRoute.snapshot.queryParams);
    } catch (e: unknown) {
      if (e instanceof AppError) {
        router.navigateByUrl('/');
      }

      throw e;
    }

    this.lastOrderId$ = this.ordersService.getLastOrder().pipe(
      map(order => order?.id ?? null),
    );
    this.isPaymentCancelledSubject.next(this.ebtCheckoutData.status === EbtPaymentStatus.Cancelled);
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    if (this.ebtCheckoutData.status === EbtPaymentStatus.Completed) {
      this.ebtOrderPaymentService.completeEbtCheckout(this.ebtCheckoutData).pipe(

        // Retry when BE doesn't receive data from third-party service
        retry({
          count: NUMBER_OF_RETRIES,
          delay: RETRY_TIME,
        }),
        toggleExecutionState(this.isLoading$),
        untilDestroyed(this),
      )
        .subscribe({
          error: () => this.isPaymentCancelledSubject.next(true),
        });
    }
  }
}
