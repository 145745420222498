import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardBillingInformation } from '@pbox/common/core/models/billing-information';

/** Displays short information about the card payment method. */
@Component({
  selector: 'pboxc-card-payment-method',
  templateUrl: './card-payment-method.component.html',
  styleUrls: ['./card-payment-method.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPaymentMethodComponent {
  /** Card to display. */
  @Input()
  public cardPaymentMethod: CardBillingInformation | null = null;

}
