import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginWithStore } from '../models/login';
import { PasswordChange } from '../models/password-change';
import { PasswordReset } from '../models/password-reset';
import { Registration } from '../models/registration';
import { UserSecret } from '../models/user-secret';
import { assertNonNull } from '../utils/assert-non-null';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { BaseResponseDto } from './mappers/dto/base-response.dto';
import { RegistrationDto } from './mappers/dto/registration.dto';
import { SuccessResponseDto } from './mappers/dto/success-response.dto';
import { UserSecretDto } from './mappers/dto/user-secret.dto';
import { ApiError } from './mappers/dto/validation-error.dto';
import { LoginDataMapper } from './mappers/login-data.mapper';
import { PasswordChangeMapper } from './mappers/password-change.mapper';
import { RegistrationMapper } from './mappers/registration.mapper';
import { ResetPasswordConfirmationMapper } from './mappers/reset-password-confirmation.mapper';
import { ResetPasswordMapper } from './mappers/reset-password.mapper';
import { UserSecretDataMapper } from './mappers/user-secret-data.mapper';

/** Performs CRUD operations for auth-related information. */
@Injectable({ providedIn: 'root' })
export class AuthApiService {
  public constructor(
    private readonly apiUrlsConfig: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly loginDataMapper: LoginDataMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly userSecretMapper: UserSecretDataMapper,
    private readonly resetPasswordMapper: ResetPasswordMapper,
    private readonly resetPasswordConfirmationMapper: ResetPasswordConfirmationMapper,
    private readonly passwordChangeMapper: PasswordChangeMapper,
    private readonly registrationMapper: RegistrationMapper,
  ) { }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: LoginWithStore): Observable<UserSecret> {
    return this.httpClient.post<BaseResponseDto<UserSecret>>(
      this.apiUrlsConfig.auth.login,
      this.loginDataMapper.toDto(loginData),
    )
      .pipe(
        map(dto => this.userSecretMapper.fromDtoWithExpired(dto.data, loginData.keepLoggedInForOneMonth)),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.loginDataMapper,
        ),
      );
  }

  /**
   * Refresh user's secret.
   * @param secret Secret data.
   */
  public refreshSecret(
    secret: UserSecret,
  ): Observable<UserSecret> {
    return this.httpClient.put<BaseResponseDto<UserSecretDto>>(
      this.apiUrlsConfig.auth.refreshSecret,
      this.userSecretMapper.toDto(secret),
    )
      .pipe(
        map(refreshedSecret => this.userSecretMapper.fromDto(refreshedSecret.data)),
        this.appErrorMapper.catchHttpErrorToAppError(),
      );
  }

  /**
   * Sends request to reset the password.
   * @param data Data for password reset.
   * @returns Success message.
   */
  public resetPassword(data: PasswordReset.Data): Observable<void> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.resetPassword,
      this.resetPasswordMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordMapper,
        ),
      );
  }

  /**
   * Confirms password reset and applies new passwords to the account.
   * @param data New passwords data.
   * @returns Success message.
   */
  public confirmPasswordReset(
    data: PasswordReset.Confirmation,
  ): Observable<void> {
    return this.httpClient.put<SuccessResponseDto>(
      this.apiUrlsConfig.auth.confirmPasswordReset,
      this.resetPasswordConfirmationMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.resetPasswordConfirmationMapper,
        ),
      );
  }

  /**
   * Changes password of current user.
   * @param data Data required for password changing.
   */
  public changePassword(data: PasswordChange): Observable<void> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.user.changePassword,
      this.passwordChangeMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.passwordChangeMapper,
        ),
      );
  }

  /**
   * Register user.
   * @param data Registration data.
   */
  public registerUser(data: Registration): Observable<void> {
    return this.httpClient.post<SuccessResponseDto>(
      this.apiUrlsConfig.auth.register,
      this.registrationMapper.toDto(data),
    )
      .pipe(
        map(() => undefined),
        catchHttpErrorResponse(error => throwError(() => {
            const isValidateErrors = error.status === HttpStatusCode.UnprocessableEntity;
            const validateErrorMessage = 'Uh oh, please check your info!';
            const { message } = error.error as ApiError<RegistrationDto>;
            const { url } = error;

            // Bypass typescript type check although we only update message.
            assertNonNull(url);

            return new HttpErrorResponse({
              ...error,
              url,
              error: { ...error.error, message: isValidateErrors ? validateErrorMessage : message },
            });
          })),
        this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(
          this.registrationMapper,
        ),
      );
  }
}
