import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { User } from '../../models/user';

import { UserDto } from './dto/user.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Profile settings mapper. */
@Injectable({
  providedIn: 'root',
})
export class ChangeProfileSettingsMapper
implements IMapperToDto<UserDto.ChangeProfileSettings, User.ChangeProfileSettings>,
IValidationErrorMapper<UserDto.ChangeProfileSettings, User.ChangeProfileSettings> {

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<UserDto.ChangeProfileSettings> | null | undefined,
  ): EntityValidationErrors<User.ChangeProfileSettings> {
    return {
      firstName: extractErrorMessage(errorDto?.first_name),
      lastName: extractErrorMessage(errorDto?.last_name),
      email: extractErrorMessage(errorDto?.email),
      phone: extractErrorMessage(errorDto?.phone),
      password: extractErrorMessage(errorDto?.password),
      confirmPassword: extractErrorMessage(errorDto?.re_password),
    };
  }

  /** @inheritdoc */
  public toDto(data: User.ChangeProfileSettings): UserDto.ChangeProfileSettings {
    const passwordDto = data.password === '' ? { } : {
      password: data.password,
      re_password: data.confirmPassword,
    };

    return {
      first_name: data.firstName,
      last_name: data.lastName,
      phone: data.phone,
      email: data.email,
      is_account_email_notification: data.accountEmail,
      is_cell_phone_notification: data.cellPhone,
      is_menu_email_notification: data.menuEmail,
      is_special_email_notification: data.specialEmail,
      ...passwordDto,
    };
  }
}
