import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

/** Canceled membership notification dialog. */
@UntilDestroy()
@Component({
  selector: 'pboxc-canceled-membership-notification-dialog',
  templateUrl: './canceled-membership-notification-dialog.component.html',
  styleUrls: ['./canceled-membership-notification-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CanceledMembershipNotificationDialogComponent { }
