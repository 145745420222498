import { Injectable } from '@angular/core';
import { map, Observable, shareReplay, switchMap } from 'rxjs';

import { Store } from '../models/store';
import { assertNonNull } from '../utils/assert-non-null';

import { StoresApiService } from './stores-api.service';
import { Tenant, TenantsService } from './tenants.service';

const TENANT_TO_STORE_SLUG_MAP: Record<Tenant, Store.Slug> = {
  [Tenant.Default]: Store.Slug.TheProduceBox,
  [Tenant.TheProduceBox]: Store.Slug.TheProduceBox,
  [Tenant.Suppermeals]: Store.Slug.Suppermeals,
  [Tenant.CarolinaFlavors]: Store.Slug.CarolinaFlavors,
  [Tenant.NCStateFarmersMarket]: Store.Slug.NCStateFarmersMarket,
  [Tenant.TPBWholesale]: Store.Slug.TPBWholesale,
};

/** Stores service. */
@Injectable({
  providedIn: 'root',
})
export class StoresService {

  /** List of available stores. */
  public readonly stores$: Observable<readonly Store.Data[]>;

  /** Current store. */
  public readonly currentStore$: Observable<Store.Data>;

  /** Whether the current store supports subscriptions or not. */
  public readonly areSubscriptionsEnabled$: Observable<boolean>;

  /** URL to the terms of service file for the current store. */
  public readonly termsOfService$: Observable<string>;

  /** Whether delivery should be enabled. */
  public readonly isDeliveryEnabled$: Observable<boolean>;

  public constructor(
    storesApiService: StoresApiService,
    tenantsService: TenantsService,
  ) {
    this.stores$ = storesApiService.getStores().pipe(
      map(({ items }) => items),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.currentStore$ = this.stores$.pipe(
      switchMap(stores => tenantsService.currentTenant$.pipe(
        map(tenant => {
          if (tenant === Tenant.Default) {
            return stores[0];
          }

          const currentStore = stores.find(store => store.slug === TENANT_TO_STORE_SLUG_MAP[tenant]);
          assertNonNull(currentStore);

          return currentStore;
        }),
      )),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    // Subscriptions are supported only in the TPB store.
    this.areSubscriptionsEnabled$ = this.currentStore$.pipe(map(({ slug }) => slug === Store.Slug.TheProduceBox));

    this.termsOfService$ = this.currentStore$.pipe(
      map(({ slug }) =>
      slug === Store.Slug.Suppermeals ? '/assets/documents/terms-of-service-sm.pdf' : '/assets/documents/terms-of-service-tpb.pdf'),
    );
    this.isDeliveryEnabled$ = this.currentStore$.pipe(map(({ isDeliveryEnabled }) => isDeliveryEnabled));
  }

}
