import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Price highlighted in primary color. */
@Component({
  selector: 'pboxc-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoneyComponent {

  /** Price amount. */
  @Input()
  public amount?: number | null;

}
