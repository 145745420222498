<button
  type="button"
  class="
    subscribe-button
    subscribe-button_active
    subscribe-button_{{ type }}
    basic
    with-icon
  "
  disabled
  *ngIf="isSubscribed; else subscribeButtonTemplate"
>
  <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
  {{ getSubscribedTitle(product) }}
</button>
<ng-template #subscribeButtonTemplate>
  <button
    type="button"
    class="subscribe-button subscribe-button_{{ type }} basic with-icon"
    [pboxcLoading]="isLoading"
    (click)="onSubscribeButtonClick()"
  >
    <ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
    {{ getSubscribeTitle(product) }}
    <ng-container *ngIf="subscriptionPrice as price">
      for {{ price | currency }}
    </ng-container>
  </button>
</ng-template>

<ng-template #iconTemplate>
  <mat-icon
    [svgIcon]="type === 'normal' ? 'subscribe' : 'dollar-badge'"
    class="subscribe-button__icon"
  ></mat-icon>
</ng-template>
