import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DialogCloseButtonComponent } from '../dialog-close-button/dialog-close-button.component';

/** Dialog container. */
@Component({
  selector: 'pboxc-dialog-container',
  templateUrl: './dialog-container.component.html',
  styleUrls: ['./dialog-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DialogCloseButtonComponent],
})
export class DialogContainerComponent {

  /** Whether should have the close button or not. */
  @Input()
  public hasCloseButton = false;
}
