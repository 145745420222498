import { ChangeDetectorRef, Directive } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartItemUpdateError } from '@pbox/common/core/errors/cart-item-update-error';
import { NotAllowedToUpdateCartError } from '@pbox/common/core/errors/not-allowed-to-update-cart-error';
import { Product } from '@pbox/common/core/models/product';
import { CartService } from '@pbox/common/core/services/cart.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { BehaviorSubject, catchError, filter, ignoreElements, MonoTypeOperatorFunction, Observable, of, tap, throwError } from 'rxjs';

/** Base implementation of component interacting with the quantity control. */
@Directive()
export abstract class QuantityControlBase {

  /** Whether the product is being added/deleted. */
  protected readonly isLoading$ = new BehaviorSubject(false);

  /** Amount of products in the cart. */
  protected readonly quantityControl = this.fb.control(0);

  /** Product to change the quantity for. */
  protected abstract product: Product | null;

  public constructor(
    protected readonly cartService: CartService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    private readonly fb: NonNullableFormBuilder,
    protected readonly notificationService: NotificationService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) { }

  /** Checks whether the product should be marked as sold out. */
  protected get isProductSoldOut(): boolean {
    return this.product?.isSoldOut === true;
  }

  /** In case there is less products than user has in the cart. */
  protected get isInsufficientProductsAmount(): boolean {
    return this.product != null && this.quantityControl.value > this.product.remainingAmount;
  }

  /**
   * Initializes side effect responsible for filling quantity control.
   * @param product Product.
   */
  protected initQuantityControlFillSideEffect(product: Product): Observable<never> {
    return this.cartService.getProductsQuantity(product.id).pipe(
      filter(() => !this.isLoading$.getValue()),
      tap(quantity => this.quantityControl.setValue(quantity, { emitEvent: false })),
      tap(() => this.changeDetectorRef.markForCheck()),
      ignoreElements(),
    );
  }

  /** Catches and handles the error of unsuccessful cart item update. */
  protected catchCartItemUpdateError(): MonoTypeOperatorFunction<void> {
    return catchError((error: unknown) => {
      if (error instanceof CartItemUpdateError) {
        const rollbackQuantityValue = error.updateData.quantity - 1;
        this.notificationService.notify(error.message);
        this.quantityControl.setValue(rollbackQuantityValue, { emitEvent: false });
        this.changeDetectorRef.markForCheck();

        return of(undefined);
      }

      return throwError(() => error);
    });
  }

  /** Catches the error of when the user is not allowed to update the cart. */
  protected catchNotAllowedToUpdateCartError(): MonoTypeOperatorFunction<void> {
    return catchError((error: unknown) => {
      if (error instanceof NotAllowedToUpdateCartError) {
        this.notificationService.notify(error.message);
        this.navigateToProfile();

        return of(undefined);
      }

      return throwError(() => error);
    });
  }

  private navigateToProfile(): void {
    // Close all dialog because it's complex to get dialogRef from product-quantity, and when navigate, we need to close all dialog too.
    this.dialog.closeAll();
    this.router.navigate(['/user']);
  }

}
