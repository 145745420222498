<ng-container *ngIf="product$ | async; else skeletonTemplate">
  {{ price$ | async | currency }}
</ng-container>

<ng-template #skeletonTemplate>
  <span
    pboxcSkeleton
    [pboxcSkeletonMinWidth]="4"
    [pboxcSkeletonMaxWidth]="4"
  ></span>
</ng-template>
