import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { assertNonNull } from '@pbox/common/core/utils/assert-non-null';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

import { CommonSharedModule } from '../../common-shared.module';

/** Quantity control. */
@Component({
  selector: 'pboxc-quantity-control',
  templateUrl: './quantity-control.component.html',
  styleUrls: ['./quantity-control.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => QuantityControlComponent)],
  standalone: true,
  imports: [CommonModule, CommonSharedModule, MatIconModule, FormsModule],
})
export class QuantityControlComponent extends SimpleValueAccessor<number> {
  /** Min value. Default is 0. */
  @Input()
  public min?: number = 0;

  /** Max value. */
  @Input()
  public max?: number;

  /**
   * Handles custom numeric input.
   * @param strValue Value.
   */
  protected onValueChange(strValue: string): void {
    const value = Number(strValue);
    if (isNaN(value)) {
      return;
    }

    if (this.min != null && value < this.min) {
      this.controlValue = 0;
      return;
    }

    if (this.max != null && value > this.max) {
      this.controlValue = this.max;
      return;
    }

    this.controlValue = value;
  }

  /** Increments current value. */
  protected increment(): void {
    assertNonNull(this.controlValue);
    this.controlValue++;
  }

  /** Decrements current value. */
  protected decrement(): void {
    assertNonNull(this.controlValue);
    this.controlValue--;
  }

  /** Whether the increment button is disabled. */
  protected get isIncrementDisabled(): boolean {
    if (this.max != null && this.controlValue != null) {
      return this.controlValue >= this.max;
    }

    return false;
  }

  /** Whether the decrement button is disabled. */
  protected get isDecrementDisabled(): boolean {
    if (this.min != null && this.controlValue != null) {
      return this.controlValue <= this.min;
    }

    return false;
  }
}
