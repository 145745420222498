import { Injectable } from '@angular/core';

import { UserSecret } from '../../models/user-secret';

import { UserSecretDto } from './dto/user-secret.dto';
import { IMapper } from './mappers';

const ONE_HOUR_IN_MS = 3600000;
const ONE_DAY_IN_MS = 86400000;
const ONE_MONTH_IN_MS = ONE_DAY_IN_MS * 30;

/** User secret mapper. */
@Injectable({
  providedIn: 'root',
})
export class UserSecretDataMapper implements IMapper<UserSecretDto, UserSecret> {
  /** @inheritdoc */
  public toDto(data: UserSecret): UserSecretDto {
    return {
      token: data.token,
    };
  }

  /** @inheritdoc */
  public fromDto(dto: UserSecretDto): UserSecret {
    return {
      token: dto.token,
    };
  }

  /**
   * Maps from DTO to Domain model.
   * @param dto Token dto.
   * @param keepLoggedInForOneMonth Whether user is keep logged in for one month.
   */
  public fromDtoWithExpired(
    dto: UserSecretDto,
    keepLoggedInForOneMonth: boolean,
  ): UserSecret {
    const now = new Date();
    const expiredAfterOneMonth = new Date(now.getTime() + ONE_MONTH_IN_MS);
    const expiredAfterOneHour = new Date(now.getTime() + ONE_HOUR_IN_MS);

    return {
      token: dto.token,
      expiredAt: keepLoggedInForOneMonth ? expiredAfterOneMonth : expiredAfterOneHour,
    };
  }
}
