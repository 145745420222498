import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Card container. */
@Component({
  selector: 'pboxc-card-container',
  templateUrl: './card-container.component.html',
  styleUrls: ['./card-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardContainerComponent {
}
