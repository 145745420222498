import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/** UI service that helps managing the infinity scrolling of products. */
@Injectable({
  providedIn: 'root',
})
export class ProductsScrollService {

  /** Emits a signal value when an user reach a threshold distance of scrolling. */
  public readonly scrolled$: Observable<void>;

  private readonly scrolledSubject = new Subject<void>();

  public constructor() {
    this.scrolled$ = this.scrolledSubject.asObservable();
  }

  /** Handles `scrolled` event of the infinity scroller. */
  public onScrollDown(): void {
    this.scrolledSubject.next();
  }
}
