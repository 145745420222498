import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Sold out badge. */
@Component({
  selector: 'pboxc-sold-out-badge',
  templateUrl: './sold-out-badge.component.html',
  styleUrls: ['./sold-out-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoldOutBadgeComponent {
  /** Whether the icon should be present. */
  @Input()
  public withIcon = true;
}
