import { Injectable } from '@angular/core';

import { Membership } from '../../models/membership';

import { MembershipDto } from './dto/membership.dto';
import { IMapperFromDto } from './mappers';

/** Membership information mapper. */
@Injectable({
  providedIn: 'root',
})
export class MembershipMapper implements IMapperFromDto<MembershipDto, Membership> {

  /** @inheritdoc */
  public fromDto(data: MembershipDto): Membership {
    return {
      balance: data.balance,
      message: data.message,
    };
  }
}
