import { Address } from './address';
import { Coordinator } from './coordinator';
import { MenuInformation } from './menu';
import { Reference } from './reference';

/** User status. */
export enum UserStatus {
  Processing = 'process',
  Trial = 'trial',
  Active = 'active',
  Suspended = 'suspended',
  Canceled = 'canceled',
}

/** Delivery info. */
export interface UserDeliveryInfo {

  /** Date. */
  readonly date: Date;

  /** Message. */
  readonly message: string;

  /** Instructions. */
  readonly instructions: string;
}

/** Basic representation of a user. */
export interface User {

  /** ID. */
  readonly id: number;

  /** First name. */
  readonly firstName: string;

  /** Last name. */
  readonly lastName: string;

  /** Address. */
  readonly address: Address;

  /** Email. */
  readonly email: string;

  /** Mobile phone number. */
  readonly phone: string;

  /** Menu information. */
  readonly menu: MenuInformation;

  /** Whether the user is visiting this site for the first time or not. */
  readonly isFirstTime: boolean;

  /** Notification config. */
  readonly notifications: {

    /** Whether the menu email notification is enabled. */
    readonly menuEmail: boolean;

    /** Whether the account email notification is enabled. */
    readonly accountEmail: boolean;

    /** Whether the special email notification is enabled. */
    readonly specialEmail: boolean;

    /** Whether the cell phone notification is enabled. */
    readonly cellPhone: boolean;
  };

  /** Delivery info. */
  readonly delivery: UserDeliveryInfo;

  /** References. */
  readonly references: {

    /** Employer ID. */
    readonly employerId: number | null;
  };

  /** Balance in dollars. */
  readonly balance: number;

  /** Personalized referral URL. */
  readonly referralLink: string;

  /** Anniversary date. */
  readonly anniversaryDate: Date;

  /** Current user status. */
  readonly status: UserStatus;

  /** Whether the user has order this week. */
  readonly hasOrderWeek: boolean;

  /** Whether user has a subscription. */
  readonly isSubscribed: boolean;

  /** The person who delivers groceries . */
  readonly coordinator: Coordinator;

  /** Whether user has a cart. */
  readonly hasCart: boolean;

  /** Whether user is assigned to a location. */
  readonly hasAssignedLocation: boolean;

  /** Whether user has become a member or not. */
  readonly isMember: boolean;

  /** Store id. */
  readonly storeId: number;

}

export namespace User {
  export type ChangeAccountInformation = Pick<User, 'firstName'
  | 'lastName'
  | 'email'
  | 'phone'> & {
    readonly password: string;
    readonly confirmPassword: string;
  };

  export type Notifications = User['notifications'];

  export type ChangeProfileSettings = ChangeAccountInformation & Notifications;

  /** Change deliveries settings data. */
  export interface ChangeDeliveriesSettings {

    /** Instructions. */
    readonly instructions: string;

    /** Employer reference. */
    readonly employerReference: Reference.FromEmployer | null;
  }
}

export type UserCreationData = Pick<User, 'firstName'
| 'lastName'
| 'address'
| 'email'
| 'phone'>;
