import { Injectable } from '@angular/core';

import { AddressEditData } from '../../models/address';
import { EntityValidationErrors } from '../../models/app-error';

import { AddressEditDto } from './dto/address.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IValidationErrorMapper } from './mappers';

/** Edit address mapper. */
@Injectable({ providedIn: 'root' })
export class EditAddressMapper implements
  IValidationErrorMapper<AddressEditDto, AddressEditData> {

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<AddressEditDto>): EntityValidationErrors<AddressEditData> {
    return {
      address: {
        addressLine: extractErrorMessage(errorDto.address),
        city: extractErrorMessage(errorDto.city_name),
        suite: extractErrorMessage(errorDto.apt_suite),
        zipCode: extractErrorMessage(errorDto.zip_code),
        state: extractErrorMessage(errorDto.state_id),
      },
      effectiveDate: extractErrorMessage(errorDto.effective_date),
    };
  }

}
