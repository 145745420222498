import { Injectable } from '@angular/core';

import { State } from '../../models/state';

import { StateDto } from './dto/state.dto';

import { IMapperFromDto } from './mappers';

/** State mapper. */
@Injectable({
  providedIn: 'root',
})
export class StateMapper implements IMapperFromDto<StateDto, State> {

  /** @inheritdoc */
  public fromDto(data: StateDto): State {
    return {
      id: data.state_id,
      name: data.name,
      abbreviation: data.code,
    };
  }

  /** @inheritdoc */
  public toDto(data: State): StateDto {
    return {
      code: data.abbreviation,
      name: data.name,
      state_id: data.id,
    };
  }

}
