import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { RecommendedNavigationService } from '@pbox/common/shared/ui-services/recommended-navigation.service';
import { Observable } from 'rxjs';

/** Using this key in `data` attribute of the route tells the app to not to redirect a user to a recommended/welcome page. */
export const NO_INITIAL_REDIRECT = 'noInitialRedirect';

/** Redirects a user to recommended page. */
@Injectable({
  providedIn: 'root',
})
export class RecommendedNavigationGuard implements CanActivate {
  private isNavigated = false;

  public constructor(
    private readonly recommendedNavigationService: RecommendedNavigationService,
  ) {}

  /** @inheritdoc */
  public canActivate(route: ActivatedRouteSnapshot): boolean | Observable<UrlTree> {
    if (this.shouldAvoidRedirect(route) || this.isNavigated) {
      return true;
    }

    this.isNavigated = true;
    return this.recommendedNavigationService.getRecommendedRoute();
  }

  private shouldAvoidRedirect(route: ActivatedRouteSnapshot): boolean {
    if (route.data[NO_INITIAL_REDIRECT]) {
      return true;
    }

    return route.children.some(child => this.shouldAvoidRedirect(child));
  }
}
