import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Customize order dialog. */
@Component({
  selector: 'pboxc-customize-order-dialog',
  templateUrl: './customize-order-dialog.component.html',
  styleUrls: ['./customize-order-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeOrderDialogComponent { }
