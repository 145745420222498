import { Directive, ElementRef, Input } from '@angular/core';

import { messages as APP_MESSAGES } from './messages';

/** Applied to an element where the constant message should be put. */
@Directive({ selector: '[pboxcMessage]' })
export class MessageDirective {

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {}

  /** Key of a constant message to put in an element. */
  @Input('pboxcMessage')
  public set message(key: keyof typeof APP_MESSAGES) {
    this.elementRef.nativeElement.textContent = APP_MESSAGES[key];
  }
}
