import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@pbox/common/core/models/store';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { Observable } from 'rxjs';

/** Footer. */
@Component({
  selector: 'pboxw-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {

  /** Current store. */
  protected readonly store$: Observable<Store.Data>;

  public constructor(
    storesService: StoresService,
  ) {
    this.store$ = storesService.currentStore$;
  }
}
