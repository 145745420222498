import { Injectable } from '@angular/core';

import { AppParameters } from '../../models/app-parameters';

import { AppParametersDto } from './dto/app-parameters.dto';

import { IMapperFromDto } from './mappers';

/** App parameters mapper. */
@Injectable({ providedIn: 'root' })
export class AppParametersMapper implements IMapperFromDto<AppParametersDto, AppParameters> {

  /** @inheritdoc */
  public fromDto(dto: AppParametersDto): AppParameters {
    return {
      menuBannerUrl: dto.menu_banner,
      profileBannerUrl: dto.profile_banner,
    };
  }

}
