import { Injectable } from '@angular/core';
import { UserService } from '@pbox/common/core/services/user.service';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { intervalToDuration } from 'date-fns';
import { map, Observable, switchMap, timer } from 'rxjs';

type PluralMap = Partial<Readonly<Record<Intl.LDMLPluralRule, string>>>;

/** UI service for all welcome dates. */
@Injectable({
  providedIn: 'root',
})
export class WelcomeDateService {

  /** Date of order delivery. */
  public readonly deliveryDate$: Observable<Date>;

  /** Day of order delivery. */
  public readonly deliveryDay$: Observable<string>;

  /** Timer to show the delivery time left to order. */
  public readonly deliveryDateTimer$: Observable<string>;

  /** Timer to show the menu next open time. */
  public readonly menuNextOpenDateTimer$: Observable<string>;

  /** Timer to show the current menu close time. */
  public readonly menuCloseDateTimer$: Observable<string>;

  public constructor(
    private readonly userService: UserService,
  ) {
    const user$ = this.userService.currentUser$.pipe(
      filterNull(),
    );

    this.deliveryDay$ = user$.pipe(
      map(({ delivery }) => delivery.message),
    );

    this.deliveryDate$ = user$.pipe(
      map(({ delivery }) => delivery.date),
    );

    this.deliveryDateTimer$ = timer(0, 1000).pipe(
      switchMap(() => this.deliveryDate$),
      map(deliveryDate => this.getDuration(deliveryDate)),
    );

    const menuNextOpenDate$ = user$.pipe(
      map(({ menu }) => menu.nextOpenDate),
    );

    this.menuNextOpenDateTimer$ = timer(0, 1000).pipe(
      switchMap(() => menuNextOpenDate$),
      filterNull(),
      map(menuNextOpenDate => this.getDuration(menuNextOpenDate)),
    );

    const menuCloseDate$ = user$.pipe(
      map(({ menu }) => menu.closeDate),
    );

    this.menuCloseDateTimer$ = timer(0, 1000).pipe(
      switchMap(() => menuCloseDate$),
      filterNull(),
      map(menuCloseDate => this.getDuration(menuCloseDate)),
    );

  }

  /**
   * Function that returns the correct noun according to the plural rules of the locale and `cardinalNumber`.
   * @param cardinalNumber Cardinal number.
   * @param pluralWordsMap Plural words depending on the amount.
   * @param locale Locale.
   */
  private makePlural(cardinalNumber: number, pluralWordsMap: PluralMap, locale = 'en-US'): string {
    const rule = new Intl.PluralRules(locale).select(cardinalNumber);
    return pluralWordsMap[rule] ?? '';
  }

  private getDuration(date: Date): string {
    const { days, hours, minutes } = intervalToDuration({
      start: new Date(),
      end: date,
    });

    const daysLeft = days ? `${days} ${this.makePlural(days, { one: 'Day', other: 'Days' })}` : null;
    const hoursLeft = hours ? `${hours} ${this.makePlural(hours, { one: 'Hour', other: 'Hours' })}` : null;
    const minutesLeft = minutes ? `${minutes} ${this.makePlural(minutes, { one: 'Minute', other: 'Minutes' })}` : null;
    return [daysLeft, hoursLeft, minutesLeft].join(' ');
  }
}
