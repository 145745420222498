import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

const NO_TIME_PLACEHOLDER = '-';

/** Standard time pipe. */
@Pipe({
  name: 'pboxcTime',
})
export class TimePipe implements PipeTransform {

  public constructor(
    private readonly datePipe: DatePipe,
  ) { }

  /** @inheritdoc */
  public transform(value: Date | null, type: 'shortDate' | 'day' | 'time' = 'time'): string {
    const SHORT_DATE_FORMAT = 'M/d/yy';
    const DAY_FORMAT = 'MM/dd/yyyy';
    const TIME_FORMAT = 'hh:mma';

    if (value == null) {
      return NO_TIME_PLACEHOLDER;
    }

    if (type === 'day') {
      return this.datePipe.transform(value, DAY_FORMAT)?.toLowerCase() ?? 'Unknown date';
    }

    if (type === 'shortDate') {
      return this.datePipe.transform(value, SHORT_DATE_FORMAT)?.toLowerCase() ?? 'Unknown date';
    }

    return this.datePipe.transform(value, `${TIME_FORMAT} ${DAY_FORMAT}`)?.toLowerCase() ?? 'Unknown date';
  }

}
