import { Injectable } from '@angular/core';
import { BehaviorSubject, ignoreElements, Observable, tap } from 'rxjs';

import { Theme } from '../models/theme';
import { assertNonNull } from '../utils/assert-non-null';
import { filterNull } from '../utils/rxjs/filter-null';

import { Tenant, TenantsService } from './tenants.service';

const availableThemes: Readonly<Record<Tenant, Theme>> = {
  [Tenant.Default]: {
    name: 'tpb',
    logo: {
      baseImage: 'logo-tpb.png',
      contrastImage: 'logo-tpb-contrast.png',
    },
  },
  [Tenant.TheProduceBox]: {
    name: 'tpb',
    logo: {
      baseImage: 'logo-tpb.png',
      contrastImage: 'logo-tpb-contrast.png',
    },
  },
  [Tenant.CarolinaFlavors]: {
    name: 'cf',
    logo: {
      baseImage: 'logo-cf.png',
      contrastImage: 'logo-cf-contrast.png',
    },
  },
  [Tenant.Suppermeals]: {
    name: 'sm',
    logo: {
      baseImage: 'logo-sm.png',
      contrastImage: 'logo-sm-contrast.png',
    },
  },
  [Tenant.NCStateFarmersMarket]: {
    name: 'ncsfm',
  },
  [Tenant.TPBWholesale]: {
    name: 'tpbw',
  },
};

const DEFAULT_IMAGE_NAME = availableThemes[Tenant.Default].logo?.baseImage ?? 'logo-tpb.png';
const LOGO_IMAGE_BASE_PATH = 'assets/logos/';

/** Theme service. */
@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  /** Active color theme of the app. */
  public readonly activeTheme$: Observable<Theme>;

  private readonly activeThemeSubject = new BehaviorSubject<Theme | null>(null);

  public constructor(
    private readonly tenantsService: TenantsService,
  ) {
    this.activeTheme$ = this.activeThemeSubject.asObservable().pipe(filterNull());
  }

  /** Sets theme for the whole app. */
  public initThemeSideEffect(): Observable<never> {
    return this.tenantsService.currentTenant$.pipe(
      tap(tenant => this.setActiveTheme(availableThemes[tenant])),
      ignoreElements(),
    );
  }

  /** Provides src for the base logo image of the current theme or default logo if theme's logo is not set. */
  public getBaseLogoImageSrc(): string {
    const fileName = this.activeThemeSubject.getValue()?.logo?.baseImage ?? DEFAULT_IMAGE_NAME;
    return [LOGO_IMAGE_BASE_PATH, fileName].join('');
  }

  /** Provides src for the contrast logo image of the current theme or default logo if theme's logo is not set. */
  public getContrastLogoImage(): string {
    const fileName = this.activeThemeSubject.getValue()?.logo?.contrastImage ?? DEFAULT_IMAGE_NAME;
    return [LOGO_IMAGE_BASE_PATH, fileName].join('');
  }

  /** Returns path to the background image for the web auth pages. */
  public getWebAuthBackgroundImage(): string {
    const themeName = this.activeThemeSubject.getValue()?.name;
    assertNonNull(themeName);

    if (themeName === 'sm') {
      return '/assets/background-sm.png';
    }

    return '/assets/background-tpb.png';
  }

  /** Returns path to the background image for the mobile auth pages. */
  public getMobileAuthBackgroundImage(): string {
    const themeName = this.activeThemeSubject.getValue()?.name;
    assertNonNull(themeName);

    if (themeName === 'sm') {
      return '/assets/background-sm-mobile.png';
    }

    return '/assets/background-tpb-mobile.png';
  }

  /** Returns the apple icon name for the current theme. */
  public getAppleIcon(): string {
    const themeName = this.activeThemeSubject.getValue()?.name;
    assertNonNull(themeName);

    if (themeName === 'cf' || themeName === 'sm') {
      return 'apple';
    }

    return 'apple-outlined';
  }

  private setActiveTheme(theme: Theme): void {
    this.activeThemeSubject.next(theme);
  }
}
