import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderItem } from '@pbox/common/core/models/order-item';
import { Product } from '@pbox/common/core/models/product';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { ProductSubscriptionService } from '@pbox/common/shared/ui-services/product-subscription.service';
import { BehaviorSubject, distinctUntilKeyChanged, Observable, switchMap } from 'rxjs';

/** Cart item price. */
@Component({
  selector: 'pboxc-cart-item-price',
  templateUrl: './cart-item-price.component.html',
  styleUrls: ['./cart-item-price.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProductSubscriptionService],
})
export class CartItemPriceComponent {

  /** Cart item. */
  @Input()
  public set item(value: OrderItem) {
    this.product$.next(value.product);
  }

  /** Product. */
  protected readonly product$ = new BehaviorSubject<Product | null>(null);

  /** Price. */
  protected readonly price$: Observable<number>;

  public constructor(
    productSubscriptionService: ProductSubscriptionService,
  ) {
    this.price$ = this.product$.pipe(
      filterNull(),
      distinctUntilKeyChanged('id'),
      switchMap(product => productSubscriptionService.getBasePrice(product)),
      filterNull(),
    );
  }
}
