import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Breadrumb link. */
export interface BreadcrumbLink {

  /** The label to display for the link. */
  readonly label: string;

  /** The URL to navigate to when the link is clicked. */
  readonly url?: string | string[];
}

/** Breadcrumbs. */
@Component({
  selector: 'pboxc-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {

  /** Breadcrumb link. */
  @Input()
  public breadcrumbs?: readonly BreadcrumbLink[];

  /**
   * Function to track breadcrumb in array.
   * @param _ Idx.
   * @param breadcrumb Item to track.
   */
  protected trackBreadcrumb(_: number, breadcrumb: BreadcrumbLink): string {
    return breadcrumb.label + (breadcrumb.url?.toString() ?? '');
  }
}
