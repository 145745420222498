import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ThemeService } from '@pbox/common/core/services/theme.service';

/** Base layout for the web app. */
@Component({
  selector: 'pboxw-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutComponent {

  /** Value for the the `background-image` css property. */
  protected readonly backgroundImageUrl: string;

  public constructor(
    themeService: ThemeService,
  ) {
    this.backgroundImageUrl = `url(${themeService.getWebAuthBackgroundImage()})`;
  }
}
