<label class="label">
  <div>
    <span
      class="label__content"
      [class.label__content_required]="required"
      data-testid="label"
      *ngIf="labelText !== null"
    >
      {{ labelText }}
    </span>
    <ng-content select="additional-label"></ng-content>
  </div>
  <div class="label__input" [class.label__input_error]="errorsSubject | async">
    <ng-content></ng-content>
  </div>
  <pboxc-validation-message
    data-testid="error"
    class="error"
    [errors]="errorsSubject | async"
  >
  </pboxc-validation-message>
</label>
