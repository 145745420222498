import { Directive, ElementRef, HostBinding } from '@angular/core';

/** Directive that applies `.ellipsis` class to the element and sets a `title` with `textContent` whenever width is overflown. */
@Directive({ selector: '[pboxcEllipsis]' })
export class EllipsisDirective {

  /** Title, appearing whenever width is overflown. */
  @HostBinding('attr.title')
  public get title(): string | void {
    const { textContent } = this.elementRef.nativeElement;
    if (this.isWidthOverflown && textContent != null) {
      return textContent;
    }

    return undefined;
  }

  public constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
  ) {
    this.elementRef.nativeElement.classList.add('ellipsis');
  }

  private get isWidthOverflown(): boolean {
    const { nativeElement } = this.elementRef;
    return nativeElement.scrollWidth >= nativeElement.clientWidth;
  }
}
