<img
  *ngIf="bannerSubject | async as banner; else heroSkeletonTemplate"
  [src]="banner.url"
  [alt]="banner.alternativeText"
  class="banner"
  (error)="onImageLoadError()"
/>

<ng-template #heroSkeletonTemplate>
  <div class="banner banner_skeleton" pboxcSkeleton></div>
</ng-template>
