import { Injectable } from '@angular/core';

import { Pagination } from '../../models/pagination';

import { ResponseWithPaginationDto } from './dto/base-response.dto';
import { IMapperFromDto } from './mappers';

type MapperFunction<TDto, TDomain> = (dto: TDto) => TDomain;

/** Pagination mapper. */
@Injectable({ providedIn: 'root' })
export class PaginationMapper {
  /**
   * Map pagination from dto.
   * @param response Dto page.
   * @param mapper Mapper for the items.
   */
  public mapPaginationFromDto<TDto, TDomain>(
    response: ResponseWithPaginationDto<TDto>,
    mapper: IMapperFromDto<TDto, TDomain> | MapperFunction<TDto, TDomain>,
  ): Pagination<TDomain> {
    const paginationDto = response.meta.pagination;

    return {
      items: response.data.map(typeof mapper === 'function' ? mapper : mapper.fromDto.bind(mapper)),
      page: paginationDto.current_page,
      totalCount: paginationDto.total,
      size: paginationDto.per_page,
      hasNext: paginationDto.current_page < paginationDto.total_pages,
    };
  }
}
