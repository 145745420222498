import { Injectable } from '@angular/core';
import { map, Observable, repeat, shareReplay, Subject, tap } from 'rxjs';

import { AddCardBillingInformationData, CardBillingInformation, CardBillingInformationUpdateData } from '@pbox/common/core/models/billing-information';

import { PaymentMethodsApiService } from './payment-methods-api.service';

export type CardSecret = string;

/** Payment methods. */
@Injectable({ providedIn: 'root' })
export class CardPaymentMethodsService {

  /** Emitted when payments card are updated. */
  private readonly cardsUpdateSubject = new Subject<void>();

  /** Payment cards saved in the system by user. */
  public readonly savedCardPaymentMethods$: Observable<readonly CardBillingInformation[]>;

  /** Active card payment method. */
  public readonly activeCardPaymentMethod$: Observable<CardBillingInformation | null>;

  public constructor(
    private readonly paymentMethodsApiService: PaymentMethodsApiService,
  ) {
    this.savedCardPaymentMethods$ = this.paymentMethodsApiService.getPaymentCards().pipe(
      repeat({ delay: () => this.cardsUpdateSubject }),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
    this.activeCardPaymentMethod$ = this.savedCardPaymentMethods$.pipe(
      map(cards => cards.find(card => card.isActive)),
      map(card => card ?? null),
    );
  }

  /**
   * Adds card as a payment method.
   * @param _cardData Card data.
   */
  public addCard(_cardData: AddCardBillingInformationData): Observable<CardBillingInformation> {
    return this.paymentMethodsApiService.addPaymentCard(_cardData).pipe(
      tap(() => this.cardsUpdateSubject.next()),
    );
  }

  /**
   * Sets the card payment method as active.
   * @param cardData Card data.
   */
  public setActiveCard(cardData: CardBillingInformation): Observable<void> {
    return this.paymentMethodsApiService.setActivePaymentCard(cardData.id).pipe(
      tap(() => this.cardsUpdateSubject.next()),
    );
  }

  /**
   * Updates the payment card information.
   * @param cardData Card data.
   * @param updateData Data required to update payment card.
   */
  public updateCard(cardData: CardBillingInformation, updateData: CardBillingInformationUpdateData): Observable<void> {
    return this.paymentMethodsApiService.updatePaymentCard(cardData.id, updateData).pipe(
      tap(() => this.cardsUpdateSubject.next()),
    );
  }

  /**
   * Deletes the payment card.
   * @param cardData Card data.
   */
  public deleteCard(cardData: CardBillingInformation): Observable<void> {
    return this.paymentMethodsApiService.deletePaymentCard(cardData.id).pipe(
      tap(() => this.cardsUpdateSubject.next()),
    );
  }
}
