import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { CardBillingInformation } from '@pbox/common/core/models/billing-information';
import { FlatControlsOf } from '@pbox/common/core/utils/types/controls-of';

export type PaymentMethodInfoForm = FlatControlsOf<Pick<CardBillingInformation, 'cardNickname' | 'zipCode'>>;

export namespace PaymentMethodInfoForm {

  /**
   * Initializes form.
   * @param fb Form builder.
   */
  export function initialize(fb: NonNullableFormBuilder): FormGroup<PaymentMethodInfoForm> {
    return fb.group({
      cardNickname: fb.control('', [Validators.required]),
      zipCode: fb.control('', [Validators.required]),
    });
  }
}

/** Payment method info form. */
@Component({
  selector: 'pboxc-payment-method-info-form',
  templateUrl: './payment-method-info-form.component.html',
  styleUrls: ['./payment-method-info-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodInfoFormComponent {

  /** Form. */
  @Input()
  public formGroup?: FormGroup<PaymentMethodInfoForm>;
}
