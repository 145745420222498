<div class="payment-card" *ngIf="cardPaymentMethod; else skeletonTemplate">
  <pboxc-payment-card-brand
    class="payment-card__brand"
    [cardBrand]="cardPaymentMethod.card.brand"
  ></pboxc-payment-card-brand>
  <span>
    <strong>{{ cardPaymentMethod.cardNickname }}</strong> ending in
    <strong>{{ cardPaymentMethod.card.lastFourDigits }}</strong>
  </span>
</div>

<ng-template #skeletonTemplate>
  <div class="payment-card">
    <div
      pboxcSkeleton
      class="payment-card__brand payment-card__brand_skeleton"
    ></div>
    <span>
      <span
        pboxcSkeleton
        [pboxcSkeletonMinWidth]="10"
        [pboxcSkeletonMaxWidth]="20"
      ></span>
      ending in
      <span
        pboxcSkeleton
        [pboxcSkeletonMinWidth]="4"
        [pboxcSkeletonMaxWidth]="4"
      ></span>
    </span>
  </div>
</ng-template>
