import { ContentChild, Directive, TemplateRef } from '@angular/core';

import { CarouselTemplateDirective } from './carousel-template.directive';

/** Carousel content directive. */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'pboxc-carousel-content',
})
export class CarouselContentDirective {

  /** Template. */
  @ContentChild(CarouselTemplateDirective, { read: TemplateRef })
  public template: TemplateRef<HTMLElement> | null = null;
}
