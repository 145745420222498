import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { MaskPipe, NgxMaskModule } from 'ngx-mask';
import { SwiperModule } from 'swiper/angular';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';

import { MatFormFieldModule } from '@angular/material/form-field';

import { RippleOptionsService } from '../core/services/ripple-options.service';

import { AddressComponent } from './components/address/address.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { BannerComponent } from './components/banner/banner.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CardPaymentMethodFormComponent } from './components/card-payment-method-form/card-payment-method-form.component';
import { CardPaymentMethodComponent } from './components/card-payment-method/card-payment-method.component';
import { CardContainerComponent } from './components/card/card-container/card-container.component';
import { CardImageComponent } from './components/card/card-image/card-image.component';
import { CarouselContentDirective } from './components/carousel/carousel-content.directive';
import { CarouselTemplateDirective } from './components/carousel/carousel-template.directive';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { DeliveryDayMessageComponent } from './components/delivery-day-message/delivery-day-message.component';
import { FormErrorWrapperComponent } from './components/form-error-wrapper/form-error-wrapper.component';
import { InsufficientAmountBadgeComponent } from './components/insufficient-amount-badge/insufficient-amount-badge.component';
import { LabelComponent } from './components/label/label.component';
import { MainLogoComponent } from './components/main-logo/main-logo.component';
import { MoneyComponent } from './components/money/money.component';
import { OrderDateComponent } from './components/order-date/order-date.component';
import { PasswordControlComponent } from './components/password/password.component';
import { PaymentCardBrandComponent } from './components/payment-card-brand/payment-card-brand.component';
import { PaymentCardFormComponent } from './components/payment-card-form/payment-card-form.component';
import { PaymentMethodInfoFormComponent } from './components/payment-method-info-form/payment-method-info-form.component';
import { ProductImageComponent } from './components/product-image/product-image.component';
import { RadioComponent } from './components/radio/radio.component';
import { SelectComponent } from './components/select/select.component';
import { SocialMediaComponent } from './components/socials/social-media.component';
import { SoldOutBadgeComponent } from './components/sold-out-badge/sold-out-badge.component';
import { StoreEmailComponent } from './components/store-email/store-email.component';
import { StorePhoneComponent } from './components/store-phone/store-phone.component';
import { StorePolicyComponent } from './components/store-policy/store-policy.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { EllipsisDirective } from './directives/ellipsis.directive';
import { LoadingDirective } from './directives/loading.directive';
import { MessageDirective } from './directives/message/message.directive';
import { NullifyControlDirective } from './directives/nullify-control.directive';
import { SkeletonDirective } from './directives/skeleton.directive';
import { NullablePipe } from './pipes/nullable-string.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TimePipe } from './pipes/time.pipe';
import { SubscribeToProductButtonComponent } from './components/subscribe-to-product-button/subscribe-to-product-button.component';
import { GooglePlacesAutocompleteComponent } from './components/google-places-autocomplete/google-places-autocomplete.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';

const PRIVATE_DECLARATIONS = [ValidationMessageComponent];

const EXPORTED_DECLARATIONS = [
  LoadingDirective,
  SkeletonDirective,
  EllipsisDirective,
  LabelComponent,
  CheckboxComponent,
  SelectComponent,
  AutocompleteComponent,
  PasswordControlComponent,
  AddressComponent,
  AlertDialogComponent,
  TimePipe,
  MainLogoComponent,
  CardContainerComponent,
  CardImageComponent,
  CarouselComponent,
  CarouselContentDirective,
  CarouselTemplateDirective,
  ContactInfoComponent,
  SocialMediaComponent,
  PhonePipe,
  StorePolicyComponent,
  ProductImageComponent,
  PaymentCardFormComponent,
  CardPaymentMethodFormComponent,
  BreadcrumbsComponent,
  MessageDirective,
  PaymentMethodInfoFormComponent,
  NullablePipe,
  FormErrorWrapperComponent,
  PaymentCardBrandComponent,
  DeliveryDayMessageComponent,
  MoneyComponent,
  OrderDateComponent,
  CardPaymentMethodComponent,
  BannerComponent,
  StorePhoneComponent,
  StoreEmailComponent,
  NullifyControlDirective,
  SoldOutBadgeComponent,
  InsufficientAmountBadgeComponent,
  RadioComponent,
  GooglePlacesAutocompleteComponent,
  DatepickerComponent,
];

const EXPORTED_MODULES = [
  MatIconModule,
  MatSelectModule,
  MatAutocompleteModule,
  NgxMaskModule,
  MatDialogModule,
  MatDividerModule,
  MatSnackBarModule,
  SwiperModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatFormFieldModule,
];

/** Common module. Contains entities shared across applications. */
@NgModule({
  declarations: [
    ...PRIVATE_DECLARATIONS,
    ...EXPORTED_DECLARATIONS,
    SubscribeToProductButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forChild(),
    RouterModule,
    ...EXPORTED_MODULES,
  ],
  exports: [
    ...EXPORTED_DECLARATIONS,
    ...EXPORTED_MODULES,
    SubscribeToProductButtonComponent,
  ],
  providers: [
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useExisting: RippleOptionsService },

    // To reuse in TimePipe
    DatePipe,

    // For phone pipe
    MaskPipe,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
})
export class CommonSharedModule { }
