import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  concat,
  EMPTY,
  first,
  ignoreElements,
  map,
  merge,
  Observable,
  of,
  OperatorFunction,
  pipe,
  repeat,
  shareReplay,
  Subject,
  switchMap,
  tap,
  throwError,
} from 'rxjs';

import { AddressEditData } from '../models/address';
import { AppError } from '../models/app-error';
import { Login } from '../models/login';
import { Membership } from '../models/membership';
import { PasswordReset } from '../models/password-reset';
import { Registration } from '../models/registration';
import { User, UserStatus } from '../models/user';
import { UserSecret } from '../models/user-secret';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { filterNull } from '../utils/rxjs/filter-null';

import { AuthApiService } from './auth-api.service';
import { ApiError } from './mappers/dto/validation-error.dto';
import { StorageService } from './storage.service';
import { StoresService } from './stores.service';
import { UserApiService } from './user-api.service';
import { UserProfileService } from './user-profile.service';
import { HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY, UserSecretStorageService } from './user-secret-storage.service';

/** Current user service. */
@Injectable({
  providedIn: 'root',
})
export class UserService {
  /** Current user. `null` when a user is not logged in. */
  public readonly currentUser$: Observable<User | null>;

  /** Whether the current user is authorized. */
  public readonly isAuthorized$: Observable<boolean>;

  /** Whether the current user is suspended. */
  public readonly isSuspended$: Observable<boolean>;

  /** Whether the current user is processing. */
  public readonly isProcessing$: Observable<boolean>;

  /** Whether the current user is canceled. */
  public readonly isCanceled$: Observable<boolean>;

  /** Whether user can cancel membership.  */
  public readonly canCancelMembership$: Observable<boolean>;

  /** Whether user wants to log out. */
  public readonly isLoggedOut$: Observable<void>;

  /** Logout subject. */
  private readonly logoutSubject = new Subject<void>();

  private readonly currentUserUpdateSubject = new Subject<void>();

  public constructor(
    private readonly authService: AuthApiService,
    private readonly userSecretStorage: UserSecretStorageService,
    private readonly userApiService: UserApiService,
    private readonly userProfileService: UserProfileService,
    private readonly storesService: StoresService,
    private readonly storageService: StorageService,
  ) {
    this.currentUser$ = this.initCurrentUserStream();
    this.isAuthorized$ = combineLatest([this.currentUser$, this.userSecretStorage.getUserSecret()]).pipe(
      map(([user, token]) => user != null && token != null),
    );

    this.isSuspended$ = this.currentUser$.pipe(
      filterNull(),
      map(({ status }) => status === UserStatus.Suspended),
    );

    this.isProcessing$ = this.currentUser$.pipe(
      filterNull(),
      map(({ status }) => status === UserStatus.Processing),
    );

    this.isCanceled$ = this.currentUser$.pipe(
      filterNull(),
      map(({ status }) => status === UserStatus.Canceled),
    );

    this.canCancelMembership$ = this.currentUser$.pipe(
      filterNull(),
      map(user => user.status === UserStatus.Active && user.balance >= 0),
    );

    this.isLoggedOut$ = this.logoutSubject.asObservable();
  }

  /**
   * Login a user with email and password.
   * @param loginData Login data.
   */
  public login(loginData: Login): Observable<void> {
    return this.storesService.currentStore$.pipe(
      switchMap(({ id }) => this.authService.login({ ...loginData, storeId: id })),
      this.saveSecretAndWaitForAuthorized(),
      map(() => undefined),
    );
  }

  /** Logout current user. */
  public logout(): Observable<void> {
    this.logoutSubject.next();
    return this.userSecretStorage.removeSecret();
  }

  /** Attempts to refresh user secret, in case it is not possible logs out current user.. */
  public refreshSecret(): Observable<void> {
    const refreshSecretIfPresent$ = this.userSecretStorage.currentSecret$.pipe(
      first(),
      switchMap(secret => {
        if (secret != null) {
          return this.authService.refreshSecret(secret).pipe(map(newSecret => [secret, newSecret]));
        }
        return throwError(() => new AppError('Unauthorized'));
      }),

      // Use spread operator to avoid deleting expiredAt property
      switchMap(([oldSecret, newSecret]) => this.userSecretStorage.saveSecret({
        ...oldSecret,
        ...newSecret,
      })),
    );
    return refreshSecretIfPresent$.pipe(
      catchError((error: unknown) =>
        concat(
          this.logout().pipe(ignoreElements()),
          throwError(() => error),
        )),
      map(() => undefined),
    );
  }

  /**
   * Requests to reset the password.
   * @param data Data for resetting the password.
   * @returns Message for the user.
   */
  public resetPassword(data: PasswordReset.Data): Observable<void> {
    return this.authService.resetPassword(data);
  }

  /**
   * Set new password and confirm resetting.
   * @param data Confirm password reset.
   * @returns Success message.
   */
  public confirmPasswordReset(data: PasswordReset.Confirmation): Observable<void> {
    return this.authService.confirmPasswordReset(data);
  }

  /**
   * Register user.
   * @param data Registration data.
   */
  public registerUser(data: Registration): Observable<void> {
    return this.authService.registerUser(data);
  }

  private saveSecretAndWaitForAuthorized(): OperatorFunction<UserSecret, void> {
    return pipe(
      switchMap(secret => {
        const saveUserSecretSideEffect$ = this.userSecretStorage.saveSecret(secret).pipe(ignoreElements());

        return merge(
          this.currentUser$.pipe(map(user => user != null)),
          saveUserSecretSideEffect$,
        );
      }),
      first(isAuthorized => isAuthorized),
      map(() => undefined),
    );
  }

  private initCurrentUserStream(): Observable<User | null> {
    return this.userSecretStorage.currentSecret$.pipe(
      switchMap(secret => {
        if (secret == null) {
          return of(null);
        }

        return this.storesService.currentStore$;

      }),
      switchMap(store => {
        if (store == null) {
          return of(null);
        }

        return this.userApiService.getCurrentUser(store.id).pipe(
          repeat({ delay: () => this.currentUserUpdateSubject }),
        );
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  }

  /** Redirects user to login page if token is expired. */
  public checkTokenExpirationSideEffect(): Observable<void> {
    return this.userSecretStorage.currentSecret$.pipe(
      filterNull(),
      map(secret => secret.expiredAt ? new Date().getTime() > new Date(secret.expiredAt).getTime() : false),
      switchMap(isExpired => isExpired ? this.logout() : EMPTY),
    );
  }

  /**
   * Updates user profile settings.
   * @param data Data to update user profile.
   */
  public changeProfileSettings(data: User.ChangeProfileSettings): Observable<void> {
    return this.userProfileService.changeProfileSettings(data).pipe(
      tap(() => this.currentUserUpdateSubject.next()),
    );
  }

  /**
   * Updates user deliveries settings.
   * @param data Data to update deliveries settings of the user.
   */
  public changeDeliveriesSettings(data: User.ChangeDeliveriesSettings): Observable<void> {
    return this.userProfileService.changeDeliveriesSettings(data).pipe(
      tap(() => this.currentUserUpdateSubject.next()),
    );
  }

  /**
   * Sends a request to change the address of the user.
   * @param data Changed address data.
   */
  public changeAddress(data: AddressEditData): Observable<void> {
    return this.userProfileService.changeAddress(data).pipe(
      tap(() => this.currentUserUpdateSubject.next()),
    );
  }

  /** Tries to change the current user's status from suspended to active. */
  public tryToActivateUser(): Observable<void> {
    return this.userApiService.moveFromSuspended().pipe(
      tap(() => this.currentUserUpdateSubject.next()),
      catchHttpErrorResponse(error => {
        const { message } = error.error as ApiError<unknown>;

        return throwError(() => new AppError(message));
      }),
    );
  }

  /** Whether dialog for canceled membership should be showed. */
  public checkShowCanceledUserDialog(): Observable<boolean> {
    const user$ = this.currentUser$.pipe(
      filterNull(),
    );

    const hasReadNotification$ = this.storageService.get<boolean>(HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY).pipe(
      map(Boolean),
    );

    return combineLatest([
      user$,
      hasReadNotification$,
    ]).pipe(
      map(([user, hasReadNotification]) => user.status === UserStatus.Canceled && hasReadNotification === false),
      first(),
    );
  }

  /** Cancel user's membership. */
  public cancelMembership(): Observable<void> {
    return this.userApiService.cancelMembership().pipe(
      tap(() => this.currentUserUpdateSubject.next()),
    );
  }

  /** Refresh user. */
  public refreshUser(): void {
    this.currentUserUpdateSubject.next();
  }

  /** Get membership information. */
  public getMembershipInformation(): Observable<Membership> {
    return this.userApiService.getMembershipInformation();
  }
}
