import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/** Presents "Only N Left" message. N is provided as a prop. */
@Component({
  selector: 'pboxc-insufficient-amount-badge',
  templateUrl: './insufficient-amount-badge.component.html',
  styleUrls: ['./insufficient-amount-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsufficientAmountBadgeComponent {

  /** Amount left. */
  @Input()
  public amountLeft: number | null = null;

}
