import { Injectable } from '@angular/core';

import { EntityValidationErrors } from '../../models/app-error';
import { Reference, ReferenceFromAnotherUserValue } from '../../models/reference';
import { Registration } from '../../models/registration';

import { AddressMapper } from './address.mapper';
import { RegistrationDto } from './dto/registration.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { IMapperToDto, IValidationErrorMapper } from './mappers';

/** Registration data mapper. */
@Injectable({
  providedIn: 'root',
})
export class RegistrationMapper implements
  IMapperToDto<RegistrationDto, Registration>, IValidationErrorMapper<RegistrationDto, Registration> {
  public constructor(
    private readonly addressMapper: AddressMapper,
  ) { }

  /** @inheritdoc */
  public validationErrorFromDto(
    errorDto: ValidationErrorDto<RegistrationDto>,
  ): EntityValidationErrors<Omit<Registration, 'reference' | 'employerReference' | 'userReference'>> {
    return {
      coupon: extractErrorMessage(errorDto.coupon_code),
      address: {
        zipCode: extractErrorMessage(errorDto.zip_code),
        suite: extractErrorMessage(errorDto.apt_suite),
      },
      email: extractErrorMessage(errorDto.email),
      lastName: extractErrorMessage(errorDto.last_name),
      firstName: extractErrorMessage(errorDto.first_name),
      password: extractErrorMessage(errorDto.password),
      phone: extractErrorMessage(errorDto.phone),
    };
  }

  /** @inheritdoc */
  public toDto(data: Registration): RegistrationDto {
    const userReference = this.prepareUserReference(data.userReference);
    return {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
      phone: data.phone,
      password: data.password,
      coupon_code: data.coupon,
      referrer_employer_id: data.employerReference?.id ?? null,
      referrer_employer_name: data.employerReference?.name ?? null,
      referrer_user_id: userReference?.id ?? null,
      referrer_user_name: userReference?.name ?? null,
      user_refer_id: data.reference?.id ?? null,
      store_id: data.storeId,
      state_id: data.address.state.id,
      ...this.addressMapper.toDto(data.address),
    };
  }

  /**
   * Convert reference from another user to a Reference.FromAnotherUser object.
   * @param reference Reference from another user.
   */
  private prepareUserReference(reference: ReferenceFromAnotherUserValue | null): Reference.FromAnotherUser | null {

    // Convert reference from another user to a Reference.FromAnotherUser object.
    if (reference == null) {
      return null;
    }
    return typeof (reference) === 'string' ? { id: null, name: reference } : reference;
  }
}
