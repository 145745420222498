<div
  class="card"
  *ngIf="product && product.imageUrl !== null; else imageSkeletonTemplate"
>
  <pboxc-product-image
    class="card__image"
    [src]="product.imageUrl"
  ></pboxc-product-image>
  <img
    *ngIf="product.isHotDeal"
    class="card__badge"
    src="/assets/hot-deal.png"
    alt="Hot deal image"
  />
</div>
<ng-template #imageSkeletonTemplate>
  <pboxc-product-image class="card__image" pboxcSkeleton></pboxc-product-image>
</ng-template>
