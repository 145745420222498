import { ChangeDetectionStrategy, Component } from '@angular/core';

import { OrderDetailsPageService } from '../order-details-page.service';

/** Order details page. */
@Component({
  selector: 'pboxc-created-order-controls',
  templateUrl: './created-order-controls.component.html',
  styleUrls: ['./created-order-controls.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreatedOrderControlsComponent {
  public constructor(
    protected readonly page: OrderDetailsPageService,
  ) {}
}
