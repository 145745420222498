<time *ngIf="order; else skeletonTemplate" [dateTime]="order.date">
  {{ order.date | date: "MM/dd/yyyy" }}
</time>

<ng-template #skeletonTemplate>
  <span
    [pboxcSkeleton]="order"
    [pboxcSkeletonMinWidth]="8"
    [pboxcSkeletonMaxWidth]="8"
  ></span>
</ng-template>
