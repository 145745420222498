import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CardBillingInformation } from '@pbox/common/core/models/billing-information';
import { PaymentMethodType } from '@pbox/common/core/models/payment-method-type';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { CardPaymentMethodsService } from '@pbox/common/core/services/card-payment-methods.service';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';
import { CardPaymentMethodFormComponent } from '@pbox/common/shared/components/card-payment-method-form/card-payment-method-form.component';
import { filter, ignoreElements, map, materialize, merge, Observable, shareReplay, startWith, tap } from 'rxjs';

import { CheckoutPageService } from '../checkout-page.service';

/** Allows to pick different payment methods. */
@UntilDestroy()
@Component({
  selector: 'pboxc-payment-methods-picker',
  templateUrl: './payment-methods-picker.component.html',
  styleUrls: ['./payment-methods-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => PaymentMethodsPickerComponent)],
})
export class PaymentMethodsPickerComponent extends SimpleValueAccessor<PaymentMethodType> implements OnInit {
  /** Available types of payment methods. */
  protected readonly paymentMethodTypes = PaymentMethodType;

  /** Whether the card payment method is being loaded. */
  protected readonly isCardPaymentMethodLoading$: Observable<boolean>;

  /** Available card payment methods. */
  protected readonly cardPaymentMethod$: Observable<CardBillingInformation | null>;

  /** Allows selecting different payment methods. */
  protected readonly paymentMethodTypeControl = new FormControl(PaymentMethodType.Card);

  public constructor(
    protected readonly appConfig: AppConfig,
    protected readonly checkoutPageService: CheckoutPageService,
    paymentMethodsService: CardPaymentMethodsService,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
    this.cardPaymentMethod$ = paymentMethodsService.activeCardPaymentMethod$.pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.isCardPaymentMethodLoading$ = this.cardPaymentMethod$.pipe(
      materialize(),
      filter(({ kind }) => kind === 'N'),
      map(() => false),
      startWith(true),
    );
  }

  /** @inheritdoc */
  public ngOnInit(): void {
    merge(
      this.paymentCardsChangeSideEffect(),
      this.disablePaymentCardsSideEffect(),
    ).pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

  /**
   * Handles new card addition.
   * @param form Card payment method form.
   */
  public onNewCardAdded(form: CardPaymentMethodFormComponent): void {
    form.createCardPaymentMethod().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

  /**
   * Function to track card in array.
   * @param _ Idx.
   * @param card Item to track.
   */
  protected trackCard(_: number, card: CardBillingInformation): number {
    return card.id;
  }

  private paymentCardsChangeSideEffect(): Observable<void> {
    return this.paymentMethodTypeControl.valueChanges.pipe(
      tap(method => {
        this.controlValue = method;
      }),
      ignoreElements(),
    );
  }

  private disablePaymentCardsSideEffect(): Observable<void> {
    return this.checkoutPageService.isPaymentWithCard$.pipe(
      tap(isPaymentWithCard => {
        if (isPaymentWithCard) {
          this.paymentMethodTypeControl.enable({ emitEvent: false });
        } else {
          this.paymentMethodTypeControl.disable({ emitEvent: false });
        }
      }),
      ignoreElements(),
    );
  }
}
