import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Product } from '@pbox/common/core/models/product';
import { StorageService } from '@pbox/common/core/services/storage.service';
import { HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY } from '@pbox/common/core/services/user-secret-storage.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { EMPTY, switchMap, tap } from 'rxjs';

import { UserAccessService } from '../../ui-services/user-access.service';
import { CanceledMembershipSubscribeDialogComponent } from '../canceled-membership-subscribe-dialog/canceled-membership-subscribe-dialog.component';

export type SubscribeToProductButtonType = 'normal' | 'highlighted';

/** Button for subscribing to a product. */
@UntilDestroy()
@Component({
  selector: 'pboxc-subscribe-to-product-button',
  templateUrl: './subscribe-to-product-button.component.html',
  styleUrls: ['./subscribe-to-product-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeToProductButtonComponent {

  /** Product. */
  @Input()
  public product: Product | null = null;

  /** Whether the button is being loaded. */
  @Input()
  public isLoading: boolean | null = false;

  /** Whether the user is already subscribed to a product. */
  @Input()
  public isSubscribed: boolean | null = false;

  /** Icon inside the button. */
  @Input()
  public type: SubscribeToProductButtonType = 'normal';

  /** Product to subscribe to. */
  @Input()
  public subscriptionPrice: number | null = null;

  /** Emitted when a user clicked to subscribe button. */
  @Output()
  public subscribe = new EventEmitter<void>();

  public constructor(
    private readonly dialog: MatDialog,
    private readonly userService: UserService,
    private readonly storageService: StorageService,
    private readonly userAccessService: UserAccessService,
  ) { }

  /**
   * Title for product that has not been subscribed.
   * @param product Product.
   */
  public getSubscribeTitle(product: Product | null): string {
    const boxTitle = 'Subscribe';
    const addonBundleTitle = 'Add to Every Order';
    return product?.type === 'box' ? boxTitle : addonBundleTitle;
  }

  /**
   * Title for product that has been subscribed.
   * @param product Product.
   */
  public getSubscribedTitle(product: Product | null): string {
    const boxTitle = 'Subscribed!';
    const addonBundleTitle = 'Added to Every Order!';
    return product?.type === 'box' ? boxTitle : addonBundleTitle;
  }

  /** Handle subscribe button. */
  public onSubscribeButtonClick(): void {
    this.userAccessService.ifAuthorized().pipe(
      switchMap(() => this.userService.checkShowCanceledUserDialog()),
      switchMap(shouldShow => {
        if (shouldShow) {
          this.showSubscribeDialogForCanceledMembership();
          return this.storageService.save<boolean>(HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY, true);
        }
        this.subscribe.emit();
        return EMPTY;
      }),
      untilDestroyed(this),
    )
      .subscribe();
  }

  private showSubscribeDialogForCanceledMembership(): void {
    this.dialog.open(CanceledMembershipSubscribeDialogComponent).afterClosed()
      .pipe(
        tap(isSubscribeToProduct => isSubscribeToProduct === true ? this.subscribe.emit() : EMPTY),
        untilDestroyed(this),
      )
      .subscribe();
  }

}
