import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PopularProduct } from '@pbox/common/shared/features/welcome/first-time/first-time.base';
import { map, Observable, of } from 'rxjs';

import { Category } from '../models/category';
import { Pagination } from '../models/pagination';
import { Product, ProductWithDetails } from '../models/product';
import { SearchOptions } from '../models/search-options';
import { Store } from '../models/store';
import { Tag } from '../models/tag';

import { AppUrlsConfig } from './app-urls.config';
import { BaseResponseDto, ResponseWithPaginationDto } from './mappers/dto/base-response.dto';
import { PopularProductDto, ProductDto, ProductWithDetailsDto } from './mappers/dto/product.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { ProductMapper } from './mappers/product.mapper';

/** Search options for products. */
export interface ProductSearchOptions extends SearchOptions.Combined {

  /** Categories. */
  readonly categories?: Category['id'][];

  /** Tags. */
  readonly tags?: Tag['id'][];

  /** Store. */
  readonly store?: Store.Data['id'];
}

/** Products service. */
@Injectable({
  providedIn: 'root',
})
export class ProductsService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly paginationMapper: PaginationMapper,
    private readonly productMapper: ProductMapper,
  ) { }

  /**
   * Obtains a page of products.
   * @param options Search options.
   */
  public getProducts(options: ProductSearchOptions): Observable<Pagination<Product>> {
    const params = new HttpParams({
      fromObject: this.productMapper.mapSearchOptionsToDto(options),
    });

    return this.httpClient.get<ResponseWithPaginationDto<ProductDto>>(this.apiUrls.products.list, { params })
      .pipe(
        map(response => this.paginationMapper.mapPaginationFromDto(response, this.productMapper)),
      );
  }

  /**
   * Returns a list of products based on the list of ids provided.
   * @param ids Ids.
   */
  public getProductsByIds(ids: readonly Product['id'][]): Observable<readonly Product[]> {
    if (ids.length === 0) {
      return of([]);
    }

    const params = new HttpParams({
      fromObject: {
        product_ids: ids.join(','),
        per_page: ids.length.toString(),
      },
    });

    return this.httpClient.get<ResponseWithPaginationDto<ProductDto>>(this.apiUrls.products.list, { params })
      .pipe(
        map(response => this.paginationMapper.mapPaginationFromDto(response, this.productMapper)),
        map(page => page.items),
      );
  }

  /**
   * Obtains a product by the given id.
   * @param id Id.
   */
  public getProductDetails(id: Product['id']): Observable<ProductWithDetails> {
    return this.httpClient.get<BaseResponseDto<ProductWithDetailsDto>>(this.apiUrls.products.get(id.toString()))
      .pipe(
        map(response => this.productMapper.productWithDetailsFromDto(response.data)),
      );
  }

  /**
   * Obtains a list of the most purchased products for the user.
   * @param limit The maximum amount of obtained products.
   * @param storeId Store id.
   */
  public getMostPurchasedProducts(limit: number, storeId: Store.Data['id']): Observable<Product[]> {
    const params = new HttpParams({
      fromObject: {
        limit,
        store_id: storeId,
      },
    });

    return this.httpClient.get<BaseResponseDto<ProductDto[]>>(this.apiUrls.products.mostPurchased, { params }).pipe(
      map(response => response.data.map(dto => this.productMapper.fromDto(dto))),
    );
  }

  /**
   * Obtains a list of new products for the current week.
   * @param limit The maximum amount of obtained products.
   * @param storeId Store id.
   */
  public getNewThisWeekProducts(limit: number, storeId: Store.Data['id']): Observable<Product[]> {
    const params = new HttpParams({
      fromObject: {
        limit,
        store_id: storeId,
      },
    });

    return this.httpClient.get<BaseResponseDto<ProductDto[]>>(this.apiUrls.products.newThisWeek, { params }).pipe(
      map(response => response.data.map(dto => this.productMapper.fromDto(dto))),
    );
  }

  /** Get popular products. */
  public getMostPopularProducts(): Observable<PopularProduct[]> {
    return this.httpClient.get<BaseResponseDto<PopularProductDto[]>>(this.apiUrls.products.popularProduct)
      .pipe(
        map(response => response.data.map(product => this.productMapper.popularProductFromDto(product))),
      );
  }
}
