<form
  (ngSubmit)="formSubmit.emit()"
  class="payment-method-form"
  [formGroup]="form"
>
  <pboxc-payment-method-info-form [formGroup]="form.controls.paymentInfo">
    <pboxc-payment-card-form #paymentForm> </pboxc-payment-card-form>
  </pboxc-payment-method-info-form>

  <footer class="payment-method-form__footer">
    <ng-content></ng-content>
  </footer>
</form>
