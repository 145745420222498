import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserService } from '@pbox/common/core/services/user.service';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { map, Observable } from 'rxjs';

import { WelcomeDateService } from '../../ui-services/welcome-date.service';

/** Displays short information about the day of delivery. */
@Component({
  selector: 'pboxc-delivery-day-message',
  templateUrl: './delivery-day-message.component.html',
  styleUrls: ['./delivery-day-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeliveryDayMessageComponent {

  /** Whether menu is opened. */
  protected readonly isOpen$: Observable<boolean>;

  public constructor(
    protected readonly welcomeDateService: WelcomeDateService,
    private readonly userService: UserService,
  ) {
    const user$ = this.userService.currentUser$.pipe(
      filterNull(),
    );
    this.isOpen$ = user$.pipe(
      map(({ menu }) => menu.isOpen),
    );
  }
}
