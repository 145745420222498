import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { FormErrorWrapperComponent } from '../form-error-wrapper/form-error-wrapper.component';

/**
 * Label component. Displays error and label for the input component.
 */
@UntilDestroy()
@Component({
  selector: 'pboxc-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent extends FormErrorWrapperComponent {
  /**
   * Text of control's label.
   */
  @Input()
  public labelText: string | null = null;

  /** Whether the required mark should be displayed or not. */
  @Input()
  public required = false;
}
