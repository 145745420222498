import { OrderItem } from '../models/order-item';

/** Cart item update error. */
export class CartItemUpdateError extends Error {

  /** Data with which update failed. */
  public readonly updateData: OrderItem;

  public constructor(
    message: string,
    updateData: OrderItem,
  ) {
    super(message);
    this.updateData = updateData;
  }
}
