<fieldset [formGroup]="form">
  <div class="payment-form">
    <pboxc-label
      labelText="Name On Card"
      [required]="true"
      class="payment-form__label payment-form__label_name"
    >
      <input
        type="text"
        autocomplete="cc-name"
        autocapitalize="words"
        formControlName="cardholderName"
        placeholder="Card Owner"
      />
    </pboxc-label>
    <pboxc-label
      class="payment-form__label payment-form__label_number"
      labelText="Card Number"
      [required]="true"
      [errorText]="(spreedlyFieldsErrorSubject | async)?.cardNumber || null"
    >
      <div class="pboxc-input" id="spreedly-number"></div>
    </pboxc-label>
    <pboxc-label
      [required]="true"
      class="payment-form__label payment-form__label_expiration"
      labelText="Exp. Date"
    >
      <input
        type="text"
        inputmode="numeric"
        autocomplete="cc-exp"
        mask="00/00"
        [dropSpecialCharacters]="false"
        placeholder="MM/YY"
        formControlName="expiresAt"
      />
    </pboxc-label>
    <pboxc-label
      class="payment-form__label payment-form__label_csc"
      labelText="Security Code"
      [required]="true"
      [errorText]="(spreedlyFieldsErrorSubject | async)?.csc || null"
    >
      <div class="pboxc-input" id="spreedly-cvv"></div>
    </pboxc-label>
    <footer class="payment-form__footer">
      <ng-content></ng-content>
    </footer>
  </div>
</fieldset>
