<div class="policy" *ngIf="store !== null; else skeleton">
  <span class="copyright">{{ store.name }} ™. All Rights Reserved 2022.</span>
  <a
    class="terms"
    target="_blank"
    [href]="storesService.termsOfService$ | async"
  >
    <span>Terms of Service</span>
    <div class="vertical-separator terms__separator"></div>
    <span>Privacy Policy</span>
  </a>
</div>

<ng-template #skeleton>
  <div class="policy">
    <span
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="30"
      [pboxcSkeletonMaxWidth]="50"
    ></span>
  </div>
</ng-template>
