<span
  class="price"
  *ngIf="amount !== null && amount !== undefined; else priceSkeletonTemplate"
>
  {{ amount | currency }}
</span>

<ng-template #priceSkeletonTemplate>
  <span
    pboxcSkeleton
    [pboxcSkeletonMinWidth]="4"
    [pboxcSkeletonMaxWidth]="4"
  ></span>
</ng-template>
