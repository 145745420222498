import { Injectable } from '@angular/core';

import { AddCoupon, Coupon } from '../../models/coupon';

import { AddCouponDto, CouponDto } from './dto/coupon.dto';
import { IMapperFromDto } from './mappers';

/**
 * Coupon mapper.
 */
@Injectable({ providedIn: 'root' })
export class CouponMapper implements IMapperFromDto<CouponDto, Coupon> {
  /** @inheritdoc */
  public fromDto(dto: CouponDto): Coupon {
    return {
      id: dto.coupon_id,
      code: dto.coupon_code,
      amount: dto.amount,
    };
  }

  /** @inheritdoc */
  public toDto(data: AddCoupon): AddCouponDto {
    return {
      coupon_code: data.code,
    };
  }
}
