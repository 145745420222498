import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AddressEditData } from '../models/address';
import { User } from '../models/user';

import { AppUrlsConfig } from './app-urls.config';
import { AddressMapper } from './mappers/address.mapper';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { ChangeDeliveriesSettingsMapper } from './mappers/change-deliveries-settings.mapper';
import { ChangeProfileSettingsMapper } from './mappers/change-profile-settings.mapper';
import { EditAddressMapper } from './mappers/edit-address.mapper';

/** User profile service. */
@Injectable({
  providedIn: 'root',
})
export class UserProfileService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly httpClient: HttpClient,
    private readonly addressMapper: AddressMapper,
    private readonly editAddressMapper: EditAddressMapper,
    private readonly changeProfileSettingsMapper: ChangeProfileSettingsMapper,
    private readonly changeDeliveriesSettingsMapper: ChangeDeliveriesSettingsMapper,
  ) { }

  /**
   * Updates user profile settings.
   * @param data Data required to update user profile.
   */
  public changeProfileSettings(data: User.ChangeProfileSettings): Observable<void> {
    return this.httpClient.patch<void>(
      this.apiUrls.user.profile, this.changeProfileSettingsMapper.toDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.changeProfileSettingsMapper),
    );
  }

  /**
   * Updates user deliveries settings.
   * @param data Data required to update deliveries settings.
   */
  public changeDeliveriesSettings(data: User.ChangeDeliveriesSettings): Observable<void> {
    return this.httpClient.patch<void>(
      this.apiUrls.user.profile,
      this.changeDeliveriesSettingsMapper.toDto(data),
    );
  }

  /**
   * Sends a request to change the address of the user.
   * @param data Changed address data.
   */
  public changeAddress(data: AddressEditData): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.user.changeAddress,
      this.addressMapper.mapEditDataToDto(data),
    ).pipe(
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.editAddressMapper),
    );
  }
}
