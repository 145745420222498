import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

interface IconData {

  /** Name. */
  readonly name: string;

  /** Filename.*/
  readonly path: string;
}

const ICONS: IconData[] = [
  {
    name: 'cart',
    path: 'cart.svg',
  },
  {
    name: 'customize',
    path: 'customize.svg',
  },
  {
    name: 'profile',
    path: 'profile.svg',
  },
  {
    name: 'subscribe',
    path: 'subscribe.svg',
  },
  {
    name: 'apple',
    path: 'apple.svg',
  },
  {
    name: 'add-to-cart',
    path: 'add-to-cart.svg',
  },
  {
    name: 'facebook',
    path: 'facebook.svg',
  },
  {
    name: 'instagram',
    path: 'instagram.svg',
  },
  {
    name: 'linkedin',
    path: 'linkedin.svg',
  },
  {
    name: 'dollar-badge',
    path: 'dollar-badge.svg',
  },
  {
    name: 'edit',
    path: 'edit.svg',
  },
  {
    name: 'dropdown',
    path: 'dropdown.svg',
  },
  {
    name: 'selected',
    path: 'selected.svg',
  },
  {
    name: 'not-selected',
    path: 'not-selected.svg',
  },
  {
    name: 'delete',
    path: 'delete.svg',
  },
  {
    name: 'truck',
    path: 'truck.svg',
  },
  {
    name: 'order',
    path: 'order.svg',
  },
  {
    name: 'credit-card',
    path: 'credit-card.svg',
  },
  {
    name: 'skip-calendar',
    path: 'skip-calendar.svg',
  },
  {
    name: 'sign-out',
    path: 'sign-out.svg',
  },
  {
    name: 'tip',
    path: 'tip.svg',
  },
  {
    name: 'out-of-stock',
    path: 'out-of-stock.svg',
  },
  {
    name: 'twitter',
    path: 'twitter.svg',
  },
  {
    name: 'apple-outlined',
    path: 'apple-outlined.svg',
  },
  {
    name: 'filters',
    path: 'filters.svg',
  },
  {
    name: 'clear',
    path: 'clear.svg',
  },
];

/** Service to register custom icons. */
@Injectable({
  providedIn: 'root',
})
export class IconsService {
  public constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) { }

  /** Adds custom icons to the registry. */
  public registerIcons(): void {
    ICONS.forEach(({ name, path }) => {
      this.registerIcon(name, `/assets/icons/${path}`);
    });
  }

  private registerIcon(name: string, path: string): void {
    this.matIconRegistry.addSvgIcon(name, this.domSanitizer.bypassSecurityTrustResourceUrl(path));
  }
}
