/** Delivery status. */
export enum DeliveryStatus {
  Skipped = 'skipped',
  Opened = 'opened',
}

const DELIVERY_STATUS_TO_READABLE_MAP: Readonly<Record<DeliveryStatus, string>> = {
  [DeliveryStatus.Skipped]: 'Skip',
  [DeliveryStatus.Opened]: 'Open',
};

export namespace DeliveryStatus {

  /**
   * Transforms delivery status into human-readable format.
   * @param deliveryStatus Delivery status.
   */
  export function toReadable(deliveryStatus: DeliveryStatus): string {
    return DELIVERY_STATUS_TO_READABLE_MAP[deliveryStatus];
  }
}

/** Short representation of delivery. */
export interface ShortDelivery {

  /**
   * Key identifying the delivery. Equals to a date of the `yyyy-MM-dd` format.
   * @example '2022-09-23'
   */
  readonly key: string;

  /**
   * Range of delivery days in the string format.
   * @example 'September 27 - 29', 'November 29 - December 1'
   */
  readonly deliveryRange: string;
}

/** Delivery information. */
export interface Delivery extends ShortDelivery {

  /** Menu opening date for the delivery. */
  readonly menuOpeningDate: Date;

  /** Status. */
  readonly status: DeliveryStatus;

  /** Whether the delivery status changeable or not. */
  readonly isStatusChangeable: boolean;
}

export type DeliveryUpdateData = Pick<Delivery, 'key' | 'status'>;
