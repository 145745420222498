<ul class="order-sub-totals">
  <li class="order-sub-totals__item">
    <span> Sub-Total </span>
    <pboxc-money [amount]="order?.price?.subTotal"></pboxc-money>
  </li>
  <li class="order-sub-totals__item" *ngIf="order?.coupon">
    <span>
      Coupon:
      <span class="order-sub-totals__coupon">{{ order?.coupon?.code }}</span>
    </span>
    <pboxc-money
      *ngIf="order && order.coupon"
      [amount]="-order.coupon.amount"
    ></pboxc-money>
  </li>
  <ng-container *ngIf="getPrice(order) as price">
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.afterDiscount)"
    >
      <span> After Discount </span>
      <pboxc-money [amount]="price.afterDiscount"></pboxc-money>
    </li>
    <li class="order-sub-totals__item" *ngIf="shouldShowPrice(price.tax)">
      <span> Tax </span>
      <pboxc-money [amount]="price.tax"></pboxc-money>
    </li>
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.membershipFee)"
    >
      <span> Initial Membership Fee </span>
      <pboxc-money [amount]="price.membershipFee"></pboxc-money>
    </li>
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.membershipFeeDiscount)"
    >
      <span> Membership Discount </span>
      <pboxc-money [amount]="-price.membershipFeeDiscount"></pboxc-money>
    </li>
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.snapTotal) && appConfig.hasSnap"
    >
      <span> Amount Charged to SNAP </span>
      <pboxc-money [amount]="price.snapTotal"></pboxc-money>
    </li>
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.ebtTotal) && appConfig.hasSnap"
    >
      <span> Amount Charged to EBT </span>
      <pboxc-money [amount]="price.ebtTotal"></pboxc-money>
    </li>
    <li
      class="order-sub-totals__item"
      *ngIf="shouldShowPrice(price.cardTotal) && appConfig.hasSnap"
    >
      <span> Amount Charged to Credit Card </span>
      <pboxc-money [amount]="price.cardTotal"></pboxc-money>
    </li>
    <li class="order-sub-totals__item" *ngIf="shouldShowPrice(price.delivery)">
      <span> Delivery fee </span>
      <pboxc-money [amount]="price.delivery"></pboxc-money>
    </li>
    <li class="order-sub-totals__item" *ngIf="shouldShowPrice(price.tip)">
      <span> Tip </span>
      <pboxc-money [amount]="price.tip"></pboxc-money>
    </li>
  </ng-container>
</ul>
