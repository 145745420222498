<ng-container *ngIf="swiperOptions$ | async as config">
  <swiper class="carousel" [config]="config">
    <ng-template
      *ngFor="let slide of contents; trackBy: trackSlide"
      swiperSlide
    >
      <!-- There is not way to use ng-content with the swiper lib, therefore we have to use such construction. -->
      <ng-template
        [ngTemplateOutlet]="slide.template"
        [ngTemplateOutletContext]="{
          $implicit: slide.template
        }"
      ></ng-template>
    </ng-template>
  </swiper>
  <ng-container
    *ngIf="withCustomPaginationClass && hasPaginationOptions(config.pagination)"
  >
    <div [class]="getPaginationClass(config.pagination)"></div>
  </ng-container>
</ng-container>
