import { FormControl } from '@angular/forms';
import { ignoreElements, Observable, shareReplay, tap } from 'rxjs';

/**
 * Disables/enables form control when observable execution emits.
 * @param control Control to disable.
 * @param disabled$ Stream of disabled state. When `true` control will be disabled, when `false` - enabled.
 */
export function controlDisabledState(control: FormControl, disabled$: Observable<boolean>): Observable<never> {
  return disabled$.pipe(
    tap(disabled => {
      if (disabled) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    }),
    shareReplay({ refCount: true, bufferSize: 1 }),
    ignoreElements(),
  );
}
