import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardBrand } from '@pbox/common/core/models/card-brand';

const cardBrandIconMap: Record<CardBrand, string> = {
  [CardBrand.Amex]: 'amex.svg',
  [CardBrand.Visa]: 'visa.svg',
  [CardBrand.Discover]: 'discover.svg',
  [CardBrand.Mastercard]: 'mastercard.svg',
  [CardBrand.Unknown]: 'unknown.svg',
};

const fallbackIcon = 'unknown.svg';

/**
 * Card brand component. Accepts card brand name and provides an logo of the operator company.
 * @example
 * ```html
 * <pboxc-payment-card-brand cardBrand="mastercard"></pboxc-payment-card-brand>
 * <!-- OR -->
 * <pboxc-payment-card-brand [cardBrand]="CardBrand.Mastercard"></pboxc-payment-card-brand>
 * ```
 */
@Component({
  selector: 'pboxc-payment-card-brand',
  templateUrl: './payment-card-brand.component.html',
  styleUrls: ['./payment-card-brand.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandComponent {

  /** Card brand. */
  @Input()
  public cardBrand?: CardBrand;

  /** Image src. */
  public get imageSrc(): string | undefined {
    if (this.cardBrand == null) {
      return undefined;
    }
    const iconName = cardBrandIconMap[this.cardBrand] || fallbackIcon;
    return `/assets/card-brands/${iconName}`;
  }
}
