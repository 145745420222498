import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { CommonSharedModule } from '@pbox/common/shared/common-shared.module';
import { CartItemsAmountBadgeComponent } from '@pbox/common/shared/components/cart-items-amount-badge/cart-items-amount-badge.component';

import { HeaderComponent } from './components/header/header.component';

/** Module that contains entities shared between features/layouts. */
@NgModule({
  declarations: [HeaderComponent],
  providers: [],
  imports: [CommonModule, RouterModule, CommonSharedModule, MatMenuModule, CartItemsAmountBadgeComponent],
  exports: [HeaderComponent],
})
export class WebSharedModule { }
