import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { Pagination } from '../models/pagination';
import { Store } from '../models/store';

import { AppUrlsConfig } from './app-urls.config';
import { ResponseWithPaginationDto } from './mappers/dto/base-response.dto';
import { StoreDto } from './mappers/dto/store.dto';
import { PaginationMapper } from './mappers/pagination.mapper';
import { StoreMapper } from './mappers/store.mapper';

/** Stores API service. */
@Injectable({
  providedIn: 'root',
})
export class StoresApiService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly paginationMapper: PaginationMapper,
    private readonly storeMapper: StoreMapper,
  ) { }

  /** Obtains a list of stores. */
  public getStores(): Observable<Pagination<Store.Data>> {
    return this.httpClient.get<ResponseWithPaginationDto<StoreDto>>(this.apiUrls.stores.list)
      .pipe(
        map(response => this.paginationMapper.mapPaginationFromDto(response, this.storeMapper)),
      );
  }
}
