<ng-container *ngIf="isPaymentCancelledSubject | async; else loadingTemplate">
  <b class="h1">Sorry!</b>
  <p class="description">
    For some reason, we were unable to complete the payment transaction. The
    payment process was either cancelled or declined. Please try again later or
    contact the support team.
  </p>
  <a class="redirect-button primary" routerLink="/order/cart">Back to Cart</a>
</ng-container>

<ng-template #loadingTemplate>
  <ng-container *ngIf="isLoading$ | async; else successTemplate">
    <pboxc-loader [isLoading]="true"></pboxc-loader>
    <p class="description">Please wait while we process your payment...</p>
  </ng-container>
</ng-template>

<ng-template #successTemplate>
  <b class="h1">Success!</b>
  <p class="description">
    Your payment was successful. Thank you for using EBT.

    <a
      *ngIf="lastOrderId$ | async as orderId"
      class="redirect-button primary"
      [routerLink]="['/order/details', orderId]"
    >
      Check my order
    </a>
  </p>
</ng-template>
