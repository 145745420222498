import { ChangeDetectionStrategy, Component } from '@angular/core';

/** Card for highlighting a section on the page. */
@Component({
  selector: 'pboxc-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
}
