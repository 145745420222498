import { enumToArray } from '../utils/enum-to-array';

export type PlatformName = 'facebook' | 'instagram' | 'linkedin';

/** Social platform. */
export interface SocialPlatform {

  /** Name. */
  readonly name: PlatformName;

  /** Link URL. */
  readonly link: string;
}

export namespace Store {

  /** Store slug. */
  export enum Slug {
    TheProduceBox = 'tpb',
    Suppermeals = 'suppermeals',
    CarolinaFlavors = 'carolina-flavors',
    NCStateFarmersMarket = 'nc-state-farmers-market',
    TPBWholesale = 'tpb-wholesale',
  }

  export namespace Slug {

    /**
     * Type-guard for store slug.
     * @param value String to type-guard.
     */
    export function is(value: string): value is Slug {
      return (enumToArray(Slug) as string[]).includes(value);
    }
  }

  /** Store info. */
  export interface Data {

    /** Id. */
    readonly id: number;

    /** Full name. */
    readonly name: string;

    /** Slug. */
    readonly slug: Store.Slug;

    /** URL. */
    readonly url: string;

    /** Address. */
    readonly address: string;

    /** Email. */
    readonly email: string;

    /** Phone. */
    readonly phone: string;

    /** Social media. */
    readonly socialMedia: readonly SocialPlatform[];

    /** Whether delivery is enabled for current store. */
    readonly isDeliveryEnabled: boolean;
  }

  export type ContactInfo = Pick<Data, 'address' | 'email' | 'phone'>;
  export type SocialMedia = Data['socialMedia'];
}
