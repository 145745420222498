<ul *ngIf="socialMedia !== null; else skeleton" class="list">
  <li
    class="list__item"
    *ngFor="let platform of socialMedia; trackBy: trackSocialPlatforms"
  >
    <a [href]="platform.link" target="_blank" [title]="platform.name">
      <mat-icon
        class="list__icon"
        [svgIcon]="iconMapper[platform.name]"
      ></mat-icon>
    </a>
  </li>
</ul>

<ng-template #skeleton>
  <ul class="list">
    <!-- Iterating over primitives -->
    <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
    <li class="list__item" *ngFor="let _ of [1, 2, 3]">
      <span
        class="list__icon"
        pboxcSkeleton
        [pboxcSkeletonMinWidth]="2"
        [pboxcSkeletonMaxWidth]="2"
      ></span>
    </li>
  </ul>
</ng-template>
