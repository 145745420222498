import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, shareReplay } from 'rxjs';

import { State } from '../models/state';

import { AppUrlsConfig } from './app-urls.config';
import { BaseResponseDto } from './mappers/dto/base-response.dto';
import { StateDto } from './mappers/dto/state.dto';
import { StateMapper } from './mappers/state.mapper';

/** State service. */
@Injectable({
  providedIn: 'root',
})
export class StateService {

  /** States. */
  public readonly states$: Observable<readonly State[]>;

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly stateMapper: StateMapper,
  ) {
    this.states$ = this.getStates().pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** Get states. */
  private getStates(): Observable<State[]> {
    return this.httpClient
      .get<BaseResponseDto<StateDto[]>>(this.apiUrls.state.list)
      .pipe(map(response => response.data.map(state => this.stateMapper.fromDto(state))));
  }

}
