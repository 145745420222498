import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Product } from '@pbox/common/core/models/product';
import { CartService } from '@pbox/common/core/services/cart.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject } from 'rxjs';

import { CustomizeOrderDialogComponent } from '../customize-order-dialog/customize-order-dialog.component';

/** Initial data for the customize product dialog. */
export interface CustomizeProductDialogInitialData {

  /** Product to customize. */
  readonly product: Product;

  /** Whether the customize order dialog should be opened after the product is customized. */
  readonly shouldOpenCustomizeOrderDialog?: boolean;
}

/** Dialog that requires the current user to confirm a product customization. */
@UntilDestroy()
@Component({
  selector: 'pboxc-customize-product-dialog',
  templateUrl: './customize-product-dialog.component.html',
  styleUrls: ['./customize-product-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizeProductDialogComponent {

  /** Product. */
  protected readonly product = this.initialData.product;

  /** Whether the customize button should be disabled or not. */
  protected readonly isCustomizing$ = new BehaviorSubject(false);

  public constructor(
    @Inject(DIALOG_DATA) private readonly initialData: CustomizeProductDialogInitialData,
    private readonly dialogRef: MatDialogRef<CustomizeProductDialogComponent, void>,
    private readonly dialog: MatDialog,
    private readonly cartService: CartService,
    private readonly notificationService: NotificationService,
  ) { }

  /** Handles click on the customize order button. */
  public onCustomizeOrderButtonClick(): void {
    this.cartService.addCustomizedItem(this.product).pipe(
      toggleExecutionState(this.isCustomizing$),
      this.notificationService.notifyOnAppError(),
      untilDestroyed(this),
    )
      .subscribe({
        next: () => {
          this.dialogRef.close();

          if (this.initialData.shouldOpenCustomizeOrderDialog === true) {
            this.dialog.open(CustomizeOrderDialogComponent, {
              panelClass: 'cart-dialog-container',
              position: { right: '0' },
              hasBackdrop: false,
            });
          }
        },
      });
  }
}
