<form class="tip" [formGroup]="checkoutPageService.checkoutForm">
  <fieldset class="tip__fieldset">
    <legend class="tip__heading">
      <mat-icon
        [class.tip__icon_active]="
          checkoutPageService.checkoutForm.controls.tipAmount.value > 0
        "
        svgIcon="tip"
        class="tip__icon"
      ></mat-icon>
      Add a Tip
    </legend>
    <p class="tip__message">
      Show your appreciation for your neighborhood ambassador by adding a tip to
      your order. 100% of this goes to your
      <abbr title="Neighborhood Ambassador">NA</abbr>
    </p>

    <label class="tip__label inline-label">
      <span class="inline-label__text"
        >Add a tip for Neighborhood Ambassador</span
      >

      <pboxc-form-error-wrapper class="inline-label__error-wrapper">
        <div class="inline-label__input">
          $
          <input
            type="text"
            formControlName="tipAmount"
            pboxcNullifyControl
            placeholder="Enter Amount"
            mask="separator.2"
            thousandSeparator=","
            class="inline-label__control"
          />
        </div>
      </pboxc-form-error-wrapper>
    </label>
  </fieldset>
  <button type="submit" hidden></button>
</form>
