import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

const ALERT_TITLE_CLASS_MAP: Readonly<Record<AlertType, string>> = {
  normal: 'title',
  highlighted: 'title title_highlighted',
};

type AlertType = 'highlighted' | 'normal';

/** Alert options. */
export interface AlertOptions {

  /** Dialog title. */
  readonly title: string;

  /** Alert message. */
  readonly message: string;

  /** Button title. */
  readonly buttonTitle: string;

  /** Type of dialog title. `highlighted` with color by default. */
  readonly titleType?: AlertType;
}

const DEFAULT_TITLE_TYPE: AlertType = 'highlighted';

/** Alert dialog component. */
@Component({
  selector: 'pboxc-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent {

  public constructor(
    @Inject(MAT_DIALOG_DATA) public options: AlertOptions,
  ) { }

  /**  */
  public get titleClass(): string {
    return ALERT_TITLE_CLASS_MAP[this.options.titleType ?? DEFAULT_TITLE_TYPE];
  }
}
