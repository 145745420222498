import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CardBrand } from '@pbox/common/core/models/card-brand';
import { enumToArray } from '@pbox/common/core/utils/enum-to-array';

/** Preview for available payment providers. */
@Component({
  selector: 'pboxc-payment-card-brands-preview',
  templateUrl: './payment-card-brands-preview.component.html',
  styleUrls: ['./payment-card-brands-preview.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardBrandsPreviewComponent {

  /** Available card brands. */
  protected readonly cardBrands = enumToArray(CardBrand);

  /**
   * Function to track cardBrand in array.
   * @param _ Idx.
   * @param cardBrand Item to track.
   */
  protected trackCardBrand(_: number, cardBrand: CardBrand): CardBrand {
    return cardBrand;
  }
}
