import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@pbox/common/core/models/store';

/** Contact info. */
@Component({
  selector: 'pboxc-contact-info',
  templateUrl: './contact-info.component.html',
  styleUrls: ['./contact-info.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactInfoComponent {

  /** Contact info. */
  @Input()
  public contactInfo: Store.ContactInfo | null = null;
}
