<div *ngIf="formGroup" [formGroup]="formGroup">
  <pboxc-label [required]="true" labelText="Card Nickname">
    <input
      type="text"
      formControlName="cardNickname"
      placeholder="e.g: My main VISA card"
    />
  </pboxc-label>

  <ng-content></ng-content>

  <fieldset>
    <legend hidden>Billing Address</legend>

    <pboxc-label [required]="true" labelText="Billing Zip Code">
      <input
        type="text"
        autocomplete="postal-code"
        formControlName="zipCode"
        placeholder="00000"
      />
    </pboxc-label>
  </fieldset>
</div>
