import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ProductsScrollService } from '@pbox/common/shared/ui-services/products-scroll.service';

/** Products layout for the web app. */
@UntilDestroy()
@Component({
  selector: 'pboxw-products-layout',
  templateUrl: './products-layout.component.html',
  styleUrls: ['./products-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsLayoutComponent {

  public constructor(
    private readonly productsScrollService: ProductsScrollService,
  ) { }

  /** Handles `scrolled` event of the infinity scroller. */
  protected onScrollDown(): void {
    this.productsScrollService.onScrollDown();
  }
}
