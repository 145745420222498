<div class="password">
  <input
    [(ngModel)]="controlValue"
    [disabled]="disabled"
    [attr.type]="type"
    placeholder="Enter your password"
  />
  <button (click)="onToggleClick()" type="button" class="password__toggle">
    <mat-icon class="password__icon">{{ icon }}</mat-icon>
  </button>
</div>
