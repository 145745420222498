<div class="layout">
  <pboxw-header class="layout__header"></pboxw-header>

  <div
    infinite-scroll
    [infiniteScrollThrottle]="300"
    (scrolled)="onScrollDown()"
    class="layout__content"
  >
    <router-outlet></router-outlet>
  </div>

  <pboxw-footer></pboxw-footer>
</div>
