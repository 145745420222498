import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AddCoupon, Coupon } from '@pbox/common/core/models/coupon';
import { catchValidationData } from '@pbox/common/core/utils/rxjs/catch-validation-error';
import { FlatFormGroup } from '@pbox/common/core/utils/types/controls-of';
import { map, Observable } from 'rxjs';

import { OrderPageService } from '../order-page.service';

export type CouponForm = FlatFormGroup<AddCoupon>;

/** Coupon component. */
@UntilDestroy()
@Component({
  selector: 'pboxc-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CouponComponent {
  /** Coupon control. */
  protected readonly couponForm: CouponForm;

  /** Applied coupon. */
  protected readonly coupon$: Observable<Coupon | null>;

  public constructor(
    protected readonly orderPageService: OrderPageService,
    fb: NonNullableFormBuilder,
  ) {
    this.couponForm = fb.group({
      code: fb.control(''),
    });
    this.coupon$ = this.orderPageService.cart$.pipe(
      map(({ coupon }) => coupon),
    );
  }

  /** Handles coupon submission. */
  public onCouponSubmit(): void {
    this.couponForm.markAllAsTouched();
    if (this.couponForm.invalid) {
      return;
    }

    this.orderPageService.applyCoupon({
      code: this.couponForm.controls.code.getRawValue(),
    }).pipe(
      catchValidationData(this.couponForm),
      untilDestroyed(this),
    )
      .subscribe({
        next: () => this.couponForm.reset(),
      });
  }

  /** Handles coupon removal. */
  public onCouponRemove(): void {
    this.orderPageService.removeCoupon().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }
}
