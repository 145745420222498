import { ChangeDetectionStrategy, Component } from '@angular/core';
import { StoresService } from '@pbox/common/core/services/stores.service';

/** Store email. */
@Component({
  selector: 'pboxc-store-email',
  templateUrl: './store-email.component.html',
  styleUrls: ['./store-email.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreEmailComponent {

  /** Current store. */
  protected readonly store$ = this.storesService.currentStore$;

  public constructor(
    private readonly storesService: StoresService,
  ) { }
}
