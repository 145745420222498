<img
  #image
  *ngIf="src !== null; else skeleton"
  [src]="src"
  (error)="
    image.onerror = null; image.src = 'assets/product-image-fallback.svg'
  "
  class="image"
  alt=""
  loading="lazy"
/>

<ng-template #skeleton>
  <div class="image" pboxcSkeleton></div>
</ng-template>
