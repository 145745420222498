<span *ngIf="welcomeDateService.deliveryDay$ | async as deliveryDay">
  <span class="delivery-day-label">Your delivery day is</span>
  <strong
    [pboxcSkeleton]="deliveryDay"
    [pboxcSkeletonMinWidth]="10"
    [pboxcSkeletonMaxWidth]="10"
  >
    {{ deliveryDay }}
  </strong>

  <span *ngIf="isOpen$ | async; else isClosedTemplate">
    ({{ welcomeDateService.menuCloseDateTimer$ | async }} until our menu closes)
  </span>

  <ng-template #isClosedTemplate>
    ({{ welcomeDateService.menuNextOpenDateTimer$ | async }} until our menu
    opens)
  </ng-template>
</span>
