import { ChangeDetectionStrategy, Component } from '@angular/core';
import { OrderItem } from '@pbox/common/core/models/order-item';

import { OrderPageService } from '@pbox/common/shared/features/order/order-page.service';

/** Cart items list. */
@Component({
  selector: 'pboxc-cart-items-list',
  templateUrl: './cart-items-list.component.html',
  styleUrls: ['./cart-items-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemsListComponent {

  public constructor(
    protected readonly orderPageService: OrderPageService,
  ) {}

  /**
   * Function to track cartItem in array.
   * @param _ Idx.
   * @param cartItem Item to track.
   */
  protected trackCartItem(_: number, cartItem: OrderItem): number {
    return cartItem.id;
  }
}
