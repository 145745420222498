/** Type of payment method. */
export enum PaymentMethodType {

  /** Payment with credit/debit card. */
  Card = 'card',

  /** Payment with EBT cash. */
  EBT = 'ebt',

  /** Payment was made via account balance. */
  Balance = 'balance',
}
