import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';

import { AppError } from '../models/app-error';
import { CreateOrderWithCardData, DetailedOrder, Order } from '../models/order';

import { Pagination } from '../models/pagination';
import { SearchOptions } from '../models/search-options';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';
import { onMessageOrFailed } from '../utils/rxjs/on-message-or-failed';

import { AppUrlsConfig } from './app-urls.config';
import { CartService } from './cart.service';
import { AppErrorMapper } from './mappers/app-error.mapper';
import { CreateOrderDataMapper } from './mappers/create-order-data.mapper';
import { BaseResponseDto, ResponseWithPaginationDto } from './mappers/dto/base-response.dto';
import { FullOrderDto, ShortOrderDto } from './mappers/dto/order.dto';
import { OrderMapper } from './mappers/order.mapper';
import { PaginationMapper } from './mappers/pagination.mapper';

/** Search options for orders. */
export interface OrdersSearchOptions extends SearchOptions.Pagination {

  /** Whether it's possible to request credit for the order or not.  */
  readonly canRequestCredit?: boolean;
}

/** Provides API to work with orders. */
@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  public constructor(
    private readonly httpClient: HttpClient,
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly orderMapper: OrderMapper,
    private readonly paginationMapper: PaginationMapper,
    private readonly createOrderDataMapper: CreateOrderDataMapper,
    private readonly appErrorMapper: AppErrorMapper,
    private readonly cartService: CartService,
  ) { }

  /** Returns last purchased order. */
  public getLastOrder(): Observable<Order | null> {
    return this.getOrders({ page: 1, pageSize: 1 }).pipe(
      map(pagination => pagination.items[0]),
    );
  }

  /**
   * Returns order by id.
   * @param id Order id.
   */
  public getDetailedOrder(id: Order['id']): Observable<DetailedOrder> {
    return this.httpClient.get<BaseResponseDto<FullOrderDto>>(
      this.appUrlsConfig.orders.order(id.toString()),
    ).pipe(
      map(order => this.orderMapper.mapFromFullDto(order.data)),
    );
  }

  /**
   * Cancels order.
   * @param id Order id.
   */
  public cancelOrder(id: Order['id']): Observable<void> {
    return this.httpClient.delete<void>(
      this.appUrlsConfig.orders.order(id.toString()),
      {},
    ).pipe(
      catchHttpErrorResponse(error => throwError(() => new AppError(error.error.message ?? error.message))),
    );
  }

  /**
   * Creates an order.
   * @param createOrderData Data required to create an order.
   */
  public orderViaCard(createOrderData: CreateOrderWithCardData): Observable<Order['id']> {
    return this.httpClient.post<BaseResponseDto<ShortOrderDto>>(
      this.appUrlsConfig.orders.allOrders,
      this.createOrderDataMapper.toDto(createOrderData),
    ).pipe(
      map(({ data }) => data.order_id),
      this.appErrorMapper.catchHttpErrorToAppErrorWithValidationSupport(this.createOrderDataMapper),
      onMessageOrFailed(() => this.cartService.invalidateCart()),
    );
  }

  /**
   * Returns paginated orders list.
   * @param options Search options.
   */
  public getOrders(options: OrdersSearchOptions): Observable<Pagination<Order>> {
    return this.httpClient.get<ResponseWithPaginationDto<ShortOrderDto>>(
      this.appUrlsConfig.orders.allOrders,
      {
        params: new HttpParams({
          fromObject: this.orderMapper.mapOrdersSearchOptionsToDto(options),
        }),
      },
    ).pipe(
      map(dto =>
        this.paginationMapper.mapPaginationFromDto(
          dto, order => this.orderMapper.mapFromShortDto(order),
        )),
    );
  }
}
