import { ChangeDetectionStrategy, Component } from '@angular/core';
import { trackById } from '@pbox/common/core/utils/trackby';

import { OrderPageService } from '@pbox/common/shared/features/order/order-page.service';

/** Cart items list. */
@Component({
  selector: 'pboxc-cart-paid-items-list',
  templateUrl: './cart-paid-items-list.component.html',
  styleUrls: ['./cart-paid-items-list.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartPaidItemsListComponent {

  /** Trackby function. */
  protected trackById = trackById;

  public constructor(
    protected readonly orderPageService: OrderPageService,
  ) {}

}
