import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cart } from '@pbox/common/core/models/cart';

/** Displays message showing how much money needed to be spent. */
@Component({
  selector: 'pboxc-free-delivery-message',
  templateUrl: './free-delivery-message.component.html',
  styleUrls: ['./free-delivery-message.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeDeliveryMessageComponent {

  /** Cart. */
  @Input()
  public cart: Cart | null = null;

}
