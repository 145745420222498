import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CardBillingInformation } from '@pbox/common/core/models/billing-information';
import { CardBrand } from '@pbox/common/core/models/card-brand';
import { CardPaymentMethod, PaymentMethod } from '@pbox/common/core/models/payment-method';
import { PaymentMethodType } from '@pbox/common/core/models/payment-method-type';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { BalanceService } from '@pbox/common/core/services/balance.service';
import { CardPaymentMethodsService } from '@pbox/common/core/services/card-payment-methods.service';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { filter, map, Observable, ReplaySubject, switchMap } from 'rxjs';

const NULL_CARD_PAYMENT_METHOD: CardBillingInformation = {
  card: {
    brand: CardBrand.Unknown,
    expirationMonth: '**',
    expirationYear: '**',
    lastFourDigits: '****',
  },
  cardNickname: 'Unknown card',
  fullName: '-',
  id: -1,
  isActive: false,
  zipCode: '*****',
};

/** Order payment method. */
@Component({
  selector: 'pboxc-order-payment-method',
  templateUrl: './order-payment-method.component.html',
  styleUrls: ['./order-payment-method.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPaymentMethodComponent {
  /** Order payment method. Skeleton is rendered when `null` is provided. */
  @Input()
  public set orderPaymentMethod(value: PaymentMethod | null) {
    this.orderPaymentMethodSubject.next(value);
  }

  /** Payment card. */
  public readonly card$: Observable<CardBillingInformation>;

  /** Available types of payment. */
  protected readonly paymentMethodType = PaymentMethodType;

  /** Order payment method. */
  protected readonly orderPaymentMethodSubject = new ReplaySubject<PaymentMethod | null>(1);

  /** Whether payment method is ebt. */
  protected isEbtPaymentMethod = PaymentMethod.isEbtPaymentMethod;

  public constructor(
    private readonly paymentMethodsService: CardPaymentMethodsService,
    protected readonly balanceService: BalanceService,
    protected readonly appConfig: AppConfig,
  ) {
    this.card$ = this.orderPaymentMethodSubject.pipe(
      filterNull(),
      filter(CardPaymentMethod.is),
      map(({ cardId }) => cardId),
      switchMap(cardId =>
        this.paymentMethodsService.savedCardPaymentMethods$.pipe(
          map(cards => cards.find(({ id }) => id === cardId) ?? NULL_CARD_PAYMENT_METHOD),
        )),
    );
  }

}
