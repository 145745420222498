import { Injectable } from '@angular/core';
import { combineLatest, first, map, Observable, of, repeat, shareReplay, Subject, switchMap, tap, throwError } from 'rxjs';

import { Product } from '../models/product';
import { ProductSubscription, ProductSubscriptionEditData } from '../models/product-subscription';
import { ScheduleType } from '../models/schedule-type';
import { filterNull } from '../utils/rxjs/filter-null';

import { CartService, PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE } from './cart.service';
import { CardPaymentMethodsService } from './card-payment-methods.service';
import { StoresService } from './stores.service';
import { SubscriptionsApiService } from './subscriptions-api.service';
import { UserService } from './user.service';

export const SUBSCRIBE_WITHOUT_ACTIVE_CREDIT_CARD_ERROR_MESSAGE = 'Please add a valid credit card number to continue.';

/** User is not allowed to subscribe error.  */
export class NotAllowedToSubscribeError extends Error {

  public constructor(
    message: string,
  ) {
    super(message);
  }
}

/** Subscriptions service. */
@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {

  /** List of subscriptions for the current user. */
  public readonly subscriptions$: Observable<readonly ProductSubscription[]>;

  private readonly hasSubscription$: Observable<boolean>;

  private readonly _subscriptionMap$: Observable<Record<Product['id'], ProductSubscription>>;

  private readonly invalidateSubscriptionsSubject = new Subject<void>();

  public constructor(
    private readonly userService: UserService,
    private readonly subscriptionsApiService: SubscriptionsApiService,
    private readonly paymentMethodsService: CardPaymentMethodsService,
    private readonly cartService: CartService,
    private readonly storesService: StoresService,
  ) {
    this.subscriptions$ = this.initSubscriptionsStream();
    this.hasSubscription$ = this.initHasSubscription();
    this._subscriptionMap$ = this.subscriptions$.pipe(
      map(subscriptions => subscriptions.reduce(
        (acc, val) => ({ ...acc, [val.product.id]: val }), {} as Record<Product['id'], ProductSubscription>,
      )),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

  }

  /**
   * Obtains the base price for the product.
   * @param product Product.
   */
  public getProductBasePrice(product: Product): Observable<number> {
    return this.hasSubscription$.pipe(
      map(hasSubscription => hasSubscription ? product.basePrice : product.guestPrice),
    );
  }

  /**
   * Obtains the subscription price for the product.
   * @param product Product.
   */
  public getProductSubscriptionPrice(product: Product): Observable<number | null> {
    return combineLatest([this.hasSubscription$, this.storesService.areSubscriptionsEnabled$]).pipe(
      map(([hasSubscription, areSubscriptionsEnabled]) => {
        if (!areSubscriptionsEnabled || areSubscriptionsEnabled && hasSubscription) {
          return null;
        }

        return product.basePrice;
      }),
    );
  }

  /**
   * Checks whether the user is subscribed to the product.
   * @param product Product.
   */
  public checkIfSubscribedToProduct(product: Product): Observable<boolean> {
    return this._subscriptionMap$.pipe(
      map(subscriptionMap => subscriptionMap[product.id] != null),
    );
  }

  /**
   * Subscribes to the product.
   * @param product Product.
   */
  public subscribeToProduct(product: Product): Observable<void> {
    return this.assertUserIsAllowedToSubscribe().pipe(
      switchMap(() => this.storesService.currentStore$),
      switchMap(store => this.subscriptionsApiService.addSubscription(product.id, store.id)),
      map(() => undefined),
      tap(() => this.invalidateSubscriptionsSubject.next()),

      // Product is added to the cart on subscribe.
      tap(() => this.cartService.invalidateCart()),
    );
  }

  /**
   * Updates the subscription details.
   * @param subscription Subscription.
   * @param data Data required to update the subscription.
   */
  public updateSubscription(subscription: ProductSubscription, data: ProductSubscriptionEditData): Observable<void> {
    return this.subscriptionsApiService.updateSubscription(subscription.id, data).pipe(
      tap(() => this.invalidateSubscriptionsSubject.next()),
    );
  }

  /**
   * Cancels subscription on the product.
   * @param subscription Subscription.
   */
  public unsubscribeFromProduct(subscription: ProductSubscription): Observable<void> {
    return this.subscriptionsApiService.removeSubscription(subscription.id).pipe(
      tap(() => this.invalidateSubscriptionsSubject.next()),

      // Product is removed from the cart when the user unsubscribes from it.
      tap(() => this.cartService.invalidateCart()),
    );
  }

  /** Get a list of schedule type. */
  public getScheduleTypes(): Observable<ScheduleType[]> {
    return this.subscriptionsApiService.getScheduleTypes();
  }

  private assertUserIsAllowedToSubscribe(): Observable<void> {
    return combineLatest([this.paymentMethodsService.activeCardPaymentMethod$, this.userService.isProcessing$]).pipe(
      switchMap(([activeCardPaymentMethod, isProcessing]) => {

        if (isProcessing) {
          return throwError(() => new NotAllowedToSubscribeError(PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE));
        }

        if (activeCardPaymentMethod == null) {
          return throwError(() => new NotAllowedToSubscribeError(SUBSCRIBE_WITHOUT_ACTIVE_CREDIT_CARD_ERROR_MESSAGE));
        }

        return of(undefined);
      }),
      first(),
    );
  }

  private initHasSubscription(): Observable<boolean> {
    return this.subscriptions$.pipe(
      map(subscriptions => subscriptions.length > 0),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private initSubscriptionsStream(): Observable<readonly ProductSubscription[]> {
    return this.userService.currentUser$.pipe(
      filterNull(),
      switchMap(() => this.subscriptionsApiService.getSubscriptions().pipe(
        repeat({ delay: () => this.invalidateSubscriptionsSubject }),
      )),
      map(page => page.items),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }
}
