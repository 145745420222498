import { CardBillingInformation } from './billing-information';
import { CardBrand } from './card-brand';
import { PaymentMethodType } from './payment-method-type';

/** Payment method. */
export type PaymentMethod = CardPaymentMethod | BalancePaymentMethod | EbtPaymentMethod;

interface BasePaymentMethod {

  /** Type of the payment method. */
  readonly type: PaymentMethodType;
}

/** Payment via card. */
export interface CardPaymentMethod extends BasePaymentMethod {

  /** @inheritdoc */
  readonly type: PaymentMethodType.Card;

  /** Card id. */
  readonly cardId: CardBillingInformation['id'];
}

export namespace CardPaymentMethod {

  /**
   * Guard for the card payment method.
   * @param value Payment method.
   */
  export function is(value: PaymentMethod): value is CardPaymentMethod {
    return value.type === PaymentMethodType.Card;
  }
}

/** Payment via account balance. */
export interface BalancePaymentMethod extends BasePaymentMethod {

  /** @inheritdoc */
  readonly type: PaymentMethodType.Balance;
}

/** Payment via account balance. */
export interface EbtPaymentMethod extends BasePaymentMethod {

  /** @inheritdoc */
  readonly type: PaymentMethodType.EBT;

  /** Number of the snap card. */
  readonly snapCardNumber: string;

  /** Amount of money paid via snap. */
  readonly snapAmount: number;

  /** Snap balance. */
  readonly snapBalance: number;

  /** Amount of money paid via ebt. */
  readonly ebtAmount: number;

  /** Ebt balance. */
  readonly ebtBalance: number;

  /** Number of the snap card. */
  readonly creditCardNumber: string;

  /** Credit card brand. */
  readonly creditCardBrand: CardBrand;

  /** Amount of money paid via credit card. */
  readonly creditCardAmount: number;
}

export namespace PaymentMethod {

  /**
   * Type-guard for Ebt payment method.
   * @param paymentMethod Payment method to check.
   */
  export function isEbtPaymentMethod(paymentMethod: PaymentMethod): paymentMethod is EbtPaymentMethod {
    return paymentMethod.type === PaymentMethodType.EBT;
  }
}
