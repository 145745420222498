import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

import { AppError } from '../models/app-error';

import { ValidationErrorCode } from '../models/validation-error-code';

export namespace AppValidators {

  /**
   * Checks whether the current control matches another.
   * @param controlName Control name to check matching with.
   * @param controlTitle Control title to display for a user.
   */
  export function matchControl(
    controlName: string,
    controlTitle = controlName,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        control.parent &&
        control.parent.get(controlName)?.value !== control.value
      ) {
        return {
          [ValidationErrorCode.Match]: {
            controlName,
            controlTitle,
          },
        };
      }
      return null;
    };
  }

  /**
   * Validates a control for empty trimmed string.
   * @param this Context.
   * @param control Control to validate.
   */
  export function requiredWithTrim(this: void, control: AbstractControl): ValidationErrors | null {
    if (control.value != null && typeof control.value !== 'string') {
      throw new AppError(`Expected string value, got: ${control.value} - ${typeof control.value}`);
    }

    return Validators.required(control) ??
        (control.value.trim() === '' ? buildAppError('Empty space is not allowed') : null);
  }

  /**
   * Create validation error from a message.
   * @param message Message to create an error from.
   */
  export function buildAppError(message: string): ValidationErrors {
    return {
      [ValidationErrorCode.AppError]: {
        message,
      },
    };
  }
}
