import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeService } from '@pbox/common/core/services/theme.service';

type LogoType = 'base' | 'contrast';

/** Main logo. */
@Component({
  selector: 'pboxc-main-logo',
  templateUrl: './main-logo.component.html',
  styleUrls: ['./main-logo.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainLogoComponent {

  /** Logo type. */
  @Input()
  public type: LogoType = 'base';

  /** Src of the logo image. */
  public get logoImageSrc(): string {
    return this.type === 'base' ? this.themeService.getBaseLogoImageSrc() : this.themeService.getContrastLogoImage();
  }

  public constructor(
    private readonly themeService: ThemeService,
  ) { }
}
