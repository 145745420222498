import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';

import { AppError } from '../models/app-error';

import { Membership } from '../models/membership';

import { Store } from '../models/store';

import { User } from '../models/user';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { BaseResponseDto } from './mappers/dto/base-response.dto';
import { MembershipDto } from './mappers/dto/membership.dto';
import { UserDto } from './mappers/dto/user.dto';
import { MembershipMapper } from './mappers/membership.mapper';
import { UserMapper } from './mappers/user.mapper';

/** Performs CRUD operations for users. */
@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly userMapper: UserMapper,
    private readonly membershipMapper: MembershipMapper,
  ) { }

  /**
   * Returns current user info.
   * @param storeId Store id.
   */
  public getCurrentUser(storeId: Store.Data['id']): Observable<User> {
    const params = new HttpParams({
      fromObject: {
        store_id: storeId,
      },
    });
    return this.httpClient
      .get<BaseResponseDto<UserDto>>(this.apiUrls.user.profile, { params })
      .pipe(map(user => this.userMapper.fromDto(user.data)));
  }

  /** Changes the current user's status from suspended to active. */
  public moveFromSuspended(): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.user.moveFromSuspended, {},
    );
  }

  /** Get membership information. */
  public getMembershipInformation(): Observable<Membership> {
    return this.httpClient.get<BaseResponseDto<MembershipDto>>(
      this.apiUrls.user.membershipInfo,
    ).pipe(
      map(dto => this.membershipMapper.fromDto(dto.data)),
    );
  }

  /** Cancel membership. */
  public cancelMembership(): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.user.cancelMembership, {},
    ).pipe(
      map(() => undefined),
      catchHttpErrorResponse(error => throwError(() => new AppError(error.error.message ?? error.message))),
    );
  }
}
