import { Injectable } from '@angular/core';

import { Address, AddressEditData } from '../../models/address';
import { EntityValidationErrors } from '../../models/app-error';
import { assertNonNull } from '../../utils/assert-non-null';

import { DateMapper } from './date.mapper';

import { AddressDto, AddressEditDto } from './dto/address.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';
import { LocationMapper } from './location.mapper';
import { IMapperFromDto, IValidationErrorMapper } from './mappers';
import { StateMapper } from './state.mapper';

/** Address mapper. */
@Injectable({ providedIn: 'root' })
export class AddressMapper implements
  IMapperFromDto<AddressDto, Address>,
  IValidationErrorMapper<AddressEditDto, Address> {

  public constructor(
    private readonly stateMapper: StateMapper,
    private readonly locationMapper: LocationMapper,
    private readonly dateMapper: DateMapper,
  ) {}

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<AddressEditDto>): EntityValidationErrors<Address> {
    return {
      addressLine: extractErrorMessage(errorDto.address),
      city: extractErrorMessage(errorDto.city_name),
      suite: extractErrorMessage(errorDto.apt_suite),
      zipCode: extractErrorMessage(errorDto.zip_code),
      state: extractErrorMessage(errorDto.state_id),
    };
  }

  /** @inheritdoc */
  public fromDto(data: AddressDto): Address {
    return {
      addressLine: data.address,
      city: data.city_name,
      suite: data.apt_suite,
      zipCode: data.zip_code,
      state: this.stateMapper.fromDto(data.state),
    };
  }

  /** @inheritdoc */
  public toDto(data: Address): AddressDto {
    assertNonNull(data.state);

    return {
      state: this.stateMapper.toDto(data.state),
      apt_suite: data.suite,
      city_name: data.city,
      address: data.addressLine,
      zip_code: data.zipCode,
    };
  }

  /**
   * Maps edit data to dto.
   * @param data Data.
   */
  public mapEditDataToDto(data: AddressEditData): AddressEditDto {
    return {
      state_id: data.address.state.id,
      apt_suite: data.address.suite,
      address: data.address.addressLine,
      zip_code: data.address.zipCode,
      city_name: data.address.city,
      location_id: data.location?.id,
      county_id: data.county?.id ?? null,
      type: this.locationMapper.mapTypeToDto(data.type),
      effective_date: this.dateMapper.toDto(data.effectiveDate),
    };
  }
}
