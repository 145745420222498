import { Injectable } from '@angular/core';

import { Menu } from '../../models/menu';

import { MenuDto } from './dto/menu.dto';

import { IMapperFromDto } from './mappers';

/** Menu mapper. */
@Injectable({
  providedIn: 'root',
})
export class MenuMapper implements IMapperFromDto<MenuDto, Menu> {

  /** @inheritdoc */
  public fromDto(data: MenuDto): Menu {
    return {
      id: data.data.menu_id,
      villageId: data.data.village_id,
      isPublished: data.data.is_published,
    };
  }
}
