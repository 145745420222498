import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@pbox/common/core/injection-tokens/window-token';
import { CreateOrderData, Order } from '@pbox/common/core/models/order';
import { PaymentMethodType } from '@pbox/common/core/models/payment-method-type';
import { CardPaymentMethodsService } from '@pbox/common/core/services/card-payment-methods.service';
import { EbtOrderPaymentService } from '@pbox/common/core/services/ebt-order-payment.service';
import { OrdersService } from '@pbox/common/core/services/orders.service';
import { first, ignoreElements, Observable, switchMap, tap } from 'rxjs';

/** Strategy for creating an order. */
export interface OrderPaymentStrategy {

  /**
   * Creates an order.
   * @param createOrderData Data required to create an order.
   */
  createOrder(createOrderData: CreateOrderData): Observable<Order['id']>;
}

/** Factory for creating order payment strategies. */
@Injectable({
  providedIn: 'root',
})
export class OrderStrategyFactoryService {
  public constructor(
    private readonly paymentMethodsService: CardPaymentMethodsService,
    private readonly ebtOrderPaymentService: EbtOrderPaymentService,
    private readonly orderService: OrdersService,
    @Inject(WINDOW) private readonly window: Window,
  ) {}

  /**
   * Creates a payment strategy based on provided payment method type.
   * @param paymentMethodType Type of payment method.
   */
  public createFor(paymentMethodType: PaymentMethodType | null): OrderPaymentStrategy {
    switch (paymentMethodType) {
      case PaymentMethodType.Card:
        return new CardOrderStrategy(this.paymentMethodsService, this.orderService);
      case PaymentMethodType.EBT:
        return new EbtOrderStrategy(this.ebtOrderPaymentService, this.window);
      case null:
        return new CardOrderStrategy(this.paymentMethodsService, this.orderService);
      default:
        throw new Error(`Unknown payment method type: ${paymentMethodType}`);
    }
  }
}

/** Handles EBT payment for the order. */
class EbtOrderStrategy implements OrderPaymentStrategy {
  public constructor(
    private readonly ebtPaymentMethodService: EbtOrderPaymentService,
    private readonly window: Window,
  ) {}

  /** @inheritdoc */
  public createOrder(_createOrderData: CreateOrderData): Observable<Order['id']> {
    return this.ebtPaymentMethodService.initializeEbtCheckout(_createOrderData).pipe(
      tap(({ url }) => {
        this.window.location.href = url;
      }),

      // This will never happen, since we redirected to another website, and the EBT-handling site will redirect us to either:
      // 1. The order confirmation page, or
      // 2. The error page, if the payment was not successful
      ignoreElements(),
    );
  }
}

/** Creates an order using cart payments. */
class CardOrderStrategy implements OrderPaymentStrategy {
  public constructor(
    private readonly paymentMethodsService: CardPaymentMethodsService,
    private readonly orderService: OrdersService,
  ) {}

  /** @inheritdoc */
  public createOrder(createOrderData: CreateOrderData): Observable<Order['id']> {
    const paymentCard$ = this.paymentMethodsService.activeCardPaymentMethod$.pipe(
      first(),
    );

    return paymentCard$.pipe(
      switchMap(card => this.orderService.orderViaCard({
        ...createOrderData,
        paymentMethodType: PaymentMethodType.Card,
        card,
      })),
    );
  }
}
