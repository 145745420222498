<a
  *ngIf="store$ | async as store; else emailSkeletonTemplate"
  href="mailto:{{ store.email }}"
  class="email"
>
  {{ store.email }}
</a>

<ng-template #emailSkeletonTemplate>
  <span
    aria-hidden
    pboxcSkeleton
    [pboxcSkeletonMinWidth]="10"
    [pboxcSkeletonMaxWidth]="10"
  ></span>
</ng-template>
