import { Injectable } from '@angular/core';

import { User } from '../../models/user';

import { UserDto } from './dto/user.dto';
import { IMapperToDto } from './mappers';

/** Change deliveries settings mapper. */
@Injectable({
  providedIn: 'root',
})
export class ChangeDeliveriesSettingsMapper implements IMapperToDto<UserDto.ChangeDeliveriesSettings, User.ChangeDeliveriesSettings> {

  /** @inheritdoc */
  public toDto(data: User.ChangeDeliveriesSettings): UserDto.ChangeDeliveriesSettings {
    return {
      delivery_instructions: data.instructions,
      referrer_employer_id: data.employerReference?.id ?? null,
    };
  }
}
