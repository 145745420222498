import { Injectable } from '@angular/core';

import { SearchOptions } from '../../models/search-options';

import { SearchOptionsDto } from './dto/search-options.dto';

/** Mapper for search options. */
@Injectable({ providedIn: 'root' })
export class SearchOptionsMapper {

  /** @inheritdoc */
  public mapPaginationOptionsToDto(data: SearchOptions.Pagination): SearchOptionsDto.Any {
    return {
      page: data.page,
      per_page: data.pageSize,
    };
  }
}
