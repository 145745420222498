import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { filterNull } from '../utils/rxjs/filter-null';

import { UserService } from './user.service';

/** Provides information about the current balance of user's account. */
@Injectable({ providedIn: 'root' })
export class BalanceService {
  /** Current account's balance. */
  public readonly balance$: Observable<number>;

  public constructor(
    private readonly userService: UserService,
  ) {
    this.balance$ = this.userService.currentUser$.pipe(
      filterNull(),
      map(({ balance }) => balance),
    );
  }
}
