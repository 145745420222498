import { Injectable } from '@angular/core';

import { AppError } from '../../models/app-error';
import { Store } from '../../models/store';

import { StoreDto } from './dto/store.dto';
import { IMapperFromDto } from './mappers';

/** Store mapper. */
@Injectable({
  providedIn: 'root',
})
export class StoreMapper implements IMapperFromDto<StoreDto, Store.Data> {

  /** @inheritdoc */
  public fromDto(dto: StoreDto): Store.Data {
    const { slug } = dto;

    if (!Store.Slug.is(slug)) {
      throw new AppError('Unsupported store');
    }

    return {
      id: dto.store_id,
      slug,
      name: dto.name,
      url: dto.url,
      address: dto.address,
      email: dto.email,
      phone: dto.phone,
      socialMedia: dto.socials,
      isDeliveryEnabled: slug !== Store.Slug.Suppermeals,
    };
  }
}
