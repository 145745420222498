<ul class="products" *ngIf="orderItems; else skeletonTemplate">
  <li
    class="products__item product"
    *ngFor="let orderItem of orderItems; trackBy: trackOrderItem"
  >
    <pboxc-order-item
      [withImages]="withImages"
      [orderItem]="orderItem"
      [orderItemSlotTemplate]="orderItemSlotTemplate"
      [isCustomizationEnabled]="isCustomizationEnabled"
    ></pboxc-order-item>
  </li>
</ul>

<ng-template #skeletonTemplate>
  <div class="products">
    <!-- eslint-disable-next-line @angular-eslint/template/use-track-by-function -->
    <div class="products__item" *ngFor="let _ of [1, 2, 3]">
      <pboxc-order-item
        [withImages]="withImages"
        [orderItemSlotTemplate]="orderItemSlotTemplate"
        [orderItemSlotSkeletonTemplate]="orderItemSlotSkeletonTemplate"
      ></pboxc-order-item>
    </div>
  </div>
</ng-template>
