import { defer, EMPTY, merge, MonoTypeOperatorFunction, pipe, Subject } from 'rxjs';
import { finalize, ignoreElements, shareReplay, tap } from 'rxjs/operators';

/**
 * Toggles loading subject when observable execution starts and ends.
 * @param subject$ Execution state subject. Will accept `true` when execution started and `false` when
 * it's either finalized or emitted an item.
 * @param options Options.
 */
export function toggleExecutionState<T>(
  subject$: Subject<boolean>,
  { untilFirstEmission = false } = {},
): MonoTypeOperatorFunction<T> {
  const startLoadingSideEffect$ = defer(() => {
    subject$.next(true);
    return EMPTY;
  });

  return source$ => {
    const sharedSource$ = source$.pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    const finishLoadingSideEffect$ = sharedSource$.pipe(
      finalizeExecutionState(untilFirstEmission, () => subject$.next(false)),
      ignoreElements(),
    );

    return merge(
      startLoadingSideEffect$,
      finishLoadingSideEffect$,
      sharedSource$,
    );
  };
}

/**
 * Operator that finalizes execution state subject when observable execution ends.
 * @param untilFirstEmission Whether to finalize execution state when first emission is received.
 * @param stopExecutionState Callback representing the action that will stop the execution state.
 */
function finalizeExecutionState<T>(untilFirstEmission: boolean, stopExecutionState: () => void): MonoTypeOperatorFunction<T> {
  if (untilFirstEmission) {
    return pipe(tap(stopExecutionState), finalize(stopExecutionState));
  }
  return finalize(stopExecutionState);
}
