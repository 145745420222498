import { Injectable } from '@angular/core';

import { CardBrand } from '../../models/card-brand';

import { CardBrandDto } from './dto/card-brand.dto';

const MAP_FROM_DTO = {
  [CardBrandDto.Visa]: CardBrand.Visa,
  [CardBrandDto.Amex]: CardBrand.Amex,
  [CardBrandDto.Discover]: CardBrand.Discover,
  [CardBrandDto.Mastercard]: CardBrand.Mastercard,
  [CardBrandDto.Unknown]: CardBrand.Unknown,
};

/** Card brand mapper. */
@Injectable({ providedIn: 'root' })
export class CardBrandMapper {

  /** @inheritdoc */
  public fromDto(data: CardBrandDto): CardBrand {
    return MAP_FROM_DTO[data] ?? CardBrand.Unknown;
  }
}
