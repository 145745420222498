<ng-container *ngIf="{ store: store$ | async } as data">
  <footer class="footer">
    <pboxc-main-logo type="contrast" class="logo"></pboxc-main-logo>

    <div class="store-info">
      <pboxc-contact-info
        [contactInfo]="data.store"
        class="contact-info"
      ></pboxc-contact-info>
      <pboxc-social-media
        [socialMedia]="data.store?.socialMedia ?? null"
        class="social-media"
      ></pboxc-social-media>
    </div>

    <pboxc-store-policy
      class="store-policy"
      [store]="data.store"
    ></pboxc-store-policy>
  </footer>
</ng-container>
