import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CheckoutPageService } from '../checkout-page.service';

/** Tip form. */
@UntilDestroy()
@Component({
  selector: 'pboxc-tip-form',
  templateUrl: './tip-form.component.html',
  styleUrls: ['./tip-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TipFormComponent {

  public constructor(
    protected readonly checkoutPageService: CheckoutPageService,
  ) {}
}
