import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterService } from '@pbox/common/shared/ui-services/router.service';

import { DialogContainerComponent } from '../dialog-container/dialog-container.component';

/** Dialog for canceled membership . */
@UntilDestroy()
@Component({
  selector: 'pboxc-canceled-membership-dialog',
  templateUrl: './canceled-membership-dialog.component.html',
  styleUrls: ['./canceled-membership-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogContainerComponent],
})
export class CanceledMembershipDialogComponent implements OnInit {

  public constructor(
    private readonly matDialogRef: MatDialogRef<CanceledMembershipDialogComponent>,
    private readonly routerService: RouterService,
  ) { }

  /** @inheritdoc */
  public ngOnInit(): void {
    this.routerService.handleAfterNavigationEnd(() => this.matDialogRef.close()).pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

}
