import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, throwError } from 'rxjs';

import { AppValidationError } from '../models/app-error';

import { AddCardBillingInformationData, CardBillingInformation, CardBillingInformationUpdateData } from '../models/billing-information';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { CardPaymentMethodMapper } from './mappers/card-payment-method.mapper';
import { BaseResponseDto } from './mappers/dto/base-response.dto';
import { CardPaymentMethodDto } from './mappers/dto/card-payment-method.dto';

/** Payment methods API service. */
@Injectable({
  providedIn: 'root',
})
export class PaymentMethodsApiService {

  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly paymentMethodMapper: CardPaymentMethodMapper,
  ) { }

  /** Obtains a list of payment cards for the user. */
  public getPaymentCards(): Observable<CardBillingInformation[]> {
    return this.httpClient.get<BaseResponseDto<CardPaymentMethodDto[]>>(
      this.apiUrls.creditCard.list,
    ).pipe(
      map(response => response.data.map(dto => this.paymentMethodMapper.fromDto(dto))),
    );
  }

  /**
   * Adds card as a payment method.
   * @param paymentMethod Payment method data for creating the card.
   */
  public addPaymentCard(
    paymentMethod: AddCardBillingInformationData,
  ): Observable<CardBillingInformation> {
    return this.httpClient.post<BaseResponseDto<CardPaymentMethodDto>>(
      this.apiUrls.creditCard.creditCards,
      this.paymentMethodMapper.mapAddDataToDto(paymentMethod),
    ).pipe(
      map(dto => this.paymentMethodMapper.fromDto(dto.data)),
      catchHttpErrorResponse(error => {
        if (error.error.message) {
          return throwError(() => new AppValidationError<AddCardBillingInformationData>('Payment method can\'t be added', {
            cardName: error.error.message,
          }));
        }
        return throwError(() => error);
      }),
    );
  }

  /**
   * Sets the card payment method as active.
   * @param id Payment card id.
   */
  public setActivePaymentCard(id: CardBillingInformation['id']): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.creditCard.setActive(id.toString()),
      { },
    );
  }

  /**
   * Updates billing information of the payment card.
   * @param id Payment card id.
   * @param data Data required to update payment card.
   */
  public updatePaymentCard(id: CardBillingInformation['id'], data: CardBillingInformationUpdateData): Observable<void> {
    return this.httpClient.patch<void>(
      this.apiUrls.creditCard.creditCard(id.toString()),
      this.paymentMethodMapper.mapUpdateDataToDto(data),
    );
  }

  /**
   * Deletes the payment card.
   * @param id Payment card id.
   */
  public deletePaymentCard(id: CardBillingInformation['id']): Observable<void> {
    return this.httpClient.delete<void>(
      this.apiUrls.creditCard.creditCard(id.toString()),
    );
  }
}
