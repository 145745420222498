import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CommonSharedModule } from '@pbox/common/shared/common-shared.module';
import { MatIconModule } from '@angular/material/icon';

import { OrderItemComponent } from './order-item/order-item.component';
import { OrderItemsListComponent } from './order-items-list.component';

/** Provides component representing list of items in the order. */
@NgModule({
  declarations: [OrderItemsListComponent, OrderItemComponent],
  exports: [OrderItemsListComponent],
  imports: [CommonModule, CommonSharedModule, MatIconModule],
})
export class OrderItemsListModule { }
