import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap, throwError } from 'rxjs';

import { CartItemUpdateError } from '../errors/cart-item-update-error';

import { AppError, AppValidationError } from '../models/app-error';
import { Cart } from '../models/cart';
import { AddCoupon, Coupon } from '../models/coupon';
import { NewOrderItem, OrderItem } from '../models/order-item';
import { Product } from '../models/product';
import { Store } from '../models/store';
import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { CartMapper } from './mappers/cart.mapper';
import { CouponMapper } from './mappers/coupon.mapper';
import { BaseResponseDto } from './mappers/dto/base-response.dto';
import { CartDto } from './mappers/dto/cart.dto';
import { OrderItemDto } from './mappers/dto/order-item.dto';
import { ApiError } from './mappers/dto/validation-error.dto';

/** Cart api service. */
@Injectable({
  providedIn: 'root',
})
export class CartApiService {
  public constructor(
    private readonly apiUrls: AppUrlsConfig,
    private readonly httpClient: HttpClient,
    private readonly cartMapper: CartMapper,
    private readonly couponMapper: CouponMapper,
  ) { }

  /**
   * Returns current cart.
   * @param storeId Store id.
   */
  public getCurrentCart(storeId: Store.Data['id']): Observable<Cart> {
    const params = new HttpParams({
      fromObject: {
        store_id: storeId,
      },
    });

    const currentCartId$ = this.httpClient.get<BaseResponseDto<{ cart_id: string; }>>(this.apiUrls.user.profile, { params }).pipe(
      map(({ data }) => data.cart_id),
    );
    return currentCartId$.pipe(
      switchMap(cartId => this.httpClient.get<BaseResponseDto<CartDto>>(this.apiUrls.cart.cart(cartId))),
      map(dto => this.cartMapper.fromDto(dto.data)),
    );
  }

  /**
   * Adds product to cart.
   * @param item Item to add to cart.
   * @param cart Cart to add item to.
   */
  public addItem(item: NewOrderItem, cart: Cart): Observable<OrderItem> {
    return this.httpClient.post<BaseResponseDto<OrderItemDto>>(
      this.apiUrls.cart.cartItems,
      this.cartMapper.newCartItemToDto(cart.id, item),
    ).pipe(
      map(response => this.cartMapper.mapCartItemFromDto(response.data)),
    );
  }

  /**
   * Breaks the passed product down into individual addons, then adds them to the cart.
   * @param product Product.
   * @param cart Cart.
   */
  public addCustomizedItem(product: Product, cart: Cart): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.cart.customizeItem,
      this.cartMapper.newCustomizedItemToDto(cart.id, product),
    ).pipe(
      catchHttpErrorResponse(error => throwError(() => new AppError(error.error.message ?? error.message))),
    );
  }

  /**
   * Edits order item in the cart.
   * @param item Item to edit.
   */
  public editItem(item: OrderItem): Observable<void> {
    return this.httpClient.put<void>(
      this.apiUrls.cart.cartItem(item.id.toString()),
      { quantity: item.quantity },
    ).pipe(
      catchHttpErrorResponse(error => {
        const { message } = error.error as ApiError<unknown>;

        return throwError(() => new CartItemUpdateError(message, item));
      }),
    );
  }

  /**
   * Removes item from the cart.
   * @param item Item to remove.
   */
  public removeItem(item: OrderItem): Observable<void> {
    return this.httpClient.delete<void>(this.apiUrls.cart.cartItem(item.id.toString()));
  }

  /**
   * Applies coupon to cart.
   * @param cart Cart to apply coupon to.
   * @param coupon Coupon to apply.
   */
  public applyCoupon(cart: Cart, coupon: AddCoupon): Observable<void> {
    return this.httpClient.post<void>(
      this.apiUrls.cart.coupons(cart.id.toString()),
      this.couponMapper.toDto(coupon),
    ).pipe(
      catchHttpErrorResponse(error => {
        const { message } = error.error as ApiError<unknown>;

        if (message != null) {
          return throwError(() => new AppValidationError<Coupon>(message, { code: message }));
        }

        return throwError(() => error);
      }),
    );
  }

  /**
   * Removes coupon from the cart.
   * @param cart Cart.
   */
  public removeCoupon(cart: Cart): Observable<void> {
    if (cart.coupon == null) {
      return throwError(() => new Error('Cart does not have coupon.'));
    }
    return this.httpClient.delete<void>(this.apiUrls.cart.coupon(cart.id.toString(), cart.coupon.id.toString()));
  }
}
