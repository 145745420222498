import { Injectable } from '@angular/core';

import { IMapperToDto } from './mappers';

/** Date mapper. */
@Injectable({ providedIn: 'root' })
export class DateMapper implements IMapperToDto<string, Date> {

  /** @inheritdoc */
  public toDto(data: Date): string {
    return `${data.getFullYear()}-${data.getMonth() + 1}-${data.getDate()}`;
  }

}
