import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

/** Checkbox component. */
@Component({
  selector: 'pboxc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => CheckboxComponent)],
})
export class CheckboxComponent extends SimpleValueAccessor<boolean> {

  /** Checkbox label. */
  @Input()
  public label = '';

  /** Secondary checkbox label. Used for presenting additional detail about the option. */
  @Input()
  public secondaryLabel = '';

  /** Whether checkbox label is visible. */
  @Input()
  public withLabel = true;
}
