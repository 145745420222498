import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, first, map, Observable, of, switchMap, throwError } from 'rxjs';

import { EbtCheckoutRedirectData } from '../models/ebt-checkout-redirect-data';
import { CreateOrderData } from '../models/order';

import { assertNonNullablePropertiesWithReturn } from '../utils/assert-non-null';

import { CompleteEbtCheckoutData, EbtPaymentStatus } from '../models/complete-ebt-checkout-data';

import { AppError } from '../models/app-error';

import { onMessageOrFailed } from '../utils/rxjs/on-message-or-failed';

import { catchHttpErrorResponse } from '../utils/rxjs/catch-http-error-response';

import { AppUrlsConfig } from './app-urls.config';
import { InitializeEbtCheckoutDto } from './mappers/dto/initialize-ebt-checkout.dto';
import { InitializeEbtCheckoutResultDto } from './mappers/dto/initialize-ebt-checkout-result.dto';
import { BaseResponseDto } from './mappers/dto/base-response.dto';

import { StoresService } from './stores.service';
import { CompleteEbtCheckoutDto } from './mappers/dto/complete-ebt-checkout.dto';
import { CartService } from './cart.service';

const PAYMENT_STATUS_MAP_FROM_DTO: Readonly<Record<CompleteEbtCheckoutDto['status'], EbtPaymentStatus>> = {
  SUCCEEDED: EbtPaymentStatus.Completed,
  CANCELED: EbtPaymentStatus.Cancelled,
};

/** Provides a workflow methods for EBT checkout. */
@Injectable({ providedIn: 'root' })
export class EbtOrderPaymentService {
  public constructor(
    private readonly httpClient: HttpClient,
    private readonly appUrlsConfig: AppUrlsConfig,
    private readonly storesService: StoresService,
    private readonly cartService: CartService,
  ) {}

  /**
   * Initializes ebt checkout. Returns information required to continue handling the process.
   * @param _orderData Data required to create an order.
   */
  public initializeEbtCheckout(_orderData: CreateOrderData): Observable<EbtCheckoutRedirectData> {
    const body$ = combineLatest([
      this.storesService.currentStore$,
      of(_orderData.cartId),
      of(_orderData.tipAmount),
    ]).pipe(
      first(),
      map(([{ id }, cartId, tipAmount]) => ({ store_id: id, cart_id: cartId, gratuity: tipAmount || 0 } as InitializeEbtCheckoutDto)),
    );

    return body$.pipe(
      switchMap(body =>
        this.httpClient.post<BaseResponseDto<InitializeEbtCheckoutResultDto>>(
          this.appUrlsConfig.ebtPayments.initialize,
          body,
        )),
      map(data => this.mapEbtCheckoutRedirectData(data)),
      catchHttpErrorResponse(error => throwError(() => new AppError(error.error.message ?? error.message))),
      onMessageOrFailed(() => this.cartService.invalidateCart()),
    );
  }

  /**
   * Completes the ebt checkout.
   * @param paymentData Data required to complete the checkout.
   */
  public completeEbtCheckout(paymentData: CompleteEbtCheckoutData): Observable<void> {
    const params = new HttpParams({
      fromObject: {
        hash: paymentData.hash,
      },
    });

    return this.httpClient.get<void>(
      this.appUrlsConfig.ebtPayments.complete,
      { params },
    );
  }

  /**
   * Maps complete checkout data from dto.
   * @param completeEbtCheckoutDto Data required to complete the checkout.
   * @throws {AppError} In case some of the properties are missing or invalid.
   */
  public mapEbtCheckoutData(completeEbtCheckoutDto: Partial<CompleteEbtCheckoutDto>): CompleteEbtCheckoutData {
    const data = assertNonNullablePropertiesWithReturn(
      completeEbtCheckoutDto,
      'hash',
      'status',
      'cart_id',
      'store_id',
    );

    if (!(data.status in PAYMENT_STATUS_MAP_FROM_DTO)) {
      throw new AppError(`Invalid status: ${data.status}`);
    }

    return {
      hash: data.hash,
      status: PAYMENT_STATUS_MAP_FROM_DTO[data.status],
      cartId: data.cart_id,
      storeId: data.store_id,
    };
  }

  private mapEbtCheckoutRedirectData(response: BaseResponseDto<InitializeEbtCheckoutResultDto>): EbtCheckoutRedirectData {
    return {
      url: response.data.redirect_url,
    };
  }
}
