import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { HOME_PAGE } from '@pbox/common/core/guards/authorized.guard';
import { Order } from '@pbox/common/core/models/order';
import { User } from '@pbox/common/core/models/user';
import { OrdersService } from '@pbox/common/core/services/orders.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { first, map, Observable, of, switchMap } from 'rxjs';

/** Provides a recommended url for the current user.  */
@Injectable({ providedIn: 'root' })
export class RecommendedNavigationService {
  private readonly defaultUrlTree = this.router.createUrlTree([HOME_PAGE]);

  public constructor(
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly orderService: OrdersService,
  ) {}

  /** Redirect user to a recommended page based on their profile information. */
  public getRecommendedRoute(): Observable<UrlTree> {
    return this.userService.currentUser$.pipe(
      first(),
      switchMap(user => {
        if (user == null) {
          return of(this.defaultUrlTree);
        }

        return this.orderService.getOrders({ page: 1, pageSize: 1 }).pipe(
          map(page => this.getUrlTreeToRedirect(user, page.items)),
        );
      }),
    );
  }

  /**
   * Creates a url tree based on a current state of the user and their orders.
   * According to this task: https://saritasa.atlassian.net/browse/PBDEV-364.
   * @param user User.
   * @param orders Orders.
   */
  private getUrlTreeToRedirect(user: User, orders: Order[]): UrlTree {
    if (user.isFirstTime) {
      return this.router.createUrlTree(['welcome']);
    }

    if (user.menu.isOpen) {
      return this.defaultUrlTree;
    }

    if (user.hasOrderWeek) {
      const lastOrderId = orders[0].id;
      return this.router.createUrlTree([`order/details/${lastOrderId}`]);
    }

    if (user.menu.isOpen === false) {
      return this.router.createUrlTree(['welcome/back/recurring-orders']);
    }

    return this.defaultUrlTree;

  }
}
