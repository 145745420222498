import { AfterViewInit, ChangeDetectionStrategy, Component, NgZone, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { AppError } from '@pbox/common/core/models/app-error';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { CardSecret } from '@pbox/common/core/services/card-payment-methods.service';
import { AppValidators } from '@pbox/common/core/utils/validators';
import { BehaviorSubject, map, Observable, skip, Subject, Subscription, take, throwError } from 'rxjs';

/** Additional data required for the card set up. */
export interface AdditionalCardSetupData {

  /** Zip code of the billing address. */
  readonly zipCode?: string;
}

/** Data provided by the payment card form. */
export interface PaymentCardFormData {

  /** Cardholder name. */
  readonly fullName: string;

  /** Tokenized secure card information. */
  readonly secret: CardSecret;
}

const SPREEDLY_INPUT_STYLE = 'width: 100%; font-size: 14px; font-family: Fraunces;';

const SPREEDLY_INPUT_KEYS = {
  year: 'year',
  month: 'month',
  firstName: 'first_name',
  lastName: 'last_name',
  fullName: 'full_name',
  cardNumber: 'number',
  csc: 'cvv',
} as const;

/**
 * Parses human-readable error message from spreedly error.
 * @param errors Spreedly errors.
 * @param keys Spreedly input field.
 */
function parseSpreedlyErrorsByKey(errors: readonly spreedly.SpreedlyError[], keys: readonly string[]): string | null {
  return errors.find(({ attribute }) => keys.includes(attribute))?.message ?? null;
}

/** Errors in spreedly controls. */
interface SpreedlyFormError {

  /** Card number field error. */
  readonly cardNumber: string | null;

  /** CVV field error. */
  readonly csc: string | null;
}

interface SpreedlyForm {

  /** Cardholder name. */
  readonly cardholderName: FormControl<string>;

  /** Date of expiration in `yy/mm` format. */
  readonly expiresAt: FormControl<string>;
}

const INVALID_DATA_MESSAGE = 'Invalid data used in payment form';

const CARD_FORM_INVALID_MESSAGE = 'Card form is invalid';

/**
 * Payment form for CC information.
 * Could either be used for creating a card payment method or paying for the product directly.
 */
@Component({
  selector: 'pboxc-payment-card-form',
  templateUrl: './payment-card-form.component.html',
  styleUrls: ['./payment-card-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentCardFormComponent implements AfterViewInit, OnDestroy {

  /** Form. */
  protected readonly form: FormGroup<SpreedlyForm>;

  // Unable to use observables for watching events since Spreedly does not allow disposing handlers one by one, only all at the same time.

  /** Errors from controls that are managed directly by Spreedly API. */
  protected readonly spreedlyFieldsErrorSubject = new BehaviorSubject<SpreedlyFormError>({
    cardNumber: null,
    csc: null,
  });

  /** Payment method trigger. */
  protected readonly spreedlyPaymentMethodSubject = new Subject<PaymentCardFormData>();

  public constructor(
    fb: NonNullableFormBuilder,
    private readonly appConfig: AppConfig,

    // Need zone to catch spreedly events, they are triggered from iframes (and outside current app's ngzone)
    private readonly ngZone: NgZone,
  ) {
    // Using on-submit validation to match Spreedly
    this.form = fb.group({
      cardholderName: fb.control('', [Validators.required]),
      expiresAt: fb.control('', [Validators.required, Validators.pattern(/\d{2}\/\d{2}/)]),
    });
  }

  /**
   * @inheritdoc
   * @private
   */
  public ngAfterViewInit(): void {
    this.initSpreedlyAPI();
    this.setupSpreedlyFields();
    this.initFieldErrorCleanup();
    this.initErrorHandling();
    this.initPaymentMethodWatching();
  }

  /**
   * @inheritdoc
   * @private
   */
  public ngOnDestroy(): void {
    Spreedly.removeHandlers();
    this.spreedlyFieldsErrorSubject.complete();
    this.spreedlyPaymentMethodSubject.complete();
  }

  /**
   * Creates payment method token based on the information provided in the payment form.
   * @param additionalData Additional data required for the card set up.
   */
  public getFormData(additionalData: AdditionalCardSetupData): Observable<PaymentCardFormData> {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return throwError(() => new AppError(CARD_FORM_INVALID_MESSAGE));
    }

    return new Observable(observer => {
      const value = this.form.getRawValue();
      const [monthString, twoDigitYear] = value.expiresAt.split('/');

      const PREV_CENTURY = 20;
      Spreedly.tokenizeCreditCard({
        // Spreedly name convention
        // eslint-disable-next-line @typescript-eslint/naming-convention
        full_name: value.cardholderName,
        month: monthString,
        year: `${PREV_CENTURY}${twoDigitYear}`,
        zip: additionalData.zipCode,
      });

      const paymentMethodSubs: Subscription[] = [];

      paymentMethodSubs.push(this.spreedlyFieldsErrorSubject.pipe(
        skip(1),
        take(1),
        map(() => new AppError(INVALID_DATA_MESSAGE)),
      ).subscribe(error => observer.error(error)));

      paymentMethodSubs.push(this.spreedlyPaymentMethodSubject.pipe(
        take(1),
      ).subscribe(paymentMethod => observer.next(paymentMethod)));

      return () => paymentMethodSubs.forEach(pm => pm.unsubscribe());
    });
  }

  private initPaymentMethodWatching(): void {
    Spreedly.on('paymentMethod', (_, paymentMethod) => {
      this.ngZone.run(() => this.spreedlyPaymentMethodSubject.next({
        secret: paymentMethod.token,
        fullName: this.form.getRawValue().cardholderName,
      }));
  });
  }

  private initErrorHandling(): void {
    Spreedly.on('errors', errors => {
      const cardholderNameError = parseSpreedlyErrorsByKey(
        errors,
        [
          SPREEDLY_INPUT_KEYS.firstName,
          SPREEDLY_INPUT_KEYS.lastName,
        ],
      );

      const expiresAtError = parseSpreedlyErrorsByKey(
        errors,
        [
          SPREEDLY_INPUT_KEYS.month,
          SPREEDLY_INPUT_KEYS.year,
        ],
      );

      if (expiresAtError) {
        this.form.controls.expiresAt.setErrors(
          AppValidators.buildAppError(expiresAtError),
        );
      }
      if (cardholderNameError) {
        this.form.controls.cardholderName.setErrors(
          AppValidators.buildAppError(cardholderNameError),
        );
      }

      this.ngZone.run(() => this.spreedlyFieldsErrorSubject.next({
        cardNumber: parseSpreedlyErrorsByKey(errors, [SPREEDLY_INPUT_KEYS.cardNumber]),
        csc: parseSpreedlyErrorsByKey(errors, [SPREEDLY_INPUT_KEYS.csc]),
      }));
    });
  }

  private setupSpreedlyFields(): void {
    Spreedly.on('ready', () => {
      Spreedly.setFieldType(SPREEDLY_INPUT_KEYS.cardNumber, 'text');
      Spreedly.setNumberFormat('prettyFormat');

      Spreedly.setPlaceholder(SPREEDLY_INPUT_KEYS.cardNumber, '0000 0000 0000 0000');
      Spreedly.setPlaceholder(SPREEDLY_INPUT_KEYS.csc, '000');

      Spreedly.setStyle(SPREEDLY_INPUT_KEYS.cardNumber, SPREEDLY_INPUT_STYLE);
      Spreedly.setStyle(SPREEDLY_INPUT_KEYS.csc, SPREEDLY_INPUT_STYLE);
    });
  }

  private initFieldErrorCleanup(): void {
    Spreedly.on('fieldEvent', (field, type) => {
      if (type === 'input') {
        // So as not to overcomplicate this, use simple callback API with sync assignment
        const currentError = this.spreedlyFieldsErrorSubject.getValue();
        this.ngZone.run(() => this.spreedlyFieldsErrorSubject.next({
          cardNumber: field !== SPREEDLY_INPUT_KEYS.cardNumber ? currentError.cardNumber : null,
          csc: field !== SPREEDLY_INPUT_KEYS.csc ? currentError.csc : null,
        }));
      }
    });
  }

  private initSpreedlyAPI(): void {
    Spreedly.init(this.appConfig.spreedlyToken, {
      numberEl: 'spreedly-number',
      cvvEl: 'spreedly-cvv',
    });
  }
}
