/** Location types. */
export enum LocationType {
  Neighborhood = 'neighborhood',
  Corporate = 'corporate',
}

/** Location. */
export interface Location {

  /** ID. */
  readonly id: number;

  /** Name. */
  readonly name: string;

  /** Zip code. */
  readonly zipCode: number | null;

  /** State id. */
  readonly type: LocationType;
}

export namespace Location {

  /**
   * Transforms location into human-readable format.
   * @param location Location.
   */
  export function toReadable(location: Location): string {
    return location.name;
  }
}
