<header class="header">
  <pboxw-header
    [shouldShowCartLink]="false"
    class="header__body"
  ></pboxw-header>
  <h1
    class="header__heading"
    *ngIf="
      welcomeLayoutService.title$ | async as title;
      else titleSkeletonTemplate
    "
  >
    {{ title }}
  </h1>
</header>

<main class="content">
  <router-outlet></router-outlet>
</main>

<pboxw-footer></pboxw-footer>

<ng-template #titleSkeletonTemplate>
  <span
    class="h1 header__heading"
    pboxcSkeleton
    [pboxcSkeletonMinWidth]="20"
    [pboxcSkeletonMaxWidth]="26"
  >
  </span>
</ng-template>
