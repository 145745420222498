<form
  (ngSubmit)="onCouponSubmit()"
  [formGroup]="couponForm"
  *ngIf="(coupon$ | async) === null; else appliedCouponTemplate"
>
  <div class="coupon">
    <label class="coupon__label">
      <span class="visually-hidden">Coupon</span>
      <pboxc-form-error-wrapper class="error-wrapper">
        <input
          type="text"
          class="coupon__input"
          placeholder="Add Coupon Code"
          formControlName="code"
        />
      </pboxc-form-error-wrapper>
    </label>

    <button
      class="primary coupon__submit"
      [disabled]="orderPageService.isLoading$ | async"
      type="submit"
    >
      Apply
    </button>
  </div>
</form>

<ng-template #appliedCouponTemplate>
  <div class="applied-coupon">
    <span class="applied-coupon__text">{{ (coupon$ | async)?.code }}</span>
    <button
      class="applied-coupon__remove-button icon-button"
      [disabled]="orderPageService.isLoading$ | async"
      (click)="onCouponRemove()"
      type="button"
    >
      <mat-icon class="icon-button__icon">close</mat-icon>
    </button>
  </div>
</ng-template>
