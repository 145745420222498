import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Cart } from '@pbox/common/core/models/cart';
import { AppConfig } from '@pbox/common/core/services/app.config';
import { StoreTextsService } from '@pbox/common/shared/ui-services/store-texts.service';

/** Represents summary of the order. */
@Component({
  selector: 'pboxc-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent {
  /** Cart. */
  @Input()
  public cart: Cart | null = null;

  public constructor(
    protected readonly storeTextsService: StoreTextsService,
    protected readonly appConfig: AppConfig,
  ) {}
}
