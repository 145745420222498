import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';

/** Radio component. */
@Component({
  selector: 'pboxc-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => RadioComponent)],
})
export class RadioComponent<T> extends SimpleValueAccessor<T> {

  /** Radio input element. */
  @ViewChild('radioInput', { read: ElementRef })
  public radioInput!: ElementRef;

  /** Value. */
  @Input()
  public value: T | null = null;

  /** Name. */
  @Input()
  public name: string | null = null;

  /** Handle check the radio input. */
  public check(): void {
    this.controlValue = this.value;
    this.radioInput.nativeElement.click();
  }
}
