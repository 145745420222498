import { Injectable } from '@angular/core';
import { forkJoin, ignoreElements, map, Observable } from 'rxjs';

import { UserSecret } from '../models/user-secret';

import { StorageService } from './storage.service';

const USER_SECRET_STORAGE_KEY = 'user';
export const HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY = 'HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION';

/** User secret storage. */
@Injectable({ providedIn: 'root' })
export class UserSecretStorageService {
  /** Token info for current user. */
  public readonly currentSecret$: Observable<UserSecret | null>;

  public constructor(
    private readonly storageService: StorageService,
  ) {
    this.currentSecret$ = this.getUserSecret();
  }

  /** Get user secret. */
  public getUserSecret(): Observable<UserSecret | null> {
    return this.storageService.get<UserSecret | null>(USER_SECRET_STORAGE_KEY);
  }

  /**
   * Saves a secret.
   * @param secret Secret to save.
   */
  public saveSecret(
    secret: UserSecret,
  ): Observable<UserSecret> {
    return this.storageService.save(USER_SECRET_STORAGE_KEY, secret).pipe(map(() => secret));
  }

  /** Removes current secret. */
  public removeSecret(): Observable<void> {
    return forkJoin([
      this.storageService.remove(USER_SECRET_STORAGE_KEY),
      this.storageService.remove(HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY),
    ]).pipe(
      ignoreElements(),
    );
  }
}
