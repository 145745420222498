/** Menu. */
export interface Menu {

  /** Menu id. */
  readonly id: number;

  /** Village id. */
  readonly villageId: number;

  /** Whether menu is published. */
  readonly isPublished: boolean;
}

/** Menu information constructor data. */
type MenuInformationConstructorData = Omit<MenuInformation, 'hasUnknownDeliveryDate'>;

/** Menu information. */
export class MenuInformation {

  /** Menu. */
  public readonly content: Menu | null;

  /** Information about the menu. */
  public readonly message: string;

  /** Delivery information. */
  public readonly delivery: {

    /** Date. */
    readonly date: Date | null;

    /** Description. */
    readonly description: string;
  };

  /** Whether menu is opened. */
  public readonly isOpen: boolean;

  /** Current open date. */
  public readonly openDate: Date;

  /** Current close date. */
  public readonly closeDate: Date;

  /** Next open date. */
  public readonly nextOpenDate: Date;

  /** Next close date. */
  public readonly nextCloseDate: Date;

  public constructor(
    data: MenuInformationConstructorData,
  ) {
    this.content = data.content;
    this.message = data.message;
    this.delivery = data.delivery;
    this.isOpen = data.isOpen;
    this.openDate = data.openDate;
    this.closeDate = data.closeDate;
    this.nextOpenDate = data.nextOpenDate;
    this.nextCloseDate = data.nextCloseDate;
  }

  /** Whether delivery date is unknown. */
  public get hasUnknownDeliveryDate(): boolean {
    return this.delivery.date === null;
  }
}
