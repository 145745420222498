import { Injectable } from '@angular/core';
import { Store } from '@pbox/common/core/models/store';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { map, Observable } from 'rxjs';

/** Represents texts used in a store. */
interface StoreText {

  /** Texts used on the login page. */
  readonly login: {

    /** Instruction for signing up. */
    readonly signUpInstruction: string;
  };

  /** Texts used on the sign-up page. */
  readonly signUp: {

    /** Title. */
    readonly title: string;
  };

  /** Texts used on the welcome page. */
  readonly welcome: {

    /** Title. */
    readonly title: string;

    /** First step. */
    readonly firstStep: string;

    /** Second step. */
    readonly secondStep: string;

    /** Third step. */
    readonly thirdStep: string;
  };

  /** Texts used on the order page. */
  readonly order: {

    /** Groceries title field. */
    readonly groceriesTitle: string;
  };

  /** Texts used on the user's profile page. */
  readonly profile: {

    /** Order history tab. */
    readonly orderHistory: {

      /** Description. */
      readonly description: string;
    };
  };
}

const DEFAULT_STORE_TEXT: StoreText = {
  login: {
    signUpInstruction: 'Not a member or a returning member?',
  },
  signUp: {
    title:
      'Sign up now to access the best fresh artisan foods in North Carolina',
  },
  welcome: {
    title: 'Thanks for joining us and supporting our local farmers!',
    firstStep:
      'We spend each week sourcing the best items from our farmers and producers to put on the weekly menu.',
    secondStep:
      'Our menu opens Friday morning, and ordering is available until Sunday at 11:30pm.',
    thirdStep:
      'On your assigned delivery day, a Neighborhood Ambassador delivers your hand-packed box right to your door.',
  },
  order: {
    groceriesTitle: 'Your Groceries',
  },
  profile: {
    orderHistory: {
      description: `Want to know what's in your box? Or was an item in your box not quite "Up to
      Par"? If you click the "name of the box" you ordered, you can see the box
      contents. You can even ask for Product Credit by choosing "Need a Product
      Credit".`,
    },
  },
};

const SUPPERMEALS_STORE_TEXT: StoreText = {
  login: {
    signUpInstruction: 'New to Suppermeals?',
  },
  signUp: {
    title:
      'Create your account to get your first delivery of fresh, local meal solutions!',
  },
  welcome: {
    title: 'Get ready for easy meal planning!',
    firstStep: `We spend each week finding the best ready-to-heat meals, sides, desserts
      and more from beloved kitchens across North Carolina.`,
    secondStep:
      'Our menu opens Friday morning, and ordering is available until Sunday at 11:30pm.',
    thirdStep:
      'On your assigned delivery day, a Neighborhood Ambassador delivers your hand packed order right to your door.',
  },
  order: {
    groceriesTitle: 'Your Order',
  },
  profile: {
    orderHistory: {
      description: `Was an item in your box not quite "Up to Par"?
      If you click the "name of the box" you ordered, you can see the box contents.
      You can even ask for Product Credit by choosing "Need a Product Credit".`,
    },
  },
};

/** Store texts service. */
@Injectable({
  providedIn: 'root',
})
export class StoreTextsService {
  /** Represents texts used in a store. */
  public readonly text$: Observable<StoreText>;

  public constructor(
    storesService: StoresService,
  ) {
    this.text$ = storesService.currentStore$.pipe(
      map(({ slug }) => slug === Store.Slug.Suppermeals ? SUPPERMEALS_STORE_TEXT : DEFAULT_STORE_TEXT),
    );
  }
}
