<nav class="breadcrumbs">
  <ul class="breadcrumbs__list">
    <li
      *ngFor="let item of breadcrumbs; trackBy: trackBreadcrumb"
      class="breadcrumbs__list-item"
    >
      <a
        class="breadcrumbs__link"
        *ngIf="item.url; else textBreadcrumbTemplate"
        [routerLink]="item.url"
      >
        {{ item.label }}
      </a>
      <ng-template #textBreadcrumbTemplate>
        <span class="breadcrumbs__link breadcrumbs__link_inactive">
          {{ item.label }}
        </span>
      </ng-template>
    </li>
  </ul>
</nav>
