import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';

import { AppError, AppValidationError } from '@pbox/common/core/models/app-error';
import { AddCardBillingInformationData, CardBillingInformation } from '@pbox/common/core/models/billing-information';
import { CardPaymentMethodsService } from '@pbox/common/core/services/card-payment-methods.service';
import { catchValidationData } from '@pbox/common/core/utils/rxjs/catch-validation-error';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject, catchError, defer, first, map, Observable, switchMap, throwError } from 'rxjs';

import { PaymentCardFormComponent } from '../payment-card-form/payment-card-form.component';
import { PaymentMethodInfoForm } from '../payment-method-info-form/payment-method-info-form.component';

interface CardPaymentMethodForm {

  /** Payment method info. */
  readonly paymentInfo: FormGroup<PaymentMethodInfoForm>;
}

/** Component for creating new card payment method that would be attached to the current user. */
@Component({
  selector: 'pboxc-card-payment-method-form',
  templateUrl: './card-payment-method-form.component.html',
  styleUrls: ['./card-payment-method-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardPaymentMethodFormComponent {
  /**
   * Card form.
   * @private
   */
  @ViewChild('paymentForm', { static: true })
  public _paymentForm!: PaymentCardFormComponent;

  /** Emitted after the form is submitted. */
  @Output()
  public readonly formSubmit = new EventEmitter<void>();

  /** Payment method data form. */
  protected readonly form: FormGroup<CardPaymentMethodForm>;

  /** Whether the form is being submitted. */
  public readonly isLoading$: Observable<boolean>;

  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);

  public constructor(
    fb: NonNullableFormBuilder,
    private readonly paymentMethodsService: CardPaymentMethodsService,
  ) {
    this.form = fb.group({
      paymentInfo: PaymentMethodInfoForm.initialize(fb),
    });
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  /** Creates a new card payment method. */
  public createCardPaymentMethod(): Observable<CardBillingInformation> {
    const { zipCode, cardNickname } = this.form.getRawValue().paymentInfo;
    return defer(() => {
      this.form.markAllAsTouched();
      if (this.form.invalid) {
        return throwError(() => new AppError('Invalid payment method data'));
      }

      return this._paymentForm.getFormData({ zipCode });
    }).pipe(
      map(cardData => ({
          billingInformation: {
            fullName: cardData.fullName,
            zipCode,
        },
          cardName: cardNickname,
          credentialsSecret: cardData.secret,
      } as AddCardBillingInformationData)),
      switchMap(newCardData => this.paymentMethodsService.addCard(newCardData)),
      first(),
      catchError((error: unknown) => {
        if (error instanceof AppValidationError) {
          const castedError = error as AppValidationError<AddCardBillingInformationData>;
          return throwError(() => new AppValidationError<PaymentMethodInfoForm>(castedError.message, {
            cardNickname: castedError.validationData.cardName,
          }));
        }

        return throwError(() => error);
      }),
      catchValidationData(this.form.controls.paymentInfo),
      toggleExecutionState(this.isLoadingSubject),
    );
  }
}
