import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppError } from '@pbox/common/core/models/app-error';
import { Product } from '@pbox/common/core/models/product';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { NotAllowedToSubscribeError, SubscriptionsService } from '@pbox/common/core/services/subscriptions.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject, catchError, map, MonoTypeOperatorFunction, Observable, of, shareReplay, throwError } from 'rxjs';

/** UI service containing subscription logic for a product-related components. */
@Injectable()
export class ProductSubscriptionService {

  /** Whether the subscribe to the product is in process or not. */
  public readonly isSubscribing$: Observable<boolean>;

  private readonly isSubscribingSubject = new BehaviorSubject(false);

  public constructor(
    private readonly subscriptionsService: SubscriptionsService,
    private readonly notificationService: NotificationService,
    private readonly storesService: StoresService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
  ) {
    this.isSubscribing$ = this.isSubscribingSubject.asObservable();
  }

  /**
   * Handles subscription to the product.
   * @param product Product.
   */
  public subscribeToProduct(product: Product): Observable<void> {
    return this.subscriptionsService.subscribeToProduct(product).pipe(
      toggleExecutionState(this.isSubscribingSubject),
      this.catchSubscriptionError(),
    );
  }

  /**
   * Checks whether the user is subscribed to the product or not.
   * @param product Product.
   */
  public checkIfSubscribedToProduct(product: Product | null): Observable<boolean> {
    if (product == null) {
      return of(false);
    }

    return this.subscriptionsService.checkIfSubscribedToProduct(product).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Checks whether it is allowed to subscribe for a product.
   * @param product Product.
   */
  public checkCanSubscribeToProduct(product: Product): Observable<boolean> {
    return this.storesService.areSubscriptionsEnabled$.pipe(
      map(areSubscriptionsEnabled => areSubscriptionsEnabled && product.isSubscribable),
    );
  }

  /**
   * Obtains base price for the product.
   * @param product Product.
   */
  public getBasePrice(product: Product | null): Observable<number | null> {
    if (product == null) {
      return of(null);
    }

    return this.subscriptionsService.getProductBasePrice(product).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /**
   * Obtains subscription price for the product.
   * @param product Product.
   */
  public getSubscriptionPrice(product: Product | null): Observable<number | null> {
    if (product == null || product.discount === 0) {
      return of(null);
    }

    return this.subscriptionsService.getProductSubscriptionPrice(product).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private catchSubscriptionError(): MonoTypeOperatorFunction<void> {
    return catchError((error: unknown) => {
      if (error instanceof NotAllowedToSubscribeError || error instanceof AppError) {
        this.notificationService.notify(error.message);
        this.navigateToProfile();
      }
      return throwError(() => error);
    });
  }

  private navigateToProfile(): void {
    // Close all dialog because it's complex to get dialogRef from product-quantity, and when navigate, we need to close all dialog too.
    this.dialog.closeAll();
    this.router.navigate(['/user']);
  }
}
