import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NonNullableFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OrderItem } from '@pbox/common/core/models/order-item';
import { Product } from '@pbox/common/core/models/product';
import { CartService } from '@pbox/common/core/services/cart.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { assertNonNull } from '@pbox/common/core/utils/assert-non-null';
import { controlDisabledState } from '@pbox/common/core/utils/rxjs/control-disabled-state';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { QuantityControlBase } from '@pbox/common/shared/components/quantity-control/quantity-control-base.directive';
import { combineLatest, ignoreElements, map, merge, NEVER, switchMap, tap } from 'rxjs';

/** Cart item quantity. */
@UntilDestroy()
@Component({
  selector: 'pboxc-cart-item-quantity',
  templateUrl: './cart-item-quantity.component.html',
  styleUrls: ['./cart-item-quantity.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartItemQuantityComponent extends QuantityControlBase implements OnInit {

  /** Cart item. */
  @Input()
  public item: OrderItem | null = null;

  /** Get addable amount based on remaining amount and maximum amount. */
  protected getAddableAmount = Product.getAddableAmount;

  /** @inheritdoc */
  protected override get product(): Product | null {
    return this.item?.product ?? null;
  }

  public constructor(
    fb: NonNullableFormBuilder,
    cartService: CartService,
    notificationService: NotificationService,
    changeDetectorRef: ChangeDetectorRef,
    router: Router,
    dialog: MatDialog,
  ) {
    super(cartService, changeDetectorRef, fb, notificationService, router, dialog);

  }

  /** @inheritdoc */
  public ngOnInit(): void {
    const quantityControlFillSideEffect$ = this.item != null ? this.initQuantityControlFillSideEffect(this.item.product) : NEVER;

    const quantityChangeSideEffect$ = this.quantityControl.valueChanges.pipe(
      switchMap(quantity => {
        assertNonNull(this.item);

        return this.cartService
          .editItem({ ...this.item, quantity })
          .pipe(
            toggleExecutionState(this.isLoading$),
            this.catchCartItemUpdateError(),
          );
      }),
      ignoreElements(),
    );

    const isControlDisabled$ = combineLatest([
      this.isLoading$,
      this.quantityControl.valueChanges,
    ]).pipe(map(([isLoading, quantity]) => isLoading || quantity === 0));

    const disableControlBaseOnCartSideEffect$ = this.cartService.isCartPaid$.pipe(
      tap(isPaid => {
        if (isPaid) {
          this.quantityControl.disable({ emitEvent: false });
        }
      }),
    );

    merge(
      quantityControlFillSideEffect$,
      quantityChangeSideEffect$,
      controlDisabledState(this.quantityControl, isControlDisabled$),
      disableControlBaseOnCartSideEffect$,
    )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  /** Handles item removal. */
  protected onItemDelete(): void {
    this.quantityControl.setValue(0);
  }
}
