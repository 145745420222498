import { Injectable } from '@angular/core';
import { UserService } from '@pbox/common/core/services/user.service';
import { filterNull } from '@pbox/common/core/utils/rxjs/filter-null';
import { map, Observable, switchMap } from 'rxjs';

import { StoreTextsService } from '../../ui-services/store-texts.service';

/** UI service for the welcome layout. */
@Injectable()
export class WelcomeLayoutService {
  /** Page title in header. */
  public readonly title$: Observable<string>;

  public constructor(
    private readonly userService: UserService,
    storeTextsService: StoreTextsService,
  ) {
    this.title$ = storeTextsService.text$.pipe(
      switchMap(text => this.userService.currentUser$.pipe(
        filterNull(),
        map(user => {
          if (user.isFirstTime) {
            return text.welcome.title;
          }

          // According to this task: https://saritasa.atlassian.net/browse/PBDEV-364
          // When a member user return to this page again, that mean they don't have any orders
          const pageTitle = `Welcome back ${user.firstName}!\nYou do not have an order for this week`;
          return pageTitle;
        }),
      )),
    ) ;
  }

}
