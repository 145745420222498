import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CanceledMembershipDialogComponent } from '../canceled-membership-dialog/canceled-membership-dialog.component';

/** Canceled membership subscribe to product dialog. */
@UntilDestroy()
@Component({
  selector: 'pboxc-canceled-membership-subscribe-dialog',
  templateUrl: './canceled-membership-subscribe-dialog.component.html',
  styleUrls: ['./canceled-membership-subscribe-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CanceledMembershipDialogComponent, RouterModule],
})
export class CanceledMembershipSubscribeDialogComponent {

  public constructor(
    private dialogRef: MatDialogRef<CanceledMembershipSubscribeDialogComponent>,
  ) { }

  /** Handle subscribe click. */
  public onSubscribeButtonClick(): void {
    this.dialogRef.close(true);
  }
}
