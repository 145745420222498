import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@pbox/common/core/models/order';
import { AppConfig } from '@pbox/common/core/services/app.config';

/** Presents order sub-totals. */
@Component({
  selector: 'pboxc-order-sub-totals',
  templateUrl: './order-sub-totals.component.html',
  styleUrls: ['./order-sub-totals.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSubTotalsComponent {

  /** Order info. */
  @Input()
  public order: Order | null = null;

  public constructor(
    protected readonly appConfig: AppConfig,
  ) { }

  /**
   * Get order's price.
   * @param order Order.
   */
  protected getPrice(order: Order | null): Order['price'] | null {
    if (order == null) {
      return null;
    }
    return order.price;
  }

  /**
   * Whether price should be showed.
   * @param price Price.
   */
  protected shouldShowPrice(price: number): boolean {
    return price > 0;
  }

}
