<ul class="card-brand-list">
  <li
    *ngFor="let cardBrand of cardBrands; trackBy: trackCardBrand"
    class="card-brand-list__item"
  >
    <pboxc-payment-card-brand
      [cardBrand]="cardBrand"
    ></pboxc-payment-card-brand>
  </li>
  <li>and more...</li>
</ul>
