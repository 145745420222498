<address class="address" *ngIf="contactInfo; else skeleton">
  <a class="address__line" href="tel:{{ contactInfo.phone }}">{{
    contactInfo.phone | pboxcPhone
  }}</a>
  <a class="address__line" href="mailto:{{ contactInfo.email }}">{{
    contactInfo.email
  }}</a>
  <span class="address__line">{{ contactInfo.address }}</span>
</address>

<ng-template #skeleton>
  <address class="address" aria-hidden>
    <span
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="10"
      [pboxcSkeletonMaxWidth]="12"
    ></span>
    <span
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="15"
      [pboxcSkeletonMaxWidth]="20"
    ></span>
    <span
      pboxcSkeleton
      [pboxcSkeletonMinWidth]="9"
      [pboxcSkeletonMaxWidth]="12"
    ></span>
  </address>
</ng-template>
