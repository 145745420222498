import { ChangeDetectionStrategy, Component } from '@angular/core';
import { WelcomeLayoutService } from '@pbox/common/shared/layouts/welcome-layout/welcome-layout.service';

/** Layout for the welcome pages. */
@Component({
  selector: 'pboxw-welcome-layout',
  templateUrl: './welcome-layout.component.html',
  styleUrls: ['./welcome-layout.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeLayoutComponent {

  public constructor(
    protected readonly welcomeLayoutService: WelcomeLayoutService,
  ) { }
}
