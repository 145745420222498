import { Injectable } from '@angular/core';
import { PopularProduct } from '@pbox/common/shared/features/welcome/first-time/first-time.base';

import { Product, ProductWithDetails } from '../../models/product';
import type { ProductSearchOptions } from '../products.service';

import { PopularProductDto, ProductDto, ProductWithDetailsDto } from './dto/product.dto';
import { SearchOptionsDto } from './dto/search-options.dto';
import { IMapperFromDto } from './mappers';
import { SearchOptionsMapper } from './search-options.mapper';
import { StockKeepingUnitMapper } from './stock-keeping-unit.mapper';
import { VendorMapper } from './vendor.mapper';

/** Product mapper. */
@Injectable({
  providedIn: 'root',
})
export class ProductMapper implements IMapperFromDto<ProductDto, Product> {

  public constructor(
    private readonly searchOptionsMapper: SearchOptionsMapper,
    private readonly stockKeepingUnitMapper: StockKeepingUnitMapper,
    private readonly vendorMapper: VendorMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: ProductDto): Product {
    return {
      id: dto.product_id,
      name: dto.product_name,
      shortName: dto.product_short_name,
      description: dto.description,
      shortDescription: dto.short_description,
      imageUrl: dto.image_url,
      basePrice: dto.base_price,
      guestPrice: dto.guest_price,
      discount: dto.discount,
      remainingAmount: dto.qty_remaining ?? 0,
      type: dto.product_type_text,
      isSubscribable: dto.can_subscribe,
      isComposable: ['box', 'bundle'].includes(dto.product_type_text),
      isSnap: dto.is_snap,
      maximumAddableAmount: dto.max_can_order,
      isSoldOut: dto.is_sold_out,
      isHotDeal: dto.is_hotdeal,
      stockKeepingUnits: dto.product_skus ? dto.product_skus.map(skuDto => this.stockKeepingUnitMapper.shortFromDto(skuDto)) : [],
    };
  }

  /**
   * Maps product with details from dto.
   * @param dto Dto.
   */
  public productWithDetailsFromDto(dto: ProductWithDetailsDto): ProductWithDetails {
    return {
      ...this.fromDto(dto),
      stockKeepingUnits: dto.product_skus.map(skuDto => this.stockKeepingUnitMapper.fromDto(skuDto)),
      vendors: dto.vendors.map(vendorDto => this.vendorMapper.fromDto(vendorDto)),
    };
  }

  /**
   * Maps popular product from dto.
   * @param dto Dto.
   */
  public popularProductFromDto(dto: PopularProductDto): PopularProduct {
    return {
      ...this.fromDto(dto),
      isMostPopular: dto.is_most_popular,
    };
  }

  /**
   * Maps search options to DTO.
   * @param options Search options.
   */
  public mapSearchOptionsToDto(options: ProductSearchOptions): SearchOptionsDto.Any {
    return {
      ...this.searchOptionsMapper.mapPaginationOptionsToDto(options),
      keyword: options.searchString,
      category_ids: options.categories != null ? options.categories.toString() : [],
      tag_ids: options.tags != null ? options.tags.toString() : [],
      store_id: options.store != null ? options.store : '',
    };
  }
}
