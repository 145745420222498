/**
 * Abstract app config object. Provides information about current application environment configuration.
 */
export abstract class AppConfig {
  // This must NOT have any environment-provided properties declarations, since environments are application-specific.
  // Please use application-specific implementations for this (e.g. web-app-config.service.ts)!

  /** API base URL. */
  public abstract readonly apiUrl: string;

  /** App version. */
  public abstract readonly version: string;

  /** Environment key required to work with spreedly public API. */
  public abstract readonly spreedlyToken: string;

  /** Link to support website. */
  public abstract readonly memberSolutionsLink: string;

  /** App tenant. */
  public abstract readonly appTenant: string | undefined;

  /** FreshChat access token. */
  public abstract readonly freshChatToken: string;

  /** FreshChat host. */
  public abstract readonly freshChatHost: string;

  /** Whether SNAP feature is turned on. */
  public abstract readonly hasSnap: boolean;
}
