import { Injectable } from '@angular/core';
import { FormGroup, NonNullableFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateOrderData } from '@pbox/common/core/models/order';
import { PaymentMethodType } from '@pbox/common/core/models/payment-method-type';
import { BalanceService } from '@pbox/common/core/services/balance.service';
import { CardPaymentMethodsService } from '@pbox/common/core/services/card-payment-methods.service';
import { CartService } from '@pbox/common/core/services/cart.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { StorageService } from '@pbox/common/core/services/storage.service';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY } from '@pbox/common/core/services/user-secret-storage.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { assertNonNullablePropertiesWithReturn } from '@pbox/common/core/utils/assert-non-null';
import { listenControlChanges } from '@pbox/common/core/utils/rxjs/listen-control-changes';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { FlatControlsOf } from '@pbox/common/core/utils/types/controls-of';
import { BehaviorSubject, combineLatest, EMPTY, first, ignoreElements, map, Observable, switchMap } from 'rxjs';

import { CanceledMembershipNotificationDialogComponent } from './canceled-membership-notification-dialog/canceled-membership-notification-dialog.component';
import { OrderPageService } from './order-page.service';

import { OrderStrategyFactoryService } from './order-payment-factory.service';

export type CheckoutData = Pick<CreateOrderData, 'paymentMethodType' | 'tipAmount'>;
export type CheckoutForm = FlatControlsOf<CheckoutData>;

/** Handles checkout logic on checkout pages. */
@UntilDestroy()
@Injectable()
export class CheckoutPageService {
  private readonly isLoadingSubject = new BehaviorSubject<boolean>(false);

  /** Whether the checkout form is being submitted. */
  public readonly isLoading$ = this.isLoadingSubject.asObservable();

  /** Checkout form. */
  public readonly checkoutForm: FormGroup<CheckoutForm>;

  /** Whether a payment needs a card. */
  public readonly isPaymentWithCard$: Observable<boolean>;

  /** Whether checkout button is disabled. */
  public readonly isCheckoutDisabled$: Observable<boolean>;

  public constructor(
    private readonly fb: NonNullableFormBuilder,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly cartService: CartService,
    private readonly orderStrategyFactory: OrderStrategyFactoryService,
    private readonly storesService: StoresService,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly storageService: StorageService,
    orderPageService: OrderPageService,
    balanceService: BalanceService,
    cardPaymentMethodsService: CardPaymentMethodsService,
  ) {
    this.checkoutForm = this.fb.group<CheckoutForm>({
      paymentMethodType: fb.control(PaymentMethodType.Card, Validators.required),
      tipAmount: fb.control(0, Validators.min(0)),
    });

    const checkoutFormValue$ = listenControlChanges<CheckoutData>(this.checkoutForm);

    this.isPaymentWithCard$ = combineLatest({
      cart: orderPageService.cart$,
      checkoutFormValue: checkoutFormValue$,
      balance: balanceService.balance$,
    }).pipe(
      map(({ cart, checkoutFormValue, balance }) => {
        const isBalanceEnough = balance >= (checkoutFormValue.tipAmount || 0) + cart.price.creditAppliedDiscount;
        if (cart.price.total === 0 && isBalanceEnough) {
          return false;
        }
        return true;
      }),
    );

    this.isCheckoutDisabled$ = combineLatest({
      isPaymentWithCard: this.isPaymentWithCard$,
      checkoutFormValue: checkoutFormValue$,
      activeCardPaymentMethod: cardPaymentMethodsService.activeCardPaymentMethod$,
    }).pipe(
      map(({
        isPaymentWithCard,
        checkoutFormValue,
        activeCardPaymentMethod,
      }) => isPaymentWithCard && checkoutFormValue.paymentMethodType === PaymentMethodType.Card && activeCardPaymentMethod == null),
    );
  }

  /** Handles checkout submission. */
  public onCheckoutSubmit(): void {
    this.checkoutForm.markAllAsTouched();
    if (this.checkoutForm.invalid) {
      return;
    }
    const checkoutData = this.checkoutForm.getRawValue();
    const nonNullableValue = assertNonNullablePropertiesWithReturn(
      checkoutData,
      'tipAmount',
    );

    const cartId$ = this.cartService.cart$.pipe(
      first(),
      map(({ id }) => id),
    );

    const storeId$ = this.storesService.currentStore$.pipe(
      map(store => store.id),
    );

    const strategy = this.orderStrategyFactory.createFor(checkoutData.paymentMethodType);

    combineLatest([cartId$, storeId$]).pipe(
      switchMap(([cartId, storeId]) => strategy.createOrder({
        ...nonNullableValue,
        cartId,
        storeId,
      })),
      toggleExecutionState(this.isLoadingSubject),
      this.notificationService.notifyOnAppError(),
      untilDestroyed(this),
    )
      .subscribe(orderId => this.router.navigateByUrl(`/order/details/${orderId}`));
  }

  /** Show notification for canceled membership. */
  public notifyCanceledUserSideEffect(): Observable<void> {
    return this.userService.checkShowCanceledUserDialog().pipe(
      switchMap(shouldShow => {
        if (shouldShow) {
          this.dialog.open(CanceledMembershipNotificationDialogComponent);
          return this.storageService.save<boolean>(HAS_READ_CANCELED_MEMBERSHIP_NOTIFICATION_KEY, true);
        }
        return EMPTY;
      }),
      ignoreElements(),
    );
  }

}
