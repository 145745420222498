import { Injectable } from '@angular/core';

import { ShortStockKeepingUnit, StockKeepingUnit } from '../../models/stock-keeping-unit';

import { ShortStockKeepingUnitDto, StockKeepingUnitDto } from './dto/stock-keeping-unit.dto';
import { IMapperFromDto } from './mappers';

/** Stock keeping unit mapper. */
@Injectable({
  providedIn: 'root',
})
export class StockKeepingUnitMapper implements IMapperFromDto<StockKeepingUnitDto, StockKeepingUnit> {

  /**
   * Maps short stock keeping unit from DTO.
   * @param dto DTO.
   */
  public shortFromDto(dto: ShortStockKeepingUnitDto): ShortStockKeepingUnit {
    const { sku } = dto;

    return {
      id: sku.sku_id,
      productId: dto.product_id,
      name: sku.sku,
      imageUrl: sku.image_url,
      quantity: dto.quantity,
      measurement: {
        unit: sku.unit_of_measure.name,
        abbreviation: sku.unit_of_measure.abbreviation,
        quantity: sku.unit_of_measure_qty,
      },
    };
  }

  /** @inheritdoc */
  public fromDto(dto: StockKeepingUnitDto): StockKeepingUnit {
    const { sku } = dto;

    return {
      ...this.shortFromDto(dto),
      description: sku.description,
      shortDescription: sku.short_description,
    };
  }
}
