import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Address } from '@pbox/common/core/models/address';
import { controlProviderFor, SimpleValueAccessor } from '@pbox/common/core/utils/value-accessor';
import { fromEvent, ignoreElements, Observable, tap } from 'rxjs';

export type GoogleMapPlaces = Omit<Address, 'suite'> & google.maps.places.PlaceResult;

/** Coordinates. */
interface Coordinates {

  /** Latitude. */
  readonly latitude: number;

  /** Longitude. */
  readonly longitude: number;
}

/** Google places autocomplete component. */
@UntilDestroy()
@Component({
  selector: 'pboxc-google-places-autocomplete',
  templateUrl: './google-places-autocomplete.component.html',
  styleUrls: ['./google-places-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => GooglePlacesAutocompleteComponent)],
})
export class GooglePlacesAutocompleteComponent extends SimpleValueAccessor<GoogleMapPlaces> implements OnInit, AfterViewInit, OnDestroy {

  /** Control ref. */
  @ViewChild('googlePlaceControl', { static: true })
  public readonly googlePlaceControl!: ElementRef<HTMLInputElement>;

  /** Google map event listener. */
  public googleMapEventListener: google.maps.MapsEventListener | null = null;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.setAddressWithoutSelectingPlacesSideEffect().pipe(
      untilDestroyed(this),
    )
      .subscribe();
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.initGooglePlacesAutocomplete();
  }

  /** @inheritdoc */
  public ngOnDestroy(): void {
    if (this.googleMapEventListener) {
      google.maps.event.removeListener(this.googleMapEventListener);
    }
  }

  /** Binding google places api to input after it is rendered. */
  private initGooglePlacesAutocomplete(): void {

    // Use North Carolina as center (https://saritasa.atlassian.net/browse/PBDEV-593)
    const northCarolinaCoordinates: Coordinates = { latitude: 35.782169, longitude: -80.793457 };
    const variance = 0.1;

    const bounds: google.maps.LatLngBoundsLiteral = {
      north: northCarolinaCoordinates.latitude + variance,
      south: northCarolinaCoordinates.latitude + variance,
      east: northCarolinaCoordinates.longitude + variance,
      west: northCarolinaCoordinates.longitude + variance,
    };

    const autocomplete = new google.maps.places.Autocomplete(
      this.googlePlaceControl.nativeElement,
      {
        types: ['geocode'],
        fields: ['address_components', 'formatted_address'],
        bounds,
      },
    );
    this.googleMapEventListener = google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.address_components) {
        const streetNumber = this.parseGeocoderAddressComponent(place.address_components, 'street_number') ?? '';
        const route = this.parseGeocoderAddressComponent(place.address_components, 'route') ?? '';
        const city = this.parseGeocoderAddressComponent(place.address_components, 'locality');
        const stateName = this.parseGeocoderAddressComponent(place.address_components, 'administrative_area_level_1');
        const zipCode = this.parseGeocoderAddressComponent(place.address_components, 'postal_code');

        const addressLine = `${streetNumber} ${route}`;

        // Because we can only receive state name from google places API
        // And our state is an object, not a string. So we need to create an placeholder state object
        // Then we will use stateName to find a state that has name match with stateName at address.component.ts
        const placeholderState = {
          abbreviation: '',
          id: 0,
          name: stateName,
        };

        // googlePlaceControl doesn't use this.controlValue.addressLine although our code is run and bind to googlePlaceControl
        // So I set it manually if controlValue.addressLine equal to addressLine which calculated above.
        // https://saritasa.atlassian.net/browse/PBDEV-703?focusedCommentId=262483
        if (this.controlValue?.addressLine === addressLine) {
          this.googlePlaceControl.nativeElement.value = addressLine;
        }

        this.controlValue = {
          ...this.controlValue,
          ...place,
          city,
          state: placeholderState,
          zipCode,
          addressLine,
        };

        this.handleShowFormattedAddress();
      }
    });
  }

  private setAddressWithoutSelectingPlacesSideEffect(): Observable<void> {
    return fromEvent(this.googlePlaceControl.nativeElement, 'change').pipe(
      tap(event => {
        if (this.controlValue) {
          this.controlValue = {
            ...this.controlValue,
            addressLine: (event.target as HTMLInputElement).value,
          };
        }
      }),
      ignoreElements(),
    );
  }

  /**
   * Helper parse Geocoder Result by field name.
   * @param addressComponents Geocoder Address Component Object.
   * @param fieldName String field name.
   * @param isShortName Check whether get short name value or not, default is 'long_name'.
   */
  private parseGeocoderAddressComponent(
    addressComponents: google.maps.GeocoderAddressComponent[],
    fieldName: string,
    isShortName = false,
  ): string {
    for (let componentIndex = 0; componentIndex < addressComponents.length; componentIndex++) {
      for (let typeIndex = 0; typeIndex < addressComponents[componentIndex].types.length; typeIndex++) {
        if (addressComponents[componentIndex].types[typeIndex] === fieldName) {
          return isShortName ? addressComponents[componentIndex].short_name : addressComponents[componentIndex].long_name;
        }
      }
    }
    return '';
  }

  private handleShowFormattedAddress(): void {
    // TODO (Tien Luu): Find better approach to get formatted address and binding state to state component
    // instead of focusing and blurring
    this.googlePlaceControl.nativeElement.focus();
    this.googlePlaceControl.nativeElement.blur();
  }

}
