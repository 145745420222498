import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CartService } from '@pbox/common/core/services/cart.service';
import { Observable } from 'rxjs';

/** Badge displaying amount of items in the cart. */
@Component({
  selector: 'pboxc-cart-items-amount-badge',
  templateUrl: './cart-items-amount-badge.component.html',
  styleUrls: ['./cart-items-amount-badge.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CartItemsAmountBadgeComponent {

  /** Items amount. */
  protected readonly amount$: Observable<number>;

  public constructor(
    cartService: CartService,
  ) {
    this.amount$ = cartService.cartItemsAmount$;
  }
}
