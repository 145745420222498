<pboxc-card class="card">
  <ul
    class="payment-methods-list"
    [ngSwitch]="(orderPaymentMethodSubject | async)?.type"
  >
    <li class="payment-methods-list__item payment-methods-list__item_text">
      <span class="payment-methods-list__label"> Account Balance </span>
      <span class="payment-methods-list__amount">
        {{ balanceService.balance$ | async | currency }}
      </span>
    </li>

    <pboxc-card-payment-method
      *ngSwitchCase="paymentMethodType.Card"
      [cardPaymentMethod]="card$ | async"
      class="payment-methods-list__item"
    ></pboxc-card-payment-method>

    <ng-container *ngSwitchCase="paymentMethodType.EBT">
      <ng-container *ngIf="orderPaymentMethodSubject | async as ebtPayment">
        <ng-container
          *ngIf="isEbtPaymentMethod(ebtPayment) && appConfig.hasSnap"
        >
          <li
            class="payment-methods-list__item payment-methods-list__item_text"
            *ngIf="ebtPayment.snapAmount > 0"
          >
            <span class="payment-methods-list__label"> SNAP </span>
            <span class="payment-methods-list__amount">
              <span> SNAP Balance {{ ebtPayment.snapCardNumber }}: </span>
              <span>{{ ebtPayment.snapBalance | currency }}</span>
            </span>
          </li>
          <li
            class="payment-methods-list__item payment-methods-list__item_text"
            *ngIf="ebtPayment.ebtAmount > 0"
          >
            <span class="payment-methods-list__label"> EBT </span>
            <span class="payment-methods-list__amount">
              <span> EBT Balance: </span>
              <span>{{ ebtPayment.ebtBalance | currency }}</span>
            </span>
          </li>
          <li
            class="payment-methods-list__item payment-methods-list__credit-card"
            *ngIf="ebtPayment.creditCardAmount > 0"
          >
            <pboxc-payment-card-brand
              class="payment-methods-list__label payment-methods-list__card-brand"
              [cardBrand]="ebtPayment.creditCardBrand"
            >
            </pboxc-payment-card-brand>
            <span>
              <span> ending in: </span>
              <strong>{{ ebtPayment.creditCardNumber }}</strong>
            </span>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="paymentMethodType.Balance"></ng-container>

    <pboxc-card-payment-method
      class="payment-methods-list__item"
      *ngSwitchDefault
    ></pboxc-card-payment-method>
  </ul>
</pboxc-card>
