import { Injectable } from '@angular/core';

import { AddressEditData } from '../../models/address';
import { EntityValidationErrors } from '../../models/app-error';

import { Location, LocationType } from '../../models/location';
import { invertDictionary } from '../../utils/invert-dictionary';

import { AddressEditDto } from './dto/address.dto';

import { LocationDto } from './dto/location.dto';
import { ValidationErrorDto } from './dto/validation-error.dto';
import { extractErrorMessage } from './extract-error-message';

import { IMapperFromDto, IValidationErrorMapper } from './mappers';

const LOCATION_TYPE_MAP_FROM_DTO: Readonly<
  Record<LocationDto['type'], LocationType>
> = {
  1: LocationType.Neighborhood,
  2: LocationType.Corporate,
};

/** Location mapper. */
@Injectable({
  providedIn: 'root',
})
export class LocationMapper implements IMapperFromDto<LocationDto, Location>, IValidationErrorMapper<AddressEditDto, AddressEditData> {

  /** @inheritdoc */
  public validationErrorFromDto(errorDto: ValidationErrorDto<AddressEditDto>): EntityValidationErrors<AddressEditData> {
    return {
      address: {
        zipCode: extractErrorMessage(errorDto.zip_code),
      },
    };
  }

  /** @inheritdoc */
  public fromDto(dto: LocationDto): Location {
    return {
      id: dto.location_id,
      name: dto.name,
      zipCode: dto.zip_code,
      type: LOCATION_TYPE_MAP_FROM_DTO[dto.type],
    };
  }

  /**
   * Map location type to dto.
   * @param type Location type.
   */
  public mapTypeToDto(type: Location['type']): LocationDto['type'] {
    const TO_DTO_MAP = invertDictionary(LOCATION_TYPE_MAP_FROM_DTO);
    return TO_DTO_MAP[type];
  }
}
