import { Injectable } from '@angular/core';

import { ProductSubscription, ProductSubscriptionEditData } from '../../models/product-subscription';

import { DeliveryMapper } from './delivery.mapper';
import { ProductSubscriptionDto, ProductSubscriptionEditDto } from './dto/product-subscription.dto';
import { IMapperFromDto } from './mappers';
import { ProductMapper } from './product.mapper';
import { ScheduleTypeMapper } from './schedule-type.mapper';

/** Subscription mapper. */
@Injectable({
  providedIn: 'root',
})
export class ProductSubscriptionMapper implements IMapperFromDto<ProductSubscriptionDto, ProductSubscription> {

  public constructor(
    private readonly productMapper: ProductMapper,
    private readonly deliveryMapper: DeliveryMapper,
    private readonly scheduleTypeMapper: ScheduleTypeMapper,
  ) { }

  /** @inheritdoc */
  public fromDto(dto: ProductSubscriptionDto): ProductSubscription {
    return {
      id: dto.subscription_id,
      scheduleType: this.scheduleTypeMapper.fromDto(dto.user_skip_type),
      product: this.productMapper.fromDto(dto.product),
      delivery: dto.delivery != null ? this.deliveryMapper.mapShortDeliveryFromDto(dto.delivery) : null,
    };
  }

  /**
   * Maps edit data to dto.
   * @param data Data.
   */
  public mapEditDataToDto(data: ProductSubscriptionEditData): ProductSubscriptionEditDto {
    return {
      user_skip_type_id: data.scheduleType.id,
      start_week: data.delivery?.key ?? null,
    };
  }
}
