import { TrackByFunction } from '@angular/core';
import { ToReadableFunction, ComparatorFunction } from '@pbox/common/core/models/autocomplete-configuration';
import { map, Observable, ReplaySubject, shareReplay, switchMap } from 'rxjs';

/** Configuration for select components. */
export interface SelectConfiguration<TItem> {

  /** Function that obtains a list of items for select. */
  readonly get: () => Observable<readonly TItem[]>;

  /** Function that makes option human-readable. */
  readonly toReadable: ToReadableFunction<TItem>;

  /**
   * Track by function.
   * If not provided, select items are going to be tracked by reference.
   */
  readonly trackBy?: TrackByFunction<TItem>;

  /**
   * Comparator function.
   * If not provided, select items are going to be compared by results of the `toReadable` function.
   */
  readonly comparator?: ComparatorFunction<TItem>;
}

// eslint-disable-next-line jsdoc/require-param
/** Default trackby function that compares items by reference. */
export const defaultTrackByFunction = <T>(_: number, value: T): T => value;

/** Select controller. */
export class SelectController<T> {

  /** Items to select. */
  public readonly items$: Observable<readonly T[] | null>;

  /** Function, obtained from configuration, makes T item human-readable. */
  public readonly toReadable$: Observable<ToReadableFunction<T | null>>;

  /** Trackby function. */
  public readonly trackBy$: Observable<TrackByFunction<T>>;

  /** Comparator function. */
  public readonly comparator$: Observable<ComparatorFunction<T>>;

  /** Configuration setter. */
  public set configuration(configuration: SelectConfiguration<T>) {
    this.configurationSubject.next(configuration);
  }

  private readonly configurationSubject = new ReplaySubject<SelectConfiguration<T>>(1);

  public constructor() {
    this.items$ = this.configurationSubject.pipe(
      switchMap(configuration => configuration.get()),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );

    this.toReadable$ = this.configurationSubject.pipe(
      map(({ toReadable }) => (value: T | null) => {
        if (value != null) {
          return toReadable(value);
        }
        return '';
      }),
    );

    this.trackBy$ = this.configurationSubject.pipe(
      map(({ trackBy }) => {
        if (trackBy == null) {
          return defaultTrackByFunction;
        }

        return trackBy;
      }),
    );

    this.comparator$ = this.configurationSubject.pipe(
      map(({ toReadable, comparator }) => {
        if (comparator == null) {
          // Options are nullable if mat-select is used
          return (option1: T | null, option2: T | null) => {
            const left = option1 && toReadable(option1).toLowerCase();
            const right = option2 && toReadable(option2).toLowerCase();

            return left === right;
          };
        }

        return comparator;
      }),
    );
  }
}
