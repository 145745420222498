import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DetailedOrder, DetailedOrderItem } from '@pbox/common/core/models/order';
import { BalanceService } from '@pbox/common/core/services/balance.service';
import { NotificationService } from '@pbox/common/core/services/notification.service';
import { OrdersService } from '@pbox/common/core/services/orders.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject, first, Observable, shareReplay, switchMap, tap } from 'rxjs';

const ORDER_CANCELLED_MESSAGE = 'Order cancelled.';

/** Order details page service. */
@Injectable()
@UntilDestroy()
export class OrderDetailsPageService {
  private readonly isCancellingOrderSubject = new BehaviorSubject<boolean>(false);

  /** Whether the order is being cancelled. */
  public readonly isCancellingOrder$ = this.isCancellingOrderSubject.asObservable();

  /** Title. */
  public readonly title = 'Your Order';

  /** Order. */
  public readonly order$: Observable<DetailedOrder>;

  public constructor(
    private readonly orderService: OrdersService,
    protected readonly balanceService: BalanceService,
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    activatedRoute: ActivatedRoute,
  ) {

    const orderId = activatedRoute.snapshot.params['id'];
    this.order$ = this.orderService.getDetailedOrder(orderId).pipe(
      tap(() => this.userService.refreshUser()),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  /** Handles click on order cancel button. */
  public onOrderCancelClick(): void {
    this.order$.pipe(
      first(),
      switchMap(order => this.orderService.cancelOrder(order.id)),
      toggleExecutionState(this.isCancellingOrderSubject),
      tap(() => this.notificationService.notify(ORDER_CANCELLED_MESSAGE)),
      this.notificationService.notifyOnAppError(),
      untilDestroyed(this),
    ).subscribe(() => this.router.navigateByUrl('/order/cart'));
  }

  /**
   * Util for casting value to detailed order item on template.
   * @param item Order item.
   */
  public toDetailedOrderItem(item: unknown): DetailedOrderItem {
    return item as DetailedOrderItem;
  }
}
