import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Product } from '@pbox/common/core/models/product';

/** Card image. */
@Component({
  selector: 'pboxc-card-image',
  templateUrl: './card-image.component.html',
  styleUrls: ['./card-image.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardImageComponent {

  /** Product. */
  @Input()
  public product: Product | null = null;
}
