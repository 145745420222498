import { ShortStockKeepingUnit, StockKeepingUnit } from './stock-keeping-unit';
import { Vendor } from './vendor';

/** Product. */
export interface Product {

  /** ID. */
  readonly id: number;

  /** Name. */
  readonly name: string;

  /** Short name. */
  readonly shortName: string;

  /** Description. */
  readonly description: string;

  /** Short description. */
  readonly shortDescription: string;

  /** Image URL. */
  readonly imageUrl: string;

  /** Base price in dollars. */
  readonly basePrice: number;

  /** Guest price in dollars. */
  readonly guestPrice: number;

  /** Percentage discount. */
  readonly discount: number;

  /** Type. */
  readonly type: 'box' | 'simple' | 'bundle' | 'addon';

  /** Whether the product consists of several sub-products. */
  readonly isComposable: boolean;

  /** Whether subscription to the item is available. */
  readonly isSubscribable: boolean;

  /** ?. */
  readonly isSnap: boolean;

  /** Amount of products available in the store. */
  readonly remainingAmount: number;

  /**
   * Maximum amount of product that user can be added into the cart.
   * Because client may have limited inventory, and they want to limit each user can order some certain number of items.
   * It's very common in the US. For example, some discounted items in groceries stores, I could only order 2 of them.
   */
  readonly maximumAddableAmount: number;

  /** Whether the product is sold out. */
  readonly isSoldOut: boolean;

  /** Whether the product is a hot deal. */
  readonly isHotDeal: boolean;

  /** List of stock keeping units. */
  readonly stockKeepingUnits: readonly ShortStockKeepingUnit[];
}

/** Product with details. */
export interface ProductWithDetails extends Product {

  /** List of stock keeping units. */
  readonly stockKeepingUnits: readonly StockKeepingUnit[];

  /** List of vendors. */
  readonly vendors: readonly Vendor[];
}

export namespace Product {

  /**
   * Checks whether the passed product could be customized.
   * @param product Product.
   */
  export function isCustomizable(product: Product): boolean {
    return product.type === 'box';
  }

  /**
   * Get addable amount based on remaining amount and maximum amount.
   * @param product Product.
   */
  export function getAddableAmount(product: Product | null): number {
    if (product == null) {
      return 0;
    }
    return product.maximumAddableAmount <= product.remainingAmount ? product.maximumAddableAmount : product.remainingAmount;
  }

  /**
   * Returns measurement for product.
   * @param product Product.
   */
  export function getProductMeasurement(product: Product): ShortStockKeepingUnit {
    return product.stockKeepingUnits[0];
  }

  /**
   * Whether product is addon.
   * @param product Product.
   */
  export function isAddon(product: Product): boolean {
    return product.type === 'addon';
  }

}
