import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { StoresService } from '@pbox/common/core/services/stores.service';
import { UserService } from '@pbox/common/core/services/user.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { CartDialogComponent } from '@pbox/common/shared/components/cart-dialog/cart-dialog.component';
import { NEED_A_CREDIT_PARAM, NEED_A_CREDIT_PARAM_VALUE, SKIP_CALENDAR_PARAM, SKIP_CALENDAR_PARAM_VALUE } from '@pbox/common/shared/layouts/products-layout/products-layout.base';
import { OrderService } from '@pbox/common/shared/ui-services/order.service';
import { BehaviorSubject } from 'rxjs';

/** Header component. */
@UntilDestroy()
@Component({
  selector: 'pboxw-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {

  /** Whether cart link is showed. */
  @Input()
  public shouldShowCartLink = true;

  /** Skip calendar query param. */
  protected readonly skipCalendarQueryParam = { [SKIP_CALENDAR_PARAM]: SKIP_CALENDAR_PARAM_VALUE };

  /** Need a credit query param. */
  protected readonly needACreditQueryParam = { [NEED_A_CREDIT_PARAM]: NEED_A_CREDIT_PARAM_VALUE };

  /** Whether user is logging out. */
  protected readonly isLoading$ = new BehaviorSubject<boolean>(false);

  public constructor(
    private readonly dialog: MatDialog,
    private readonly orderService: OrderService,
    protected readonly userService: UserService,
    protected readonly storesService: StoresService,
  ) { }

  /** Logout. */
  protected onLogoutClick(): void {
    this.userService.logout()
      .pipe(
        toggleExecutionState(this.isLoading$),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /** Open cart dialog. */
  protected onCartClick(): void {
    if (this.orderService.canOpenCartDialog()) {
      this.dialog.open(CartDialogComponent, {
        panelClass: 'cart-dialog-container',
        position: { right: '0' },
        hasBackdrop: false,
      });
    }
  }
}
