import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { WINDOW } from '../injection-tokens/window-token';
import { enumToArray } from '../utils/enum-to-array';

import { AppConfig } from './app.config';

/** All existing tenants within the app. */
export enum Tenant {
  Default = 'default',
  TheProduceBox = 'tpb',
  Suppermeals = 'suppermeals',
  CarolinaFlavors = 'carolinaflavors',
  NCStateFarmersMarket = 'ncfarmmarket',
  TPBWholesale = 'tpb-wholesale',
}

export namespace Tenant {

  /**
   * Type-guard for tenant.
   * @param value String to type-guard.
   */
  export function is(value: string): value is Tenant {
    return (enumToArray(Tenant) as string[]).includes(value);
  }
}

/** Provides information about tenants in the application. */
@Injectable({
  providedIn: 'root',
})
export class TenantsService {

  /** Current tenant. */
  public readonly currentTenant$: Observable<Tenant>;

  public constructor(
    @Inject(WINDOW) private readonly window: Window,
    private readonly appConfig: AppConfig,
  ) {
    this.currentTenant$ = of(this.getCurrentTenant()).pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  }

  private getCurrentTenant(): Tenant {
    if (this.appConfig.appTenant != null && Tenant.is(this.appConfig.appTenant)) {
      return this.appConfig.appTenant;
    }

    const allTenants = enumToArray(Tenant);
    const currentTenant = allTenants.find(tenant => this.window.location.hostname.includes(tenant));

    return currentTenant ?? allTenants[0];
  }
}
