import { Injectable } from '@angular/core';

import { AddCardBillingInformationData, CardBillingInformation, CardBillingInformationUpdateData } from '../../models/billing-information';
import { CardBrand } from '../../models/card-brand';

import { CreateCardPaymentMethodDto, CardPaymentMethodDto, CardPaymentMethodUpdateDto } from './dto/card-payment-method.dto';
import { IMapperFromDto } from './mappers';

/** Mapper for card payment method. */
@Injectable({ providedIn: 'root' })
export class CardPaymentMethodMapper implements IMapperFromDto<CardPaymentMethodDto, CardBillingInformation> {

  /** @inheritdoc */
  public fromDto(dto: CardPaymentMethodDto): CardBillingInformation {
    const [expirationMonth, expirationYear] = dto.card_month_year.split('/');

    return {
      id: dto.credit_card_id,
      zipCode: '-',
      fullName: dto.card_name,
      cardNickname: dto.card_nickname ?? 'Unnamed card',
      isActive: dto.is_active,
      card: {
        brand: CardBrand.Visa,
        expirationMonth,
        expirationYear,
        lastFourDigits: dto.card_number.slice(-4),
      },
    };
  }

  /**
   * Maps update data to dto.
   * @param data Data.
   */
  public mapUpdateDataToDto(data: CardBillingInformationUpdateData): CardPaymentMethodUpdateDto {
    return { nickname: data.cardNickname };
  }

  /**
   * Maps add card data to dto.
   * @param data Data required to add payment card.
   */
  public mapAddDataToDto(data: AddCardBillingInformationData): CreateCardPaymentMethodDto {
    return {
      credit_card_token: data.credentialsSecret,
      nickname: data.cardName,
    };
  }
}
