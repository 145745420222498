import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';

import { AppError, AppValidationError } from '../../models/app-error';
import { catchHttpErrorResponse } from '../../utils/rxjs/catch-http-error-response';

import { ApiError } from './dto/validation-error.dto';

import { IValidationErrorMapper } from './mappers';

/**
 * Could be a simple function that transform errors from DTO to domain-level errors
 * or an implementation of `IMapper` with implemented `validationErrorFromDto` method.
 */
export type ErrorMapper<TDto, TEntity extends object> =
  | IValidationErrorMapper<TDto, TEntity>
  | IValidationErrorMapper<TDto, TEntity>['validationErrorFromDto'];

/**
 * Errors mapper.
 */
@Injectable({ providedIn: 'root' })
export class AppErrorMapper {
  /**
   * Maps `HttpErrorResponse` to an application-level error.
   * @param httpError Http error response.
   */
  private fromDto(httpError: HttpErrorResponse): AppError {
    const { statusText, error } = httpError;
    return new AppError(error?.message ?? statusText);
  }

  /**
   * Maps `HttpErrorResponse` to either `AppError` or `AppValidationError`.
   * @param httpError Http error.
   * @param mapper Mapper for backend-provided validation data into domain validation data.
   */
  private fromDtoWithValidationSupport<TDto, TEntity extends object>(
    httpError: HttpErrorResponse,
    mapper: ErrorMapper<TDto, TEntity>,
  ): AppError | AppValidationError<TEntity> {

    if ([HttpStatusCode.NotFound, HttpStatusCode.BadRequest, HttpStatusCode.UnprocessableEntity].includes(httpError.status) === false) {
      return this.fromDto(httpError);
    }

    // Because BE team can't send errors object, so we need to create an errors object here when there is an error message
    const { error }: { readonly error: ApiError<TDto> | undefined; } = httpError;
    if (error?.message) {
      const errors = httpError.error.errors ?? {};
      httpError.error.errors = {
        ...errors,
        non_field_errors: [httpError.error.message],
      };
    }

    if (error?.errors == null) {
      return this.fromDto(httpError);
    }

    const validationData =
      typeof mapper === 'function' ?
        mapper(error.errors) :
        mapper.validationErrorFromDto(error.errors);
    return new AppValidationError<TEntity>(error.message, validationData);
  }

  /**
   * RxJS operator that catches `HttpErrorResponse` and maps it into application error.
   */
  public catchHttpErrorToAppError<T>(): MonoTypeOperatorFunction<T> {
    return catchHttpErrorResponse(error => {
      const appError = this.fromDto(error);
      return throwError(() => appError);
    });
  }

  /**
   * RxJS operator that catches `HttpErrorResponse` and maps it into application error that may contain validation data.
   * @param mapper Mapper for backend-provided validation data into domain validation data.
   */
  public catchHttpErrorToAppErrorWithValidationSupport<
    T,
    TDto,
    TEntity extends object,
  >(mapper: ErrorMapper<TDto, TEntity>): MonoTypeOperatorFunction<T> {
    return catchHttpErrorResponse(error => {
      const appError = this.fromDtoWithValidationSupport<TDto, TEntity>(
        error,
        mapper,
      );
      return throwError(() => appError);
    });
  }
}
