<ng-container
  *ngIf="{
    items: (orderPageService.cart$ | async)?.items
  } as info"
>
  <div
    *ngIf="info.items?.length === 0; else itemsListTemplate"
    class="no-items-message"
  >
    Cart is empty
    <a routerLink="/" class="link with-icon"> Add products </a>
  </div>

  <ng-template #itemsListTemplate>
    <pboxc-order-items-list
      class="items-list"
      [orderItems]="info?.items || null"
      [isCustomizationEnabled]="true"
    >
      <ng-template #itemTemplate let-item>
        <div class="item-footer">
          <pboxc-cart-item-price [item]="item"></pboxc-cart-item-price>
          <pboxc-cart-item-quantity [item]="item"></pboxc-cart-item-quantity>
        </div>
      </ng-template>

      <ng-template #itemSkeletonTemplate>
        <div class="item-footer">
          <pboxc-cart-item-price></pboxc-cart-item-price>
          <pboxc-cart-item-quantity></pboxc-cart-item-quantity>
        </div>
      </ng-template>
    </pboxc-order-items-list>
  </ng-template>
</ng-container>
