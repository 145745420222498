<div class="form" *ngIf="addressFormGroup" [formGroup]="addressFormGroup">
  <pboxc-label
    [required]="withRequiredMark"
    class="form__label form__label_address"
    labelText="Delivery Address"
  >
    <pboxc-google-places-autocomplete
      formControlName="googlePlaces"
      placeholder="Enter Your Delivery Address"
    ></pboxc-google-places-autocomplete>
  </pboxc-label>
  <pboxc-label class="form__label form__label_suite" labelText="Apt/Suite">
    <span class="form__label_additional" ngProjectAs="additional-label"
      >(Optional)</span
    >
    <input
      formControlName="suite"
      autocomplete="suite"
      placeholder="Apartment #"
    />
  </pboxc-label>
  <pboxc-label
    [required]="withRequiredMark"
    class="form__label form__label_city"
    labelText="City"
  >
    <input
      formControlName="city"
      autocomplete="city"
      placeholder="Enter City"
    />
  </pboxc-label>
  <pboxc-label
    [required]="withRequiredMark"
    class="form__label form__label_state"
    labelText="State"
  >
    <pboxc-select
      formControlName="state"
      placeholder="Enter State"
      [configuration]="stateAutocompleteConfig"
    >
    </pboxc-select>
  </pboxc-label>
  <pboxc-label
    [required]="withRequiredMark"
    class="form__label form__label_zip-code"
    labelText="Zip Code"
  >
    <input
      formControlName="zipCode"
      autocomplete="zip-code"
      placeholder="Enter Zip Code"
    />
  </pboxc-label>
</div>
