import { Injectable } from '@angular/core';
import { Cart } from '@pbox/common/core/models/cart';
import { AddCoupon } from '@pbox/common/core/models/coupon';
import { CartService } from '@pbox/common/core/services/cart.service';
import { toggleExecutionState } from '@pbox/common/core/utils/rxjs/toggle-execution-state';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

/** Handles base cart page logic. */
@Injectable({
  providedIn: 'root',
})
export class OrderPageService {

  private readonly isUpdatingCoupon$ = new BehaviorSubject(false);

  /** Whether the cart is being updated. */
  public readonly isLoading$: Observable<boolean>;

  /** Cart. */
  public readonly cart$: Observable<Cart>;

  /** Whether the checkout page is available or not. */
  public readonly isCheckoutAvailable$: Observable<boolean>;

  public constructor(
    private readonly cartService: CartService,
  ) {
    this.cart$ = this.cartService.cart$;
    this.isCheckoutAvailable$ = this.cartService.isCheckoutAvailable$;

    this.isLoading$ = combineLatest([this.cartService.isCartLoading$, this.isUpdatingCoupon$]).pipe(
      map(([isCartLoading, isUpdatingCoupon]) => isCartLoading || isUpdatingCoupon),
    );
  }

  /**
   * Applies coupon to cart.
   * @param coupon Coupon to apply.
   */
  public applyCoupon(coupon: AddCoupon): Observable<void> {
    return this.cartService.applyCoupon(coupon).pipe(
      toggleExecutionState(this.isUpdatingCoupon$),
    );
  }

  /** Removes coupon. */
  public removeCoupon(): Observable<void> {
    return this.cartService.removeCoupon().pipe(
      toggleExecutionState(this.isUpdatingCoupon$),
    );
  }
}
