import { ErrorHandler, Injectable } from '@angular/core';

/** Custom error handler. */
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  /** @inheritdoc */
  public handleError(error: unknown): void {
    if (error instanceof Error) {

      /**
       * A solution I got from https://stackoverflow.com/a/59073053
       * to handle the chunk load error.
       */
      const chunkFailedMessage = /Loading chunk [a-z0-9]+ failed/;
      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      }
    }
    console.error(error);
  }
}
