import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';
import { OrderService } from '@pbox/common/shared/ui-services/order.service';
import { Observable, of, switchMap } from 'rxjs';

import { UserService } from '../services/user.service';

/** Guard prevents the current user from accessing a route in case if menu is closed. */
@Injectable({
  providedIn: 'root',
})
export class MenuNotClosedGuard implements CanActivate {

  public constructor(
    private readonly orderService: OrderService,
    private readonly userService: UserService,
  ) { }

  /** Determines if route can be activated. */
  public canActivate(): Observable<boolean | UrlTree> {
    return this.userService.isAuthorized$.pipe(
      switchMap(isAuthorized => {
        if (!isAuthorized) {
          return of(true);
        }

        return of(this.orderService.isMenuOpen());
      }),
    );
  }
}
