import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, filter, finalize, first, map, merge, Observable, of, repeat, shareReplay, skip, Subject, switchMap, takeUntil, tap, throwError } from 'rxjs';

import { AppError } from '../models/app-error';
import { Cart } from '../models/cart';
import { AddCoupon } from '../models/coupon';
import { NewOrderItem, OrderItem } from '../models/order-item';
import { Product } from '../models/product';
import { onMessageOrFailed } from '../utils/rxjs/on-message-or-failed';
import { NotAllowedToUpdateCartError } from '../errors/not-allowed-to-update-cart-error';
import { filterNull } from '../utils/rxjs/filter-null';
import { UserStatus } from '../models/user';

import { CartApiService } from './cart-api.service';
import { UserService } from './user.service';
import { StoresService } from './stores.service';

export const PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE = `Thanks for starting your order!
  Feel free to browse the store while we activate your account, and you can add items to your cart once your account has been approved!`;

export const NO_ASSIGNED_LOCATION_MESSAGE = `Oops, it looks like your account hasn't been approved yet.
 We'll send you an email when it's been approved.`;

/** Cart storage. */
@Injectable({
  providedIn: 'root',
})
export class CartService {

  private readonly isCartLoadingSubject = new BehaviorSubject(false);

  /** Whether the cart is loading or being updated. */
  public readonly isCartLoading$: Observable<boolean>;

  /** Cart containing selected products. */
  public readonly cart$: Observable<Cart>;

  /** Amount of items in the cart. */
  public readonly cartItemsAmount$: Observable<number>;

  /** Whether checkout is available or not. */
  public readonly isCheckoutAvailable$: Observable<boolean>;

  /** Whether cart is paid. */
  public readonly isCartPaid$: Observable<boolean>;

  private readonly cartUpdateSubject = new Subject<void>();

  private readonly cartUpdateCancelSubject = new Subject<void>();

  /**
   * Workaround solution to fix problems with the cart cache.
   *
   * List of the solved problems are presented below.
   * We are assuming that the following steps are executed sequentially, therefore the cache does not have time to be updated.
   *
   * 1. Item is added -> Items is edited -> Since cache is not updated yet, the item is added again instead of being edited
   *    -> After cache is updated incorrect data is displayed in UI for the item;
   * 2. Item exists in cart -> Item is deleted -> Item is added again
   *    -> Item is edited -> Error from BE, since we updated not the new item, but the deleted one which is stored in cache.
   */
  private readonly recentlyAddedProductsToCart = new Map<Product['id'], OrderItem>();

  public constructor(
    private readonly cartApiService: CartApiService,
    private readonly userService: UserService,
    private readonly storeService: StoresService,
  ) {
    this.isCartLoading$ = this.isCartLoadingSubject.asObservable();

    this.cartUpdateSubject.pipe(
      tap(() => this.isCartLoadingSubject.next(true)),
    ).subscribe();

    this.cart$ = this.getCurrentCart();

    this.cartItemsAmount$ = this.cart$.pipe(
      map(cart => cart.items.reduce((currentSum, item) => currentSum + item.quantity, 0)),
    );

    this.isCheckoutAvailable$ = this.cart$.pipe(
      map(cart => cart.items.length > 0 && cart.price.groceries > cart.price.minimumPriceOfOrder),
    );

    this.isCartPaid$ = this.cart$.pipe(
      map(({ isPaid }) => isPaid),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getCurrentCart(): Observable<Cart> {
    const userChanged$ = this.userService.isAuthorized$.pipe(
      distinctUntilChanged(),
      skip(1),
    );
    const shouldUpdateCart$ = merge(
      this.cartUpdateSubject,
      userChanged$,
    );

    return this.userService.isAuthorized$.pipe(
      first(),
      filter(isAuthorized => isAuthorized),
      switchMap(() => this.storeService.currentStore$),
      filterNull(),
      switchMap(({ id }) => this.cartApiService.getCurrentCart(id)),
      finalize(() => this.isCartLoadingSubject.next(false)),

      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(this.cartUpdateCancelSubject),
      repeat({ delay: () => shouldUpdateCart$ }),
      shareReplay({ refCount: false, bufferSize: 1 }),
    );
  }

  /**
   * Saves item to the cart.
   * @param item Cart item.
   */
  public addItem(item: NewOrderItem): Observable<void> {
    this.cartUpdateCancelSubject.next();

    return this.assertUserIsAllowedToUpdateCart().pipe(
      switchMap(() => this.cart$.pipe(
        first(),
        switchMap(cart => this.cartApiService.addItem(item, cart)),
        tap(addedItem => this.recentlyAddedProductsToCart.set(addedItem.product.id, addedItem)),
        onMessageOrFailed(() => this.cartUpdateSubject.next()),
        map(() => undefined),
      )),
    );
  }

  /**
   * Breaks the passed product down into individual cart items.
   * @param product Product.
   */
  public addCustomizedItem(product: Product): Observable<void> {
    if (Product.isCustomizable(product) === false) {
      throw new AppError(`${product.name} can not be customized!`);
    }

    const user$ = this.userService.currentUser$.pipe(filterNull());

    return user$.pipe(
      switchMap(user => {
        if (user.hasAssignedLocation === false) {
          return throwError(() => new AppError(NO_ASSIGNED_LOCATION_MESSAGE));
        }
        return of(user);
      }),
      switchMap(() => this.cart$),
      first(),
      switchMap(cart => this.cartApiService.addCustomizedItem(product, cart)),
      tap(() => this.cartUpdateSubject.next()),
      switchMap(() => this.isCartLoading$),
      filter(isCartLoading => isCartLoading === false),
      map(() => undefined),
    );
  }

  /**
   * Edits cart item info.
   * @param product Product.
   * @param quantity Quantity.
   */
  public editItemByProduct(
    product: Product,
    quantity: number,
  ): Observable<void> {
    return this.assertUserIsAllowedToUpdateCart().pipe(
      switchMap(() => this.cart$),
      first(),
      map(
        cart =>
          this.recentlyAddedProductsToCart.get(product.id) ??
          cart.items.find(item => item.product.id === product.id),
      ),
      switchMap(item => {
        if (item) {
          return this.editItem({
            ...item,
            quantity,
          });
        }
        return this.addItem({
          product,
          quantity,
        });
      }),
    );
  }

  /**
   * Changes item.
   * @param item Cart item.
   */
  public editItem(item: OrderItem): Observable<void> {
    this.cartUpdateCancelSubject.next();

    if (item.quantity === 0) {
      return this.removeItem(item);
    }

    return this.cartApiService.editItem(item).pipe(
      onMessageOrFailed(() => this.cartUpdateSubject.next()),
    );
  }

  /**
   * Deletes cart item.
   * @param item Item to delete.
   */
  public removeItem(item: OrderItem): Observable<void> {
    return this.cartApiService.removeItem(item).pipe(
      tap(() => this.recentlyAddedProductsToCart.delete(item.product.id)),
      onMessageOrFailed(() => this.cartUpdateSubject.next()),
    );
  }

  /**
   * Returns quantity of items in the cart by product id.
   * @param id Product id.
   */
  public getProductsQuantity(id: Product['id']): Observable<number> {
    return this.cart$.pipe(
      map(cart => cart.items.find(item => item.product.id === id)),
      map(item => item?.quantity ?? 0),
    );
  }

  /**
   * Applies discount coupon to current cart.
   * @param newCoupon Coupon.
   */
  public applyCoupon(newCoupon: AddCoupon): Observable<void> {
    return this.cart$.pipe(
      first(),
      switchMap(cart => this.cartApiService.applyCoupon(cart, newCoupon)),
      tap(() => this.cartUpdateSubject.next()),
      switchMap(() => this.cart$.pipe(
        first(({ coupon }) => coupon != null),
        map(() => undefined),
      )),
    );
  }

  /** Removes current coupon. */
  public removeCoupon(): Observable<void> {
    return this.cart$.pipe(
      first(),
      switchMap(cart => this.cartApiService.removeCoupon(cart)),
      tap(() => this.cartUpdateSubject.next()),
      switchMap(() => this.cart$.pipe(
        first(({ coupon }) => coupon == null),
        map(() => undefined),
      )),
    );
  }

  /** Invalidates the cart cache. */
  public invalidateCart(): void {
    this.cartUpdateCancelSubject.next();
    this.cartUpdateSubject.next();
  }

  private assertUserIsAllowedToUpdateCart(): Observable<void> {
    return this.userService.currentUser$.pipe(
      filterNull(),
      switchMap(user => {
        if (user.status === UserStatus.Processing) {
          return throwError(() => new NotAllowedToUpdateCartError(PROCESSING_USER_NOT_ALLOWED_TO_UPDATE_CART_MESSAGE));
        }

        if (user.hasAssignedLocation === false) {
          return throwError(() => new NotAllowedToUpdateCartError(NO_ASSIGNED_LOCATION_MESSAGE));
        }

        return of(undefined);
      }),
      first(),
    );
  }
}
